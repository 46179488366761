export enum AppRoutes {
  ClientData = '/portal/data',
  ClientSignUp = '/client/sign-up',
  ForgotPassword = '/password/new',
  GettingStarted = '/portal/get-started',
  Groups = '/portal/groups',
  Leads = '/portal/leads',
  Orders = '/portal/orders',
  Dealers = '/portal/dealers',
  Integrations = '/portal/integrations',
  Portal = '/portal',
  Payments = '/portal/payments',
  Pricing = '/portal/pricing',
  Profile = '/portal/profile',
  Usage = '/portal/usage',
  Users = '/portal/users',
  Reports = '/portal/reports',
  Settings = '/portal/settings',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  Sites = '/portal/sites',
}

export const anonymousRoutes = [AppRoutes.ClientSignUp, AppRoutes.ForgotPassword, AppRoutes.SignUp];

export const routesWithConfirmation = [AppRoutes.Pricing, AppRoutes.Sites];

export enum AppRoutesWithConfirmation {
  Pricing = '/portal/pricing',
  Sites = '/portal/sites',
}

export enum RouteKeys {
  ClientDataKey = 'client-data',
  DealersKey = 'dealers',
  GettingStartedKey = 'getting-started',
  GroupKey = 'group',
  GroupsKey = 'groups',
  IntegrationsKey = 'integrations',
  LeadsKey = 'leads',
  OrdersKey = 'orders',
  PaymentsKey = 'payments',
  PricingKey = 'pricing',
  ProfileKey = 'profile',
  ReportsKey = 'reports',
  SettingsKey = 'settings',
  SitesKey = 'sites',
  UsageKey = 'usage',
  UsersKey = 'users',
}
