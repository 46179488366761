/* eslint-disable no-param-reassign */
/* eslint-disable no-param-reassign */
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { clientDataApi } from '../services/clientDataApi';
import { unknownGroup } from '../constants/Group';
import { getClientIdFromClientSupplier, getVendorFromClientId } from '../utils/clientIdUtils';
import { PricingTab } from '../constants/Pricing';

import { ClientDataType } from '../constants/ClientDataType';
import { createAppAsyncThunk } from './thunks';
import { UserPreference } from '../constants/User';
import { saveUserPreferences } from '../ducks/currentUserSlice';
import { getSetBranch } from '../utils/clientDataUtils';

export const setPricingTab = createAppAsyncThunk<PricingTab | undefined, PricingTab | undefined>(
  'viewer/setPricingTab',
  async (pricingTab, { dispatch, getState }) => {
    const state = getState();
    const { clientData, pricing, viewer, currentUser } = state;
    const { selectedTabId, selectedClientId } = viewer;
    const {
      group: { groupId } = unknownGroup,
      preferences: { [UserPreference.ProfilePreferences]: otherProfilePreferences = {} } = {},
    } = currentUser;
    const { clientId, clientDataBranch } = clientData;
    const {
      component: { pricingDataBranch: componentDataBranch },
      sizeBased: { pricingDataBranch: sizeBasedDataBranch },
      base: { pricingDataBranch: baseDataBranch },
    } = pricing;
    const vendorKey = getVendorFromClientId(clientId);

    let selectedPricingDataBranch = clientDataBranch;
    let pricingTabDataBranch = ClientDataBranch.PricingSurcharge;
    switch (pricingTab) {
      case PricingTab.Base:
        selectedPricingDataBranch = baseDataBranch;
        pricingTabDataBranch = ClientDataBranch.Pricing;
        break;
      case PricingTab.Component:
        selectedPricingDataBranch = componentDataBranch;
        pricingTabDataBranch = ClientDataBranch.ClientUpdate;
        break;
      case PricingTab.SizeBased:
        selectedPricingDataBranch = sizeBasedDataBranch;
        pricingTabDataBranch = ClientDataBranch.PricingSizeBased;
        break;
      default:
        break;
    }
    const setPricingTabDataBranch = getSetBranch(pricingTabDataBranch);

    const activeBranchesFetch = dispatch(
      clientDataApi.endpoints.getClientDataBranches.initiate({
        dataType: pricingTab !== PricingTab.Surcharge ? ClientDataType.Supplier : ClientDataType.Vendor,
        clientId,
        groupId,
      }),
    );
    activeBranchesFetch.unsubscribe();
    const { data: activeBranches = [] } = await activeBranchesFetch;

    const clientUpdateBranchExists = activeBranches.find((branch) => branch.branchType === pricingTabDataBranch);
    const existsAndFromClientId = !!clientUpdateBranchExists?.name.includes(vendorKey);

    if (clientId && clientId !== getClientIdFromClientSupplier(selectedTabId || selectedClientId || '')) {
      dispatch(setPricingTabDataBranch(ClientDataBranch.Main));
    }
    if (existsAndFromClientId && selectedPricingDataBranch !== pricingTabDataBranch) {
      dispatch(setPricingTabDataBranch(pricingTabDataBranch));
    }
    if (!existsAndFromClientId && selectedPricingDataBranch !== ClientDataBranch.Main) {
      dispatch(setPricingTabDataBranch(ClientDataBranch.Main));
    }
    dispatch(
      saveUserPreferences({
        userPreference: UserPreference.ProfilePreferences,
        preferences: {
          ...otherProfilePreferences,
          selectedPricingTabId: pricingTab,
        },
      }),
    );
    return pricingTab;
  },
);
