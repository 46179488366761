/**
 * Email validation
 *
 * @param email - email to validate
 * @returns boolean
 */
export const isValidEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
