import React, { useEffect } from 'react';
import { portalApi } from '../services/portalApi';
import { useAppDispatch } from '../hooks';

const METABASE_SITE_URL = 'https://idearoom.metabaseapp.com';

interface Props {
  clientId: string;
  dashboardId: string;
}

export const Dashboard: React.FC<Props> = ({ clientId, dashboardId }: Props) => {
  const dispatch = useAppDispatch();
  const [src, setSrc] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchToken = async (id: string) => {
      const { token } = await dispatch(
        portalApi.endpoints.getMetabaseToken.initiate({ clientId: id, dashboardId }),
      ).unwrap();
      setSrc(`${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=false`);
    };

    if (clientId) {
      fetchToken(clientId);
    }
  }, [clientId]);

  if (!src) return null;
  return <iframe height="100%" width="100%" frameBorder="0" title="product-analytics" src={src} />;
};
