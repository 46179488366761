/* eslint-disable react/jsx-props-no-spreading */
import React, { MutableRefObject, useCallback, useLayoutEffect, useState } from 'react';
import {
  DataGridPremiumProps,
  gridClasses,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  GridColumnsPanel,
  GridRowsProp,
  DataGridPremium,
  GridRowIdGetter,
  GridLocaleText,
  GridCellParams,
  GridPremiumSlotsComponent,
  GridSortModel,
  GridCallbackDetails,
  GridCellSelectionModel,
  GridCellEditStartParams,
  GridSlots,
  GridEventListener,
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { deDE, enUS, esES, frFR, nbNO, nlNL } from '@mui/x-data-grid-premium/locales';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box, useTheme, Theme } from '@mui/material';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { usePath } from 'hookrouter';
import { useDispatch } from 'react-redux';
import { AppRoutes } from '../constants/AppRoutes';
import { setFilterModel, updateDataGridState } from '../ducks/muiDataGridSlice';
import { useAppSelector } from '../hooks';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { UserPreference } from '../constants/User';
import pinLeftIcon from '../images/pinLeftIcon.svg';
import pinRightIcon from '../images/pinRightIcon.svg';
import { MUIDataGridToolbar } from './MUIDataGridToolbar';

const useStyles = makeStyles<Theme, { cellSelection: boolean }>(() => ({
  grid: ({ cellSelection }) => ({
    border: 0,
    background: 'white',
    [`& .${gridClasses.columnHeaderTitle}`]: {
      fontWeight: '700',
      fontSize: '14px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      whiteSpace: 'normal',
      lineHeight: '20px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    [`& .${gridClasses.cell}`]: {
      fontWeight: '400',
      fontSize: '14px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiTablePagination-selectLabel': {
      display: 'none !important',
    },
    '& .MuiTablePagination-input': {
      display: 'none !important',
    },
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
      ...(cellSelection ? { backgroundColor: 'rgba(1, 82, 138, 0.08)' } : {}),
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
      outline: 'none',
      ...(cellSelection ? { backgroundColor: 'rgba(1, 82, 138, 0.08)' } : {}),
    },
  }),
  columnsPanel: {
    [`& .MuiInputBase-root`]: {
      minHeight: '48px',
    },
    [`& .MuiOutlinedInput-input`]: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    [`& .MuiFormControlLabel-root`]: {
      minHeight: '34px',
    },
    [`& .MuiCheckbox-root`]: {
      marginLeft: '8px',
      marginRight: '20px',
    },
    [`& .MuiTypography-root`]: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    [`& .MuiButton-text`]: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0.75px',
    },
  },
  columnMenu: {
    [`& hr:first-of-type`]: {
      display: 'none',
    },
    [`& .MuiTypography-root`]: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
  },
}));

const RemoveAllIcon = () => {
  const theme = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 3V4H4V6H5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V6H20V4H15V3H9ZM7 6H17V19H7V6ZM9 8V17H11V8H9ZM13 8V17H15V8H13Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

const PinLeftIcon = () => <img alt="Pin Column Left" src={pinLeftIcon} />;
const PinRightIcon = () => <img alt="Pin Column Right" src={pinRightIcon} />;

const CustomColumnsPanel = (props: React.ComponentProps<typeof GridColumnsPanel>) => {
  const classes = useStyles({ cellSelection: false });
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <GridColumnsPanel {...props} className={classes.columnsPanel} sx={{ minHeight: 0 }} />
    </Box>
  );
};

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  const classes = useStyles({ cellSelection: false });
  return (
    <GridColumnMenu
      {...props}
      className={classes.columnMenu}
      slotProps={{
        columnMenuFilterItem: {
          displayOrder: 1,
        },
        columnMenuSortItem: {
          displayOrder: 0,
        },
      }}
    />
  );
};

const getDataGridKey = (path: string): string => {
  if (path.startsWith(AppRoutes.Payments)) {
    return 'MUIDataGridPayments';
  }
  if (path.startsWith(AppRoutes.Orders)) {
    return 'MUIDataGridOrders';
  }
  return `MUIDataGrid`;
};

type MUIDataGridProps = {
  loading: boolean;
  rows: GridRowsProp;
  columns: GridColDef[];
  apiRef: MutableRefObject<GridApiPremium>;
  autoHeight?: boolean | undefined;
  rowSelection?: boolean | undefined;
  cellSelection?: boolean | undefined;
  pagination?: boolean | undefined;
  checkboxSelection?: boolean | undefined;
  disableRowSelectionOnClick?: boolean | undefined;
  disableFilter?: boolean | undefined;
  enableDateFilter?: boolean | undefined;
  ignoreValueFormatterDuringExport?:
    | boolean
    | {
        csvExport?: boolean;
        clipboardExport?: boolean;
      }
    | undefined;
  disableClipboardPaste?: boolean | undefined;
  initialState?: GridInitialStatePremium | undefined;
  slots?: Partial<GridPremiumSlotsComponent> | undefined;
  countParams?:
    | {
        countLabel: I18nKeys;
        filteredLabel: I18nKeys;
      }
    | undefined;
  showToolbar?: boolean;
  isCellEditable?: ((params: GridCellParams) => boolean) | undefined;
  processRowUpdate?: ((newRow: any, oldRow: any) => any) | undefined;
  onProcessRowUpdateError?: ((error: any) => void) | undefined;
  getRowId: GridRowIdGetter<any> | undefined;
  getCellClassName?: (params: GridCellParams) => string;
  onSortModelChange?: ((model: GridSortModel, details: GridCallbackDetails) => void) | undefined;
  onCellSelectionModelChange?:
    | ((cellSelectionModel: GridCellSelectionModel, details: GridCallbackDetails) => void)
    | undefined;
  onCellEditStart?: (params: GridCellEditStartParams, event: any) => void;
  onRowClick?: GridEventListener<'rowClick'>;
  onCellClick?: GridEventListener<'cellClick'>;
  treeData?: boolean;
  getTreeDataPath?: (row: any) => string[];
  groupingColDef?: GridColDef;
  pageSizeOptions?: number[];
};

export const MuiDataGrid: React.FC<MUIDataGridProps> = ({
  loading,
  rows,
  columns,
  apiRef,
  autoHeight = false,
  rowSelection = false,
  cellSelection = false,
  pagination = true,
  checkboxSelection = false,
  disableRowSelectionOnClick = false,
  disableFilter = false,
  enableDateFilter = false,
  ignoreValueFormatterDuringExport = false,
  disableClipboardPaste = false,
  initialState = undefined,
  slots = {},
  countParams = undefined,
  showToolbar = true,
  isCellEditable = undefined,
  processRowUpdate = undefined,
  onProcessRowUpdateError = undefined,
  getRowId,
  getCellClassName = undefined,
  onSortModelChange = undefined,
  onCellSelectionModelChange = undefined,
  onCellEditStart = undefined,
  onRowClick = undefined,
  onCellClick = undefined,
  treeData = false,
  getTreeDataPath = undefined,
  groupingColDef = undefined,
  pageSizeOptions = [25, 50, 100],
}) => {
  const path = usePath();
  const dataGridKey = getDataGridKey(path);
  const [stateRestored, setStateRestored] = useState(false);
  const dispatch = useDispatch();

  const { filterModel } = useAppSelector((state) => state.muiDataGrid);
  const { preferences: { [UserPreference.MUIDataGrid]: preferences = {} } = {} } = useAppSelector(
    (state) => state.currentUser,
  );
  const { columns: columnsState = {}, pinnedColumns } = preferences[dataGridKey] || {};

  useLayoutEffect(() => {
    if (apiRef.current && !stateRestored) {
      let newColumnsState: typeof columnsState | undefined;

      if (initialState != null) {
        if (initialState.columns != null && initialState.columns.columnVisibilityModel != null) {
          const columnVisibilityModelKeys = Object.keys(initialState.columns.columnVisibilityModel);
          for (let i = 0; i < columnVisibilityModelKeys.length; i += 1) {
            const columnVisibility = columnVisibilityModelKeys[i];
            if (
              columnsState.columnVisibilityModel == null ||
              columnsState.columnVisibilityModel[columnVisibility] == null
            ) {
              newColumnsState = {
                columnVisibilityModel: {
                  ...(columnsState.columnVisibilityModel || {}),
                  [columnVisibility]: initialState.columns.columnVisibilityModel[columnVisibility],
                },
              };
            }
          }
        }
      }

      if (newColumnsState) {
        dispatch(updateDataGridState({ dataGridKey, columns: newColumnsState }));
        apiRef.current.restoreState({ pinnedColumns, columns: newColumnsState });
      } else {
        apiRef.current.restoreState({ pinnedColumns, columns: columnsState });
      }
      setStateRestored(true);
    }
  }, [apiRef, pinnedColumns, columnsState, setStateRestored, stateRestored, initialState, dispatch, dataGridKey]);

  const classes = useStyles({ cellSelection });
  const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | undefined>(undefined);
  const { t } = useTranslation();

  const onFilterModelChange = useCallback<NonNullable<DataGridPremiumProps['onFilterModelChange']>>(
    (newFilterModel, details) => {
      // we are receiving a clear state when opening the page with a previous saved filter, only change the save if there is a reason too.
      if (details.reason) {
        dispatch(setFilterModel(newFilterModel));
      }
    },
    [dispatch],
  );

  const updateDataGridPinnedColumnsCallback = useCallback<NonNullable<DataGridPremiumProps['onPinnedColumnsChange']>>(
    (newPinnedColumns) => {
      dispatch(
        updateDataGridState({
          dataGridKey,
          pinnedColumns: newPinnedColumns,
        }),
      );
      // need to call restoreState because on unpin the column's order was not respecting their last order.
      apiRef.current.restoreState({ pinnedColumns: newPinnedColumns, columns: columnsState });
    },
    [dispatch, apiRef, dataGridKey, columnsState],
  );

  const getTogglableColumns = () => columns.map((column) => column.field);
  const updateDataGridColumnWidthCallback = useCallback<NonNullable<DataGridPremiumProps['onColumnWidthChange']>>(
    (_) => {
      const { columns: updatedColumns = {} } = apiRef.current.exportState();

      dispatch(
        updateDataGridState({
          dataGridKey,
          columns: updatedColumns,
        }),
      );
    },
    [dispatch, apiRef, dataGridKey],
  );

  const updateDataGridColumnsStateCallback = useCallback(() => {
    const { columns: newColumns } = apiRef.current.exportState();

    // If all columns are hidden, show the first column (label)
    const { columnVisibilityModel } = newColumns || {};
    const allColumnsHidden =
      columnVisibilityModel &&
      columns.length > 0 &&
      Object.entries(columnVisibilityModel).length === columns.length &&
      Object.entries(columnVisibilityModel)
        .filter(([key]) => key !== '__check__')
        .every(([, value]) => !value);
    if (allColumnsHidden) {
      columnVisibilityModel[columns[0].field] = true;
    }

    dispatch(updateDataGridState({ dataGridKey, columns: newColumns }));
  }, [dispatch, apiRef, dataGridKey, columns]);

  const localeText = useCallback(() => {
    let defaultLocaleText: Partial<GridLocaleText> = {};
    switch (i18n.language) {
      case 'de': {
        defaultLocaleText = deDE.components.MuiDataGrid.defaultProps.localeText;
        break;
      }
      case 'es': {
        defaultLocaleText = esES.components.MuiDataGrid.defaultProps.localeText;
        break;
      }
      case 'fr': {
        defaultLocaleText = frFR.components.MuiDataGrid.defaultProps.localeText;
        break;
      }
      case 'nl': {
        defaultLocaleText = nlNL.components.MuiDataGrid.defaultProps.localeText;
        break;
      }
      case 'no': {
        defaultLocaleText = nbNO.components.MuiDataGrid.defaultProps.localeText;
        break;
      }
      default:
        defaultLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;
    }

    return {
      ...defaultLocaleText,
      toolbarFilters: t(I18nKeys.MUIDataGridFilter),
      filterPanelColumns: t(I18nKeys.MUIDataGridColumn),
      columnMenuUnsort: t(I18nKeys.MUIDataGridUnsort),
      pinToLeft: t(I18nKeys.MUIDataGridPinToLeft),
      pinToRight: t(I18nKeys.MUIDataGridPinToRight),
      columnMenuShowColumns: t(I18nKeys.MUIDataGridShowColumns),
      columnMenuManageColumns: t(I18nKeys.MUIDataGridManageColumns),
      columnMenuSortAsc: (colDef: GridColDef) => {
        switch (colDef.type) {
          case 'number': {
            return t(I18nKeys.MUIDataGridSortNumberAsc);
          }
          case 'dateTime':
            return t(I18nKeys.MUIDataGridSortDateAsc);
          default:
            return t(I18nKeys.MUIDataGridSortAsc);
        }
      },
      columnMenuSortDesc: (colDef: GridColDef) => {
        switch (colDef.type) {
          case 'number': {
            return t(I18nKeys.MUIDataGridSortNumberDesc);
          }
          case 'dateTime':
            return t(I18nKeys.MUIDataGridSortDateDesc);
          default:
            return t(I18nKeys.MUIDataGridSortDesc);
        }
      },
    };
  }, [t, columns]);

  return (
    <DataGridPremium
      className={classes.grid}
      showColumnVerticalBorder={false}
      hideFooterSelectedRowCount
      getRowId={getRowId}
      rowSelection={checkboxSelection || rowSelection}
      autoHeight={autoHeight}
      apiRef={apiRef}
      loading={loading}
      rows={rows}
      columns={columns}
      initialState={initialState}
      pagination={pagination}
      pageSizeOptions={pageSizeOptions}
      checkboxSelection={checkboxSelection}
      filterModel={filterModel}
      cellSelection={cellSelection}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      ignoreValueFormatterDuringExport={ignoreValueFormatterDuringExport}
      disableClipboardPaste={disableClipboardPaste}
      disableRowGrouping
      disableAggregation
      isCellEditable={isCellEditable}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={onProcessRowUpdateError}
      getCellClassName={getCellClassName}
      onPinnedColumnsChange={updateDataGridPinnedColumnsCallback}
      onColumnOrderChange={updateDataGridColumnsStateCallback}
      onColumnVisibilityModelChange={updateDataGridColumnsStateCallback}
      onColumnWidthChange={updateDataGridColumnWidthCallback}
      onFilterModelChange={onFilterModelChange}
      onSortModelChange={onSortModelChange}
      onCellSelectionModelChange={onCellSelectionModelChange}
      onCellEditStart={onCellEditStart}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
      treeData={treeData}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      slots={{
        toolbar: showToolbar ? (MUIDataGridToolbar as GridSlots['toolbar']) : null,
        filterPanelRemoveAllIcon: RemoveAllIcon,
        columnsPanel: CustomColumnsPanel,
        columnMenu: CustomColumnMenu,
        columnMenuFilterIcon: FilterListOutlinedIcon,
        columnMenuHideIcon: VisibilityOffOutlinedIcon,
        columnMenuManageColumnsIcon: ViewColumnOutlinedIcon,
        columnMenuPinLeftIcon: PinLeftIcon,
        columnMenuPinRightIcon: PinRightIcon,
        ...slots,
      }}
      slotProps={{
        filterPanel: {
          // Display columns by ascending alphabetical order
          columnsSort: 'asc',
          filterFormProps: {
            logicOperatorInputProps: {
              variant: 'filled',
            },
            columnInputProps: {
              variant: 'filled',
            },
            operatorInputProps: {
              variant: 'filled',
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'filled',
              },
            },
            deleteIconProps: {
              sx: {
                '& .MuiSvgIcon-root': { width: '24px', height: '24px' },
              },
            },
          },
          sx: {
            // Customize inputs using css selectors
            '& .MuiButton-text': {
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '16px',
              letterSpacing: '0.75px',
            },
            '& .MuiButtonBase-root': {
              padding: '0px',
            },
            '& .MuiDataGrid-filterForm': { padding: '16px 16px 16px 12px', alignItems: 'center' },
            '& .MuiDataGrid-panelFooter': { padding: '16px' },
            '& .MuiDataGrid-filterFormColumnInput': { mr: '5px', width: 150 },
            '& .MuiDataGrid-filterFormOperatorInput': { mr: '5px', width: 120 },
            '& .MuiDataGrid-filterFormValueInput': { width: 179 },
          },
        },
        panel: showToolbar
          ? {
              anchorEl: filterButtonEl,
            }
          : undefined,
        toolbar: {
          disableFilter,
          enableDateFilter,
          countLabel: countParams?.countLabel,
          filteredLabel: countParams?.filteredLabel,
          ...(showToolbar
            ? {
                anchorEl: filterButtonEl,
                setFilterButtonEl,
              }
            : {}),
        },
        columnsManagement: { getTogglableColumns },
      }}
      sx={{
        '& div[style*="z-index: 100000"]': {
          display: 'none',
        },
      }}
      localeText={localeText()}
    />
  );
};
