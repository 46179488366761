import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  Step,
  StepLabel,
  StepContent,
  StepContentProps,
  Theme,
  StepIconProps,
  Grid2 as Grid,
  IconButton,
  Box,
} from '@mui/material';
import { Check, Edit } from '@mui/icons-material';
import { useSharedAddClientMutation } from '../services/clientApi';

const useStyles = makeStyles((_: Theme) => ({
  message: {
    padding: '0px 0px 12px',
    fontSize: '14px',
    // Add space between paragraphs
    '& > div:not(:last-child)': {
      margin: '0px 0px 6px 0px',
    },
  },
  stepContent: {
    border: 'none',
    padding: '0px',
    margin: '0px 0px 0px 0px',
    '& > .MuiStepLabel-iconContainer': {
      paddingRight: '4px',
    },
  },
  label: {
    fontSize: '14px',
    color: '#323B4B',
  },
  button: {
    height: 'fit-content',
    alignSelf: 'center',
  },
}));

type Props = StepContentProps & {
  index?: number;
  header?: string;
  headerPreview?: React.ReactNode;
  message?: string;
  step: number;
  setStep: (step: number) => void;
  finalStep?: boolean;
  singleSupplierIndustry?: boolean;
};

export const ClientStepIcon: React.FC<StepIconProps> = (props: StepIconProps, finalStep: number) => {
  const classes = useStyles();
  const { icon, completed } = props;

  return completed || finalStep ? (
    <Check color="primary" style={{ marginLeft: '-8px' }} />
  ) : (
    <Typography
      className={classes.label}
      sx={{
        fontSize: '14px',
        fontWeight: completed ? 'normal' : 'bold',
        width: '16px',
      }}
    >{`${icon}.`}</Typography>
  );
};

export const ClientSignUpStep: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { children, index, header, headerPreview, message, step, setStep, finalStep, singleSupplierIndustry } = props;
  const expanded = step === index;

  const [_, { isLoading: isAddingClient }] = useSharedAddClientMutation();

  return (
    <Step index={index} expanded={expanded}>
      <Grid container columnSpacing="20px" style={{ margin: '0px 0px 0px 24px' }}>
        <Grid size={expanded ? 12 : 6} display="flex">
          <Box flexGrow={1}>
            <StepLabel
              className={classes.label}
              slots={{
                label: Typography,
                stepIcon: (stepProps) => ClientStepIcon(stepProps, finalStep),
              }}
              sx={{
                '& .MuiStepLabel-iconContainer': {
                  padding: '0px 6px 0px 0px',
                },
                margin: '0px 0px 0px -24px',
              }}
              slotProps={{
                label: {
                  className: classes.label,
                  style: { fontWeight: expanded ? 'bold' : 'normal', color: expanded ? '#323B4B' : '#323B4BB2' },
                },
              }}
            >
              {header}
            </StepLabel>
            {message && (
              <Typography
                className={classes.message}
                style={expanded ? { fontWeight: 400, color: '#323B4BB2' } : { fontWeight: 'bold' }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
          </Box>
          {index === 0 && !singleSupplierIndustry && !expanded && !isAddingClient && (
            <IconButton className={classes.button} onClick={() => setStep(0)}>
              <Edit />
            </IconButton>
          )}
        </Grid>
        <Grid size={expanded ? 12 : 6}>
          <StepContent className={classes.stepContent}>{children}</StepContent>
          {!expanded && <Box>{headerPreview}</Box>}
        </Grid>
      </Grid>
    </Step>
  );
};
