import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CardMedia, Grid2 as Grid, Slide, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';
import { FlipCard } from './FlipCard';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppSelector } from '../hooks';
import { Forms } from '../constants/Forms';
import { ClientSignUpFormFields } from '../constants/FormFields';
import { ClientType } from '../constants/Client';
import { ClientSupplierLogoBanner } from './ClientSupplierLogoBanner';
import { SUPPLIER_PREVIEW_HEIGHT } from './redux-form/ClientSignUpAutocompleteField';

const useStyles = makeStyles(() => ({
  initialSupplierPreview: {
    height: SUPPLIER_PREVIEW_HEIGHT,
    padding: '20px',
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    alignContent: 'center',
  },
  initialStylesPreviewText: {
    textAlign: 'center',
    fontSize: '14px',
    color: '#323B4BB2',
  },
  stylesPreview: {
    position: 'relative',
    height: `calc(${SUPPLIER_PREVIEW_HEIGHT} * 0.76)`,
    minWidth: '270px',
    width: 'calc(100% + 20px)',
    paddingRight: '20px',
    paddingBottom: '20px',
    overflow: 'hidden',
  },
  stylesText: {
    width: '100%',
    textAlign: 'right',
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    fontSize: '14px',
    color: '#323B4BB2',
  },
  flipCard: {
    maxHeight: `calc(${SUPPLIER_PREVIEW_HEIGHT} * 0.38)`,
    backgroundColor: '#F2F2F2',
    aspectRatio: '1/1',
  },
}));

type Props = {
  supplier?: { id?: string; label?: string; logoUrl?: string; logoBarColor?: string; styles?: string[] };
  step: number;
  hideLogo?: boolean;
};

const getInitialImagesState = (styles: string[]) => Object.fromEntries([...styles.slice(0, 4).map((s) => [s, true])]);

export const ClientSupplierPreview: React.FC<Props> = ({ supplier, step, hideLogo }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id = '', styles = [] } = supplier || {};

  const type = useAppSelector((state) => formValueSelector(Forms.ClientSignUp)(state, ClientSignUpFormFields.Type));

  const [slideIn, setSlideIn] = useState<boolean>(false);
  const [supplierKey, setSupplierKey] = useState<string | undefined>(id);
  const [loading, setLoading] = useState<{
    [key: string]: boolean;
  }>(getInitialImagesState(styles));
  const iconSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (id === supplierKey) return;
    setSlideIn(false);
    setSupplierKey(id);
    setLoading(getInitialImagesState(styles));
  }, [id, supplierKey]);

  if (!id)
    return (
      <Grid
        size={6}
        className={classes.initialSupplierPreview}
        style={{
          height:
            type === ClientType.Supplier ? `calc(${SUPPLIER_PREVIEW_HEIGHT} * 0.76 - 20px)` : SUPPLIER_PREVIEW_HEIGHT,
        }}
      >
        <Typography variant="subtitle1" className={classes.initialStylesPreviewText}>
          {t(
            type === ClientType.Supplier
              ? I18nKeys.ClientSignUpStylePreviewSupplier
              : I18nKeys.ClientSignUpStylePreviewDealer,
          )}
        </Typography>
      </Grid>
    );
  return (
    <Grid
      // Key will ensure animation starts over when switching between suppliers
      key={id}
      size={6}
      direction="column"
    >
      <Grid ref={iconSectionRef} container spacing="16px" size={12} className={classes.stylesPreview}>
        {styles.slice(0, 4).map((img, i, arr) => (
          <Grid key={`grid-flip-card-${img}`} size={6}>
            <FlipCard
              delay={i * 0.5}
              className={classes.flipCard}
              loading={loading[img]}
              onAnimationComplete={() => {
                if (i === arr.length - 1) {
                  setSlideIn(true);
                }
              }}
            >
              <CardMedia
                component="img"
                image={img}
                onLoad={() =>
                  setLoading((prevLoading) => ({
                    ...prevLoading,
                    [img]: false,
                  }))
                }
                alt="Style Preview"
                sx={{
                  maxHeight: '90%',
                  maxWidth: '90%',
                  objectFit: 'contain',
                }}
              />
            </FlipCard>
          </Grid>
        ))}
        <Slide
          key={id}
          in={!!styles.slice(4).length && slideIn && supplierKey === id}
          direction="right"
          container={iconSectionRef.current}
          timeout={750}
        >
          <Typography variant="subtitle1" className={classes.stylesText}>
            {`+ ${styles.slice(4).length} more styles`}
          </Typography>
        </Slide>
      </Grid>
      {/* Immediately hide logo on step change, to avoid choppiness with logo preview */}
      {!hideLogo && type === ClientType.Dealer && step === 0 && (
        <ClientSupplierLogoBanner supplier={supplier} height={`calc(${SUPPLIER_PREVIEW_HEIGHT} * 0.24)`} />
      )}
    </Grid>
  );
};
