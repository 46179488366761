export enum ForgotPasswordFormFields {
  Email = 'EMAIL',
  NewPassword = 'NEW_PASSWORD',
  ConfirmNewPassword = 'CONFIRM_NEW_PASSWORD',
  VerificationCode = 'VERIFICATION_CODE',
}

export enum SignInFormFields {
  Email = 'EMAIL',
  Password = 'PASSWORD',
}

export enum SignUpFormFields {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  NewPassword = 'NEW_PASSWORD',
  ConfirmNewPassword = 'CONFIRM_NEW_PASSWORD',
}

export enum ClientSignUpFormFields {
  Email = 'EMAIL',
  Password = 'PASSWORD',
  ConfirmPassword = 'CONFIRM_PASSWORD',
  SupplierOrTemplate = 'SUPPLIER_OR_TEMPLATE',
  Industry = 'INDUSTRY',
  Type = 'TYPE',
}

export enum PasswordFormFields {
  CurrentPassword = 'CURRENT_PASSWORD',
  NewPassword = 'NEW_PASSWORD',
  ConfirmNewPassword = 'CONFIRM_NEW_PASSWORD',
}

export enum ProfileFormFields {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Email = 'EMAIL',
  PhoneNumber = 'PHONE_NUMBER',
}

export enum PreferencesFormFields {
  Theme = 'THEME',
  MiniMap = 'MINIMAP',
}

export enum ConfiguratorFormFields {
  Name = 'NAME',
  GroupId = 'GROUP_ID',
  Key = 'KEY',
  GenerateKey = 'GENERATE_KEY',
  Subdomain = 'SUBDOMAIN',
  Domain = 'DOMAIN',
  Industry = 'INDUSTRY',
  ExistingKey = 'EXISTING_KEY',
  Supplier = 'SUPPLIER',
  SupplierDataTemplate = 'SUPPLIER_DATA_TEMPLATE',
  VendorDataTemplate = 'VENDOR_DATA_TEMPLATE',
  Type = 'TYPE',
}

export enum UserDialogFormFields {
  NewUser = 'NEW_USER',
  GroupId = 'GROUP_ID',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  PhoneNumber = 'PHONE_NUMBER',
  Configurators = 'CONFIGURATORS',
  Dealers = 'DEALERS',
  Permissions = 'PERMISSIONS',
  LastLogin = 'LAST_LOGIN',
}

export enum BulkUserDialogFormFields {
  Emails = 'EMAILS',
  Dealers = 'DEALERS',
  Permissions = 'PERMISSIONS',
}

export enum DealerFormFields {
  ClientId = 'CLIENT_ID',
  DealerName = 'DEALER_NAME',
  City = 'CITY',
  State = 'STATE',
  ZipCode = 'ZIPCODE',
  PhoneNumber = 'PHONE_NUMBER',
  Key = 'KEY',
  Id = 'ID',
  CustomLogoUrl = 'CUSTOM_LOGO_URL',
  DealerUrl = 'DEALER_URL',
  HomeLinkUrl = 'HOME_LINK_URL',
  EmailAddress = 'EMAIL_ADDRESS',
  QuoteEmailReplyToSame = 'QUOTE_EMAIL_REPLY_TO_SAME',
  QuoteEmailReplyToAddress = 'QUOTE_EMAIL_REPLY_TO_ADDRESS',
  QuoteEmailCopySame = 'QUOTE_EMAIL_COPY_SAME',
  QuoteEmailCopyAddress = 'QUOTE_EMAIL_COPY_ADDRESS',
  DepositAmounts = 'DEPOSIT_AMOUNTS',
  IntegrationsKeySame = 'INTEGRATIONS_KEY_SAME',
  IntegrationsKey = 'INTEGRATIONS_KEY',
  // 'Advanced' fields
  Integration = 'INTEGRATION',
  ContactBarHtml = 'CONTACT_BAR_HTML ',
  EmailLogoUrl = 'EMAIL_LOGO_URL',
}

export enum WhatsNewDialogFormFields {
  ClientId = 'CLIENT_ID',
  Id = 'ID',
  Date = 'DATE',
  Title = 'TITLE',
  Message = 'MESSAGE',
  Readonly = 'READONLY',
}

export enum SceneEnvironmentDialogFormFields {
  ClientId = 'CLIENT_ID',
  Key = 'KEY',
  Label = 'LABEL',
  FileUrl = 'FILE_URL',
  PreviewUrl = 'PREVIEW_URL',
  MaxCameraDistanceMultiplier = 'MAX_CAMERA_DISTANCE_MULTIPLIER',
  MaxDiagonalBuildingLength = 'MAX_DIAGONAL_BUILDING_LENGTH',
}

export enum LanguageDialogFormFields {
  Language = 'LANGUAGE',
}

export enum I18nDialogFormFields {
  ClientId = 'CLIENT_ID',
  Language = 'LANGUAGE',
  Key = 'KEY',
  Value = 'VALUE',
  Override = 'OVERRIDE',
}

export enum ImpersonationDialogFormFields {
  ValidationCode = 'VALIDATION_CODE',
}

export enum ClientDataNoteDialogFormFields {
  Note = 'NOTE',
}

export enum ClientDataCreateBranchFields {
  Description = 'DESCRIPTION',
}

export enum ClientDataPublishFields {
  PreviewedChanges = 'PREVIEWED_CHANGES',
  CustomerIsAware = 'CUSTOMER_IS_AWARE',
  VerifiedQuotes = 'VERIFIED_QUOTES',
  Description = 'DESCRIPTION',
  PublishVendors = 'PUBLISH_VENDORS',
}

export enum ClientDataPreviewFields {
  Description = 'DESCRIPTION',
}

export enum PreviewFields {
  Description = 'DESCRIPTION',
}

export enum PricingSheetNameFormFields {
  SheetTitle = 'SHEET_TITLE',
}

export enum PricingBaseAddSizeFormFields {
  Width = 'WIDTH',
  Length = 'LENGTH',
}

export enum PublishFields {
  Description = 'DESCRIPTION',
}

export enum PricingComponentEditFields {
  Component = 'COMPONENT',
  Table = 'TABLE',
}

export enum SetupPaymentIntegrationFormFields {
  LegalEntityName = 'LEGAL_ENTITY_NAME',
  ShopperStatement = 'SHOPPER_STATEMENT',
}

export enum IntegrationsFormFields {
  Address1 = 'ADDRESS1',
  Address2 = 'ADDRESS2',
  City = 'CITY',
  State = 'STATE',
  PostalCode = 'POSTAL_CODE',
  Phone = 'PHONE',
  Country = 'COUNTRY',
  RowId = 'rowId',
  FacebookPixelId = 'facebookPixelId',
  GoogleAnalyticsId = 'analyticsTracker',
}
