import MaterialDialog from '@mui/material/Dialog';
import React from 'react';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog } from '../ducks/dialogSlice';
import { useAppDispatch, useAppSelector } from '../hooks';

/**
 * Connect Material dialog to open dialog state to reduce boilerplate.
 */
export const Dialog: React.FC<
  React.PropsWithChildren<{
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    fullWidth?: boolean;
    dialogKey: Dialogs;
    onClosed?(): void;
    disableClose?: boolean;
    onCloseAnimationComplete?(): void;
    scroll?: 'body' | 'paper';
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  }>
> = ({
  children,
  fullWidth,
  maxWidth = 'xs',
  dialogKey,
  onClosed,
  disableClose,
  onCloseAnimationComplete,
  scroll,
  onKeyDown,
}) => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(({ dialog: { key } }) => key === dialogKey);

  const handleOnClose = (): void => {
    if (disableClose) return;
    dispatch(closeDialog());

    if (onClosed) {
      onClosed();
    }
  };

  return (
    <MaterialDialog
      key={dialogKey}
      open={open}
      scroll={scroll}
      onClose={handleOnClose}
      fullWidth={fullWidth}
      aria-labelledby="form-dialog-title"
      maxWidth={maxWidth}
      onKeyDown={onKeyDown}
      TransitionProps={{
        onExited: () => {
          if (onCloseAnimationComplete) onCloseAnimationComplete();
        },
      }}
    >
      {children}
    </MaterialDialog>
  );
};
