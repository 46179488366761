import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery } from '../utils/apiUtils';
import { S3Buckets } from '../constants/S3';
import { defaultI18nNamespace } from '../constants/I18n';
import { PortalCacheTagType } from '../constants/Portal';

export const portalApi = createApi({
  reducerPath: 'portalApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [PortalCacheTagType.SalesViewI18n, PortalCacheTagType.MetabaseToken],
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.PORTAL,
    baseUrl: '/',
  }),
  endpoints: (builder) => ({
    /**
     * Get verified quote hashes for a clientId
     *
     * @return {Promise} A promise that resolves to an object with response status and JSON data, if successful.
     */
    getVerifiedQuotesMgmtLink: builder.query<{ link: string } | { message: string }, void>({
      query: () => ({
        url: `pricing/verify/getlink`,
        method: 'get',
      }),
    }),

    /**
     * Get verified quote hashes for a clientId
     *
     * @param {string} clientId
     * @return {Promise} A promise that resolves to an object with response status and JSON data, if successful.
     */
    getVerifiedQuotes: builder.query<{ clientId: string; quoteList: string[] } | { error: string }, string>({
      query: (clientId) => ({
        url: `pricing/verify/getquotelist/${clientId}`,
        method: 'get',
      }),
    }),

    /**
     * Run a single quote verification for a client
     *
     * @param {string} clientId
     * @param {string} hash
     * @param {string} clientBranch
     * @param {string} structureBranch
     * @param {string} systemBranch
     * @return {Promise} A promise that resolves to an object with response status and JSON data, if successful.
     */
    runVerifiedQuote: builder.query<
      | {
          priceDiffDetected: boolean;
          newQuoteDetected: boolean;
          priceDiffAmt: number;
        }
      | {
          invalidConfigurationDetected: true;
          stateChanges: Record<string, unknown>[];
        }
      | { message: string }, // Error case
      {
        clientId: string;
        hash: string;
        requestorEmail: string;
        clientBranch: string;
        structureBranch: string;
        systemBranch: string;
      }
    >({
      query: ({ clientId, hash, requestorEmail, clientBranch, structureBranch, systemBranch }) => ({
        url: `pricing/verify/singlequote`,
        method: 'post',
        init: {
          body: {
            clientId,
            hash,
            requestorEmail,
            clientBranch,
            structureBranch,
            systemBranch,
          },
        },
        // number of total attempts before we actually hand off error to consumer code.
        retry: 5,
      }),
    }),

    /**
     * Gets the i18n SalesView data for a given language code
     * @param language
     * @returns {Promise}
     */
    getSalesViewI18nData: builder.query<{ data: any }, string>({
      query: (language) => ({
        url: `i18n/${S3Buckets.IdeaRoomI18n}/${language}/${defaultI18nNamespace}.json`,
        method: 'get',
      }),
      providesTags: (result, error, language) =>
        result ? [{ type: PortalCacheTagType.SalesViewI18n, id: `${S3Buckets.IdeaRoomI18n}-${language}` }] : [],
    }),

    /**
     * Gets a JWT token for the given client ID and Metabase dashboard ID
     * @param clientId
     * @param dashboardId
     * @returns {Promise}
     */
    getMetabaseToken: builder.query<{ token: string }, { clientId: string; dashboardId: string }>({
      query: ({ clientId, dashboardId }) => ({
        url: `usage/token/${dashboardId}/${clientId}`,
        method: 'get',
      }),
      providesTags: (result, error, { clientId }) =>
        result ? [{ type: PortalCacheTagType.MetabaseToken, id: clientId }] : [],
    }),
  }),
});

export const {
  useGetVerifiedQuotesMgmtLinkQuery,
  useGetVerifiedQuotesQuery,
  useRunVerifiedQuoteQuery,
  useGetSalesViewI18nDataQuery,
  useGetMetabaseTokenQuery,
} = portalApi;
