import React from 'react';
import { Card, CardMedia, Grid2 as Grid } from '@mui/material';

type Props = {
  supplier?: { id?: string; label?: string; logoUrl?: string; logoBarColor?: string; styles?: string[] };
  height: string;
};

export const ClientSupplierLogoBanner: React.FC<Props> = ({
  supplier: { logoBarColor, logoUrl = '' } = { id: undefined },
  height,
}: Props) => (
  <Grid size={12} style={{ height }}>
    <Card
      elevation={0}
      sx={{
        backgroundColor: logoBarColor,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        height: '100%',
        alignContent: 'center',
        justifyItems: 'center',
      }}
    >
      <CardMedia
        component="img"
        image={logoUrl}
        alt="Logo Preview"
        sx={{
          maxHeight: '75%',
          maxWidth: '75%',
          objectFit: 'contain',
        }}
      />
    </Card>
  </Grid>
);
