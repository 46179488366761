import React from 'react';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { RenderSelectField } from './RenderSelectField';
import { LanguageDialogFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { Language } from '../constants/Languages';
import { AppState } from '../types/AppState';

export interface FormData {
  [LanguageDialogFormFields.Language]: string;
}

type FormDispatchProps = {
  onSubmit(data: FormData): Promise<AnyAction>;
};

interface StateProps {
  languages: Language[];
}

type FormProps = StateProps & FormDispatchProps & InjectedFormProps<FormData>;

const LanguageFormComponent: React.FC<FormProps> = ({ handleSubmit, onSubmit, languages }: FormProps) => {
  const { t } = useTranslation();
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        name={LanguageDialogFormFields.Language}
        component={RenderSelectField}
        style={{ display: 'flex' }}
        variant="filled"
        slotProps={{
          select: {
            displayEmpty: true,
          },
        }}
      >
        <MenuItem value="" disabled>
          {t(I18nKeys.LanguageDialogState)}
        </MenuItem>
        {languages
          .filter((lng: Language) => !lng.hidden)
          .map((lng: Language) => (
            <MenuItem key={lng.key} value={lng.key}>
              {lng.label || lng.key}
            </MenuItem>
          ))}
      </Field>
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): FormDispatchProps => ({
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.Language}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    ),
});

const mapStateToProps = (state: AppState): StateProps => {
  const {
    viewer: { languages = [] },
  } = state;
  return { languages };
};

export const LanguageForm = reduxForm<FormData>({ form: Forms.Language })(
  connect(mapStateToProps, mapDispatchToProps)(LanguageFormComponent),
);
