import { Dispatch } from 'redux';
import { Validator } from 'redux-form';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { extractErrorProps } from './errorUtils';
import { userApi } from '../services/userApi';
import { UserDialogFormFields } from '../constants/FormFields';
import { clearWarningText, setWarningText } from '../ducks/viewerSlice';

export const required: Validator = (value: string) => {
  if (value) {
    return value.length > 0 ? undefined : 'Required';
  }
  return 'Required';
};

export const requiredGroupName: Validator = (value: string) => {
  if (!value) {
    return 'Required';
  }

  if (value.indexOf('(') > -1 || value.indexOf(')') > -1) {
    return 'Characters not allowed: ()';
  }

  return undefined;
};

export const requiredZerosOK: Validator = (value) => (value || value === '0' || value === 0 ? undefined : 'Required');

export const passwordValidation: Validator = (value: string) => {
  const requiredError = required(value);
  if (requiredError !== undefined) {
    return requiredError;
  }

  if (value.length < 8) {
    return 'The password must contain at least 8 characters';
  }

  if (!value.match(/[a-z]/)) {
    return 'The password must contain at least one lowercase letter';
  }

  if (!value.match(/[A-Z]/)) {
    return 'The password must contain at least one uppercase letter';
  }

  if (!value.match(/\d/)) {
    return 'The password must contain at least one number';
  }

  if (!value.match(/[^\w\s]/)) {
    return 'The password must contain at least one special character';
  }

  return undefined;
};

export const emailValidation: Validator = async (value: any, dispatch: Dispatch) => {
  if (value.NEW_USER) {
    let validationMessage: { message?: string; error?: string };
    dispatch(clearWarningText());
    try {
      const validationFetch = dispatch(
        userApi.endpoints.validateUser.initiate({
          username: value.EMAIL,
          groupId: value.GROUP_ID,
        }) as any,
      ) as QueryActionCreatorResult<any>;
      validationFetch.unsubscribe();
      ({ data: validationMessage } = (await validationFetch) as { data: { message?: string; error?: string } });

      if (validationMessage.message) {
        dispatch(setWarningText(validationMessage.message));
      } else if (validationMessage.error) {
        // eslint-disable-next-line no-throw-literal
        throw { [`${UserDialogFormFields.Email}`]: validationMessage.error };
      }
    } catch (error) {
      if ((error as any)[UserDialogFormFields.Email]) {
        throw error;
      }

      const { errorMessage = 'Error occurred while validating email address' } = extractErrorProps(error);

      // eslint-disable-next-line no-console
      console.error('Email validation failed: ', errorMessage);
      // eslint-disable-next-line no-throw-literal
      throw { [`${UserDialogFormFields.Email}`]: 'Please provide a valid email address' };
    }
  }
  return undefined;
};
