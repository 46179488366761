import { createApi } from '@reduxjs/toolkit/query/react';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { OptionType, PriceColumn, PricingSurcharge, PricingSurchargeVaryConditionOption } from '@idearoom/types';
import { API_NAMES } from '../constants/App';
import {
  amplifyAPIBaseQuery,
  getEndpointCacheKey,
  getRequestHeader,
  displayIfMessageNotIncludes,
} from '../utils/apiUtils';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ComponentCategoryKey, SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import {
  ClientUpdateCategory,
  ClientUpdateCategoryKey,
  ComponentCategoryItem,
  ComponentCategoryItemWithConditions,
  ConditionalPrice,
} from '../types/PricingClientUpdate';
import { GridData, TableData } from '../types/DataGrid';
import { AppState } from '../types/AppState';
import { addFetchingEndpoint, removeFetchingEndpoint } from '../ducks/pricingSlice';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { areDiffValuesDifferent, getRowDiffColumnToFromValues } from '../utils/clientDataUtils';
import { DisplayColumns, MiscPriceColumns, PricingCalculationColumns } from '../constants/PricingClientUpdate';
import { ClientDataType } from '../constants/ClientDataType';
import { clientDataApi, getClientDataCacheTag, updateClientDataGridDataCache } from './clientDataApi';
import { getUpdatedTableDataDiff } from '../ducks/client-data/getUpdatedTableDataDiff';
import { ClientDataCacheTagType, OPTION_CONDITION_TABLE, PricingDataCacheTagType } from '../constants/ClientData';
import { PricingSheet } from '../types/PricingSheet';
import { i18n } from '../i18n';
import { User } from '../types/User';
import { Region } from '../types/Region';
import {
  getClientUpdatePricingTables,
  getComponentCategoryItems,
  getMatchingItemOrConditionData,
  isPricingField,
  isRegionalPricingField,
  parsePriceValue,
} from '../utils/pricingClientUpdateUtils';
import { PricingTab } from '../constants/Pricing';
import { getPricingSheetLabelParts } from '../utils/pricingSheetUtils';

export const getPricingCacheTag = (
  type: ClientDataCacheTagType,
  params: { groupId: string; clientId: string; branch: string; category?: ClientUpdateCategoryKey; id?: string },
): { type: ClientDataCacheTagType; id: string } => {
  const { groupId, category, clientId, branch, id } = params;
  return {
    type,
    id: [groupId, category, clientId, branch, id].filter((param) => param).join('-'),
  };
};

export const pricingApi = createApi({
  reducerPath: 'pricingApi',
  tagTypes: [
    ClientDataCacheTagType.Branches,
    ClientDataCacheTagType.BranchTableDiff,
    ClientDataCacheTagType.TableData,
    ClientDataCacheTagType.PublishedVersions,
    ClientDataCacheTagType.ChangesSummary,
    ClientDataCacheTagType.CellMetadata,
    ClientDataCacheTagType.TablesData,
    ClientDataCacheTagType.VendorData,
    ClientDataCacheTagType.ComponentCategoryItems,
    ClientDataCacheTagType.ComponentCategories,
    ClientDataCacheTagType.SizeBasedCategories,
    ClientDataCacheTagType.Regions,
    PricingDataCacheTagType.PricingSheets,
  ],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/pricing',
  }),
  endpoints: (builder) => ({
    /**
     * Get the list of component categories for a specific client
     */
    getComponentCategories: builder.query<
      ClientUpdateCategory[],
      { groupId: string; clientId: string; branch: ClientDataBranch }
    >({
      query: ({ groupId, clientId, branch }) => ({
        url: `/components?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
      providesTags: (result, error, { groupId, clientId, branch }) => [
        getClientDataCacheTag(ClientDataCacheTagType.ComponentCategories, {
          clientDataType: ClientDataType.Supplier,
          groupId,
          clientId,
          branch,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getComponentCategories', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    /**
     * Get the list of components with their prices for a specific component ClientUpdateCategory
     */
    getComponentCategoryItems: builder.query<
      ComponentCategoryItemWithConditions[],
      { groupId: string; clientId: string; category: ComponentCategoryKey; branch: ClientDataBranch }
    >({
      query: ({ groupId, clientId, category, branch }) => ({
        url: `/components/${category}?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
      providesTags: (result, error, { groupId, clientId, category, branch }) => [
        getClientDataCacheTag(ClientDataCacheTagType.ComponentCategoryItems, {
          clientDataType: ClientDataType.Supplier,
          groupId,
          clientId,
          category,
          branch,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getComponentCategoryItems', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    /**
     * Get a list of size based categories for a specific client
     */
    getSizeBasedCategories: builder.query<
      ClientUpdateCategory[],
      { groupId: string; clientId: string; branch: ClientDataBranch }
    >({
      query: ({ groupId, clientId, branch }) => ({
        url: `/size-based?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
      providesTags: (result, error, { groupId, clientId, branch }) => [
        getPricingCacheTag(ClientDataCacheTagType.SizeBasedCategories, {
          groupId,
          clientId,
          branch,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getSizeBasedCategories', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    /**
     * Get a list of price sheets for a client's size based category
     */
    getSizeBasedCategoryPricingSheets: builder.query<
      PricingSheet[],
      { groupId: string; clientId: string; category: SizeBasedCategoryKey; branch: ClientDataBranch }
    >({
      query: ({ groupId, clientId, category, branch }) => ({
        url: `/size-based/${category}?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
      providesTags: (result, error, { groupId, clientId, category, branch }) => [
        getPricingCacheTag(ClientDataCacheTagType.SizeBasedCategories, {
          groupId,
          clientId,
          category,
          branch,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getSizeBasedCategoryPricingSheets', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
      transformResponse: (response: PricingSheet[]) =>
        response.slice(0).sort((a, b) => {
          const [labelA, labelB] = [a, b].map((sheet) =>
            getPricingSheetLabelParts(sheet, PricingTab.SizeBased, i18n.t).join(),
          );
          return labelA.localeCompare(labelB);
        }),
    }),

    /**
     * Get a list of price sheets for a client's size based category
     */
    getSizeBasedPricingSheetPrices: builder.query<
      PricingSheet,
      { groupId: string; clientId: string; category: SizeBasedCategoryKey; id: string; branch: ClientDataBranch }
    >({
      query: ({ groupId, clientId, category, id, branch }) => ({
        url: `/size-based/${category}/${id}?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
      providesTags: (result, error, { groupId, clientId, category, id, branch }) => [
        ClientDataCacheTagType.SizeBasedCategories,
        getPricingCacheTag(ClientDataCacheTagType.SizeBasedCategories, {
          groupId,
          clientId,
          category,
          id,
          branch,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getSizeBasedPricingSheetPrices', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    /*
     * Adds a new base price in the repository.
     *
     * @param clientId
     * @param groupId
     * @param data
     * @param message
     * @param newBranch
     * @returns
     */
    addBasePrices: builder.mutation<
      void,
      {
        clientId: string;
        groupId: string;
        data: TableData[];
        message: string;
        newBranch: boolean;
        branch: ClientDataBranch;
        user: User | undefined;
      }
    >({
      query: ({ clientId, groupId, data, message, newBranch }) => ({
        url: `/base`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: { data, message, newBranch },
        },
        displayToastOnError: displayIfMessageNotIncludes(OptionType.RoofPitch),
      }),
      async onQueryStarted(
        { clientId, data, user, groupId, newBranch, branch },
        { dispatch, queryFulfilled, getState },
      ) {
        const table = clientId.startsWith('shedview') ? 'basePrice' : 'pricingBase';
        const gridData = { [table]: data };

        let patch;
        if (!newBranch) {
          // Optimistically updates the cache so we don't have to refetch these queries again
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          patch = await updateClientDataGridDataCache(
            {
              branch,
              dataType: ClientDataType.Supplier,
              clientId,
              data: gridData,
              metadata: null,
              user,
              groupId,
            },
            false,
            dispatch,
            getState() as RootState<any, any, 'clientDataApi'> & RootState<any, any, 'pricingApi'> & AppState,
            true,
          );
        }

        try {
          await queryFulfilled;
          if (newBranch) {
            dispatch(
              clientDataApi.util.invalidateTags([
                getClientDataCacheTag(ClientDataCacheTagType.Branches, {
                  clientDataType: ClientDataType.Supplier,
                  clientId,
                }),
              ]),
            );
          }
        } catch {
          // Update failed, fetch new data just to be sure (the error might be related to a recently added/modified row)
          patch?.undo();
          dispatch(
            clientDataApi.util.invalidateTags([
              getClientDataCacheTag(ClientDataCacheTagType.TableData, {
                clientDataType: ClientDataType.Supplier,
                clientId,
                groupId,
                branch: ClientDataBranch.Pricing,
              }),
            ]),
          );
          dispatch(pricingApi.util.invalidateTags([PricingDataCacheTagType.PricingSheets]));
        }
      },
    }),

    /**
     * Get all list of all possible client update pricing regions
     */
    getClientUpdateRegions: builder.query<Region[], { groupId: string; clientId: string; branch: ClientDataBranch }>({
      query: ({ groupId, clientId, branch }) => ({
        url: `/regions?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
      providesTags: (result, error, { groupId, clientId, branch }) => [
        getClientDataCacheTag(ClientDataCacheTagType.Regions, {
          clientDataType: ClientDataType.Supplier,
          groupId,
          clientId,
          branch,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getClientUpdateRegions', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    getClientPricingSheets: builder.query<PricingSheet[], { clientId: string; groupId: string; branch: string }>({
      query: ({ clientId, groupId, branch }) => ({
        url: `/base/sheets?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getClientPricingSheets', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
      providesTags: [PricingDataCacheTagType.PricingSheets],
      transformResponse: async (query: PricingSheet[]) =>
        query
          .map((sheet, index) => ({
            ...sheet,
            id: `${index + 1}`,
          }))
          .sort((a, b) => {
            const [labelA, labelB] = [a, b].map((sheet) =>
              getPricingSheetLabelParts(sheet, PricingTab.Base, i18n.t).join(),
            );
            return labelA.localeCompare(labelB);
          }),
    }),

    /**
     * Get pricing conditions by clientId
     */
    getConditions: builder.query<PricingSurchargeVaryConditionOption[], { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/condition`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Get surcharge by clientId
     */
    getSurcharge: builder.query<{ surcharges: PricingSurcharge[] }, { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/surcharge`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),
  }),
});

const updateComponentCategoryItemInDraft = (
  categoryKey: ComponentCategoryKey | undefined,
  originalItemWithConditions: ComponentCategoryItemWithConditions,
  draft: ComponentCategoryItemWithConditions[],
  updateRow: TableData,
) => {
  const { [ClientDataFixedColumns.RowId]: updateRowId, [PriceColumn.price]: newDefaultPrice } = updateRow;
  const index = draft.findIndex(
    ({ item: component }) =>
      component[ClientDataFixedColumns.RowId] === originalItemWithConditions.item[ClientDataFixedColumns.RowId],
  );

  if (index === -1 || !categoryKey) return draft;

  const { [PriceColumn.price]: originalDefaultPrice } = getMatchingItemOrConditionData(
    originalItemWithConditions,
    PriceColumn.price,
  );
  draft.splice(
    index,
    1,
    Object.entries(updateRow).reduce((updatedItem, [column, value]) => {
      let { item } = updatedItem;
      const { conditions } = updatedItem;

      if (
        updateRowId === updatedItem.item[ClientDataFixedColumns.RowId] ||
        updatedItem.conditions.every(({ [ClientDataFixedColumns.RowId]: id }) => id !== updateRowId)
      ) {
        const col = column as keyof ComponentCategoryItem;

        if (
          ([...Object.values(DisplayColumns), ...Object.values(PricingCalculationColumns)] as string[]).includes(col)
        ) {
          const previousValue = item[col];
          item = {
            ...item,
            [col]:
              typeof previousValue === 'object' && previousValue !== null
                ? { ...previousValue, value: `${value}` }
                : `${value}`,
          };
        }
        if (isPricingField(col)) {
          let price = `${value}`;
          if (isRegionalPricingField(col)) {
            price = `${value || newDefaultPrice || originalDefaultPrice}`;
          }
          item = { ...item, [col]: `${parsePriceValue(col, { [column]: price })}` };
        }
        return { item, conditions };
      }
      const matchingItemOrConditionData = getMatchingItemOrConditionData(updatedItem, column);
      const { [column]: valueToMatch } = matchingItemOrConditionData as unknown as Record<string, string>;
      const col = column as keyof ConditionalPrice;

      return {
        item,
        conditions: conditions.reduce((updatedConditions, condition) => {
          let updatedCondition = condition;

          if (condition[col] === valueToMatch) {
            if ((Object.values(DisplayColumns) as string[]).includes(col)) {
              updatedCondition = {
                ...updatedCondition,
                [col]: `${value}`,
              };
            }
            if (isPricingField(col)) {
              let price = `${value}`;
              if (isRegionalPricingField(col)) {
                price = `${value || newDefaultPrice || originalDefaultPrice}`;
              }
              updatedCondition = { ...updatedCondition, [col]: `${parsePriceValue(col, { [column]: price })}` };
            }
          }
          return [...updatedConditions, updatedCondition];
        }, [] as ConditionalPrice[]),
      };
    }, originalItemWithConditions),
  );
  return draft;
};

export const updateComponentCategoryItemsCache = (
  {
    groupId,
    clientId,
    data,
    fetchPromises,
    patches,
  }: {
    groupId: string;
    clientId: string;
    data: GridData;
    fetchPromises: Promise<void>[];
    patches: PatchCollection[];
  },
  isDelete: boolean,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  state: RootState<any, any, 'pricingApi'> & RootState<any, any, 'clientDataApi'> & AppState,
) => {
  const {
    viewer: { selectedPricingTabId: pricingTab },
    pricing: {
      component: { pricingDataBranch: clientUpdateDataBranch = ClientDataBranch.Main, selectedCategoryKey: category },
    },
  } = state;

  if (!category) return;

  const componentCategoryItemsQueryArgs = {
    groupId,
    clientId,
    category,
    branch: ClientDataBranch.ClientUpdate,
  };

  const fetchingComponentCategoryItemsPromise =
    state.pricing.fetchingEndpoints[getEndpointCacheKey('getComponentCategoryItems', componentCategoryItemsQueryArgs)];
  if (fetchingComponentCategoryItemsPromise) fetchPromises.push(fetchingComponentCategoryItemsPromise);

  const { data: originalComponentCategoryItems } = pricingApi.endpoints.getComponentCategoryItems.select(
    componentCategoryItemsQueryArgs,
  )(state);

  if (!originalComponentCategoryItems) return;

  Object.entries(data).forEach(([table, tableRows]) =>
    tableRows.forEach((row) => {
      patches.push(
        dispatch(
          pricingApi.util.updateQueryData('getComponentCategoryItems', componentCategoryItemsQueryArgs, (draft) => {
            const { [ClientDataFixedColumns.RowId]: updateRowId } = row;

            // Find the component category item that is being updated
            const originalItemWithConditions = originalComponentCategoryItems.find(
              ({ item: component, conditions }) =>
                (table === component.table && component[ClientDataFixedColumns.RowId] === updateRowId) ||
                (table === OPTION_CONDITION_TABLE &&
                  conditions.some(({ [ClientDataFixedColumns.RowId]: id }) => id === updateRowId)) ||
                Object.values(component).some(
                  (value) =>
                    typeof value === 'object' &&
                    value !== null &&
                    value[ClientDataFixedColumns.RowId] === updateRowId &&
                    value.table === table,
                ),
            );

            if (!originalItemWithConditions) return draft;

            return updateComponentCategoryItemInDraft(category, originalItemWithConditions, draft, row);
          }),
        ),
      );
    }),
  );

  const clientDataBranchDiffQueryArgs = {
    clientId,
    groupId,
    dataType: ClientDataType.Supplier,
    branch: clientUpdateDataBranch,
    tables: getClientUpdatePricingTables(
      clientId,
      category,
      pricingTab,
      getComponentCategoryItems(originalComponentCategoryItems),
    ),
  };

  const fetchingGetClientDataBranchDiffPromise =
    state.clientData.fetchingEndpoints[getEndpointCacheKey('getClientDataBranchDiff', clientDataBranchDiffQueryArgs)];
  if (fetchingGetClientDataBranchDiffPromise) fetchPromises.push(fetchingGetClientDataBranchDiffPromise);

  const { data: originalBranchDiff = [] } =
    clientDataApi.endpoints.getClientDataBranchDiff.select(clientDataBranchDiffQueryArgs)(state);

  let componentCategoryChangesExist = false;
  Object.keys(data).forEach((table) => {
    patches.push(
      dispatch(
        clientDataApi.util.updateQueryData('getClientDataBranchDiff', clientDataBranchDiffQueryArgs, () => {
          let { changes: newTableDiffChanges } = originalBranchDiff.find((diff) => diff.table === table) || {
            table,
            changes: [],
          };
          const updatedRows = data[table];

          updatedRows.forEach((updatedRow) => {
            const { item: originalItem = null, conditions = [] } =
              originalComponentCategoryItems.find(
                ({ item: component, conditions: c }) =>
                  component[ClientDataFixedColumns.RowId] === updatedRow.rowId ||
                  c.some(({ [ClientDataFixedColumns.RowId]: id }) => id === updatedRow.rowId),
              ) || {};
            const oldRow =
              conditions.find(({ [ClientDataFixedColumns.RowId]: id }) => id === updatedRow.rowId) || originalItem;
            if (isDelete) {
              if (oldRow) {
                newTableDiffChanges = getUpdatedTableDataDiff(newTableDiffChanges, oldRow, null);
              }
            } else {
              newTableDiffChanges = getUpdatedTableDataDiff(newTableDiffChanges, oldRow, {
                ...oldRow,
                ...updatedRow,
              });
            }
          });

          const newTablesDiff = [
            ...originalBranchDiff.filter((diff) => diff.table !== table),
            { table, changes: newTableDiffChanges },
          ];
          componentCategoryChangesExist =
            componentCategoryChangesExist ||
            newTablesDiff
              .flatMap(({ changes }) => changes)
              .some(
                (diff) =>
                  diff &&
                  [PriceColumn, MiscPriceColumns, PricingCalculationColumns, DisplayColumns]
                    .flatMap((e) => Object.values(e))
                    .some((column) => {
                      const columnDiff = getRowDiffColumnToFromValues(diff, column);
                      return areDiffValuesDifferent(columnDiff?.from, columnDiff?.to);
                    }),
              );
          return newTablesDiff;
        }),
      ),
    );
  });

  patches.push(
    dispatch(
      pricingApi.util.updateQueryData(
        'getComponentCategories',
        { groupId, clientId, branch: clientUpdateDataBranch },
        (draft) => {
          const newDraft = [...draft];
          const insertIndex = draft.findIndex(({ key }) => key === category);
          if (insertIndex === -1)
            newDraft.push({
              key: category,
              count: 0,
              changes: componentCategoryChangesExist,
            });
          else
            newDraft.splice(insertIndex, 1, {
              ...draft[insertIndex],
              changes: componentCategoryChangesExist,
            });
          return newDraft;
        },
      ),
    ),
  );
};

export const {
  useGetComponentCategoriesQuery,
  useGetComponentCategoryItemsQuery,
  useGetSizeBasedCategoriesQuery,
  useGetSizeBasedCategoryPricingSheetsQuery,
  useGetSizeBasedPricingSheetPricesQuery,
  useGetClientUpdateRegionsQuery,
  useGetClientPricingSheetsQuery,
  useGetConditionsQuery,
  useGetSurchargeQuery,
} = pricingApi;
