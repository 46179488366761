import { AsyncTask, AsyncTaskFailure, AsyncTaskStatus } from '../types/ClientData';

const DELAY_BETWEEN_PROGRESS_CHECK = 3_000; // 3 seconds
const MAX_WAIT_TIME_MS = 2 * 60 * 1000; // 2 minutes

// eslint-disable-next-line no-promise-executor-return
const delay = (time: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, time));

export const executeAsyncTask = async <S extends AsyncTask, P extends AsyncTask | AsyncTaskFailure>(
  start: () => Promise<S>,
  progress: () => Promise<P>,
  delayMs = DELAY_BETWEEN_PROGRESS_CHECK,
  maxWaitTimeMs = MAX_WAIT_TIME_MS,
): Promise<P> => {
  const sResult = await start();
  if (sResult.asyncTaskStatus !== AsyncTaskStatus.Pending) {
    throw new Error(`Unable to start async task, returned status: ${sResult.asyncTaskStatus}`);
  }

  const maxAttempts = maxWaitTimeMs / delayMs;

  for (let attempt = 0; attempt < maxAttempts; attempt += 1) {
    // eslint-disable-next-line no-await-in-loop
    await delay(delayMs);
    // eslint-disable-next-line no-await-in-loop
    const pResult = await progress();
    switch (pResult.asyncTaskStatus) {
      case AsyncTaskStatus.Completed:
        return pResult;

      case AsyncTaskStatus.Failed:
        throw new Error(
          'message' in pResult
            ? pResult.message
            : `Something went wrog while processing async task: ${JSON.stringify(pResult)}`,
        );

      default:
      // Do nothing, async task is pending
    }
  }

  throw new Error(`Unnable to complete async task under ${maxWaitTimeMs} milliseconds`);
};
