import { createApi } from '@reduxjs/toolkit/query/react';
import { Industry } from '@idearoom/types';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery } from '../utils/apiUtils';
import { Client } from '../types/Client';
import { ClientTemplateSupplier, ClientTemplateSupplierResponse } from '../types/ClientTemplateSupplier';

export const clientApi = createApi({
  reducerPath: 'clientApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1',
  }),
  endpoints: (builder) => ({
    /**
     * Gets a list of all clients
     */
    getClients: builder.query<Client[], void>({
      query: () => ({
        url: `/internal/client`,
        method: 'get',
      }),
    }),

    /**
     * Gets a client by id
     */
    getClientById: builder.query<Client, string>({
      query: (clientIdentifier) => ({
        url: `/internal/client/${clientIdentifier}`,
        method: 'get',
      }),
    }),

    /**
     * Gets a new client's subscription information by subscription id
     */
    getSubscriptionDataById: builder.query<any, string>({
      query: (subscriptionId) => ({
        url: `/client/subscription/${subscriptionId}`,
        method: 'get',
      }),
    }),

    /**
     * Validate a new client's admin user
     */
    validateClientUser: builder.mutation<{ email: string; status: string | null }, string>({
      query: (email) => ({
        url: `/client/user`,
        method: 'post',
        init: {
          body: { email },
        },
      }),
    }),

    /**
     * Adds a client
     */
    addClient: builder.mutation<
      {
        client: { groupId: string };
      },
      {
        firstName?: string;
        lastName?: string;
        company: string;
        email: string;
        password?: string;
        address?: string;
        city?: string;
        state?: string;
        zip?: string;
        phone?: string;
        website?: string;
        subscriptionId: string;
        supplierOrTemplate?: string;
        industry: string;
        type: string;
      }
    >({
      query: (client) => ({
        url: `/client`,
        method: 'post',
        init: {
          body: client,
        },
      }),
    }),

    /**
     * Adds a client via vendor copy tool
     */
    addClientInternal: builder.mutation<
      {
        client: { groupId: string };
      },
      {
        groupName: string;
        groupId?: string;
        industry: Industry;
        vendorClientId?: string;
        supplierClientId?: string;
        vendorTemplateId: string;
        supplierTemplateId?: string;
        newClient: boolean;
      }
    >({
      query: (client) => ({
        url: `/internal/client`,
        method: 'post',
        init: {
          body: client,
        },
      }),
    }),

    /**
     * Updates a client
     */
    updateClient: builder.mutation<Client, Client>({
      query: (client) => ({
        url: `/internal/client/${client.id}`,
        method: 'put',
        init: {
          body: client,
        },
      }),
    }),

    /**
     * Deletes a client
     */
    deleteClient: builder.mutation<void, string>({
      query: (clientIdentifier) => ({
        url: `/internal/client/${clientIdentifier}`,
        method: 'del',
      }),
    }),

    /**
     * Get a list of available supplier's
     */
    getDealerSuppliers: builder.query<ClientTemplateSupplier[], string>({
      query: (industry) => ({
        url: `/client/dealer/suppliers/${industry}`,
        method: 'get',
      }),
      transformResponse: (response: ClientTemplateSupplierResponse[]) =>
        response.map(({ clientId, name, ...otherAttributes }) => ({
          id: clientId,
          label: name,
          clientId,
          name,
          ...otherAttributes,
        })),
    }),

    /**
     * Gets all vendors marked as templates in vendor data for the given client's industry
     *
     * @param industry - industry type
     * @returns vendor keys
     */
    getTemplateVendors: builder.query<ClientTemplateSupplier[], Industry>({
      query: (industry) => ({
        url: `/client/templates/${industry}`,
        method: 'get',
      }),
      transformResponse: (response: ClientTemplateSupplierResponse[]) =>
        response.slice(0).map(({ clientId, name, ...otherAttributes }) => ({
          id: clientId,
          label: name,
          clientId,
          name,
          ...otherAttributes,
        })),
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientByIdQuery,
  useValidateClientUserMutation,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useGetDealerSuppliersQuery,
  useGetSubscriptionDataByIdQuery,
  useGetTemplateVendorsQuery,
} = clientApi;

export const useSharedAddClientMutation = () => useAddClientMutation({ fixedCacheKey: 'add-client' });
export const useSharedClientUserValidationMutation = () =>
  useValidateClientUserMutation({ fixedCacheKey: 'validate-client-user' });
