/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { TextFieldProps } from '@mui/material/TextField';
import { WrappedFieldProps } from 'redux-form';
import { IconButton, InputAdornment, List, ListItemButton, TextField, Typography } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { KeyboardKeys } from '../../constants/ClientData';

const TEXT_FIELD_HEIGHT = 'calc(1.4375em + 14px * 2)';
export const SUPPLIER_PREVIEW_HEIGHT = `calc(40vh + 17px + ${TEXT_FIELD_HEIGHT})`;

const useStyles = makeStyles(() => ({
  input: {
    '& .MuiAutocomplete-popupIndicator': {
      display: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px 4px 0px 0px',
    },
  },
  inputLabel: {
    margin: '0px 0px 0px 31px',
  },
  list: {
    overflowY: 'scroll',
    maxHeight: '40vh',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderTop: 'none',
    borderRadius: '0px 0px 4px 4px',
  },
}));

type Props = WrappedFieldProps & TextFieldProps & { options: { id: string; label: string }[] };

export const ClientSignUpAutocompleteField: React.FC<Props> = ({ input, options, label, required, variant }: Props) => {
  const classes = useStyles();
  const { value, onChange } = input;

  const listRef = useRef<HTMLUListElement>(null);
  const [focused, setFocused] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState(options);

  useEffect(() => {
    setFilteredOptions(options);
    if (options && options.length === 1) {
      onChange(options[0]);
    }
  }, [options]);

  useEffect(() => {
    if (filteredOptions) {
      const selectedSupplierElement =
        listRef.current?.querySelector(`[id="${value.id}"]`) || listRef.current?.firstElementChild;
      if (selectedSupplierElement) {
        selectedSupplierElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [value, filteredOptions]);

  const search = useCallback(
    (searchVal: string) => {
      const filtered = options.filter(
        ({ id: optionId, label: optionLabel }) =>
          !searchVal || [optionId, optionLabel].some((str) => str.toLowerCase().includes(searchVal.toLowerCase())),
      );
      setFilteredOptions(filtered);
    },
    [searchValue],
  );

  return (
    <div>
      <TextField
        className={classes.input}
        label={label}
        variant={variant}
        required={required}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            style: { fontSize: '14px' },
            endAdornment:
              searchValue || value ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setSearchValue('');
                      setFilteredOptions(options);
                      onChange(undefined);
                    }}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : null,
          },
          inputLabel: {
            shrink: false,
            className: classes.inputLabel,
            style: { display: searchValue || value || focused ? 'none' : 'block', fontSize: '14px' },
          },
        }}
        {...input}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onChange={({ target: { value: newSearchValue } }) => {
          setSearchValue(newSearchValue);
          search(newSearchValue);
        }}
        onKeyDown={(e) => {
          const { key } = e;
          // Scroll down to select the first supplier or template
          if (key === KeyboardKeys.Down) {
            e.preventDefault();
            (listRef.current?.firstElementChild as HTMLElement | null)?.focus();
          }
        }}
        value={searchValue || value?.label || ''}
        fullWidth
      />
      <List ref={listRef} className={classes.list}>
        {filteredOptions?.map((option) => (
          <ListItemButton
            id={option.id}
            key={option.id}
            style={{
              padding: '13px 16px',
              backgroundColor: value?.id === option.id ? 'rgba(55, 135, 255)' : 'inherit',
              color: value?.id === option.id ? 'white' : 'black',
            }}
            onClick={() => {
              setSearchValue('');
              setFilteredOptions(options);
              onChange(option);
            }}
            onKeyDown={(e) => {
              const {
                key,
                target: { nextElementSibling, previousElementSibling },
              } = e;
              // Scroll up and down to select suppliers/templates
              if (([KeyboardKeys.Down, KeyboardKeys.Up] as string[]).includes(key)) {
                e.preventDefault();
                const newFocusedElement = (
                  key === KeyboardKeys.Down ? nextElementSibling : previousElementSibling
                ) as HTMLElement | null;
                newFocusedElement?.focus();
              }
            }}
            selected={value?.id === option.id}
          >
            <Typography style={{ fontSize: '14px' }}>{option.label}</Typography>
          </ListItemButton>
        ))}
      </List>
    </div>
  );
};
