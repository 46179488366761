import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import pLimit from 'p-limit';

import { Dialogs } from '../constants/Dialogs';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { useAppDispatch, useAppSelector } from '../hooks';
import { I18nKeys } from '../constants/I18nKeys';
import { closeDialog } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { ClientDataType } from '../constants/ClientDataType';
import { unknownGroup } from '../constants/Group';
import { portalApi } from '../services/portalApi';

const statusIconStyle = { width: '30px', height: '30px', marginRight: '15px' };

const iconError = <CancelIcon style={statusIconStyle} sx={{ color: 'error.dark' }} />;
const iconSuccess = <CheckCircleIcon style={statusIconStyle} sx={{ color: 'success.dark' }} />;
const iconRunning = <CircularProgress style={{ ...statusIconStyle, padding: '2px' }} />;

const useStyles = makeStyles<Theme>(() => ({
  dialogActions: { padding: '12px 16px 12px 16px' },
}));

// Possible YAGNI but keeping this piece for possible internal-only feature
const BranchSelector: React.FC<{
  type: ClientDataType;
  branch: ClientDataBranch;
  setBranch: (newBranch: ClientDataBranch) => void;
}> = ({ type, branch, setBranch }) => {
  const allBranches = Object.values(ClientDataBranch);

  const mapOldBranchToNewLabel = new Map<string, string>([
    [ClientDataType.Vendor, 'Client'],
    [ClientDataType.Supplier, 'Structure'],
    [ClientDataType.Reference, 'System'],
  ]);

  const id = `${type}-selector`;
  const labelId = `${type}-label`;
  const selectLabel = ``;
  const newLabel = mapOldBranchToNewLabel.get(type);
  const inputLabel = `${newLabel} (${type}) Branch`;

  return (
    <FormControl variant="filled" fullWidth style={{ margin: '1em 0' }}>
      <InputLabel id={labelId}>{inputLabel}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        label={selectLabel}
        value={branch}
        onChange={(event) => setBranch(event.target.value as ClientDataBranch)}
      >
        {allBranches.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// simple state flags for UI transitions
const [INIT_SELECT_VENDOR, RUNNING, COMPLETE, ERROR, NOQUOTES] = [
  'INIT_SELECT_VENDOR',
  'RUNNING',
  'COMPLETE',
  'ERROR',
  'NOQUOTES',
];

// pLimit(NUM) combined with Promise API allows us to set a precise limit of NUM concurrent running functions
const limit = pLimit(3);

export const ClientDataVerifiedQuotesDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { clientId, clientDataBranch, clientDataType } = useAppSelector((state) => state.clientData);
  const dispatch = useAppDispatch();

  const open = useAppSelector(({ dialog: { key } }) => key === Dialogs.VerifiedQuotes);

  const { group = unknownGroup, user } = useAppSelector((state) => state?.currentUser);
  const requestorEmail = user?.email || user?.username || 'unknown user'; // TODO: what happens with no user?
  const { configurators: configs = [] } = group;

  const [quotesToVerify, setQuotesToVerify] = useState<string[]>([]);
  const [numCompleted, setNumCompleted] = useState(0);
  const [priceDiffCount, setPriceDiffCount] = useState(0);
  const [invalidConfigurationCount, setInvalidConfigurationCount] = useState(0);
  const [newQuoteCount, setNewQuoteCount] = useState(0);
  const [quoteMgrLink, setQuoteMgrLink] = useState('');

  const vendorClientIds = configs.map((c) => c.clientId).filter((id): id is string => !!id);
  const multipleVendors = vendorClientIds.length > 1;

  // Start with current client, then enable switching for multi-clients
  const [clientIdToVerify, setClientIdToVerify] = useState<string>(clientId);
  useEffect(() => {
    setClientIdToVerify(clientId);
  }, [clientId]);

  const [runState, setRunState] = useState(INIT_SELECT_VENDOR);
  useEffect(() => {
    // This is just for debugging
    console.debug(`runState = ${runState}`);
  }, [runState]);

  // Assume unpublished for VQ, backend will fallback to main if unpublished does not exist.
  const [clientBranch, setClientBranch] = useState(ClientDataBranch.Unpublished);
  const [structureBranch, setStructureBranch] = useState(ClientDataBranch.Unpublished);
  const [systemBranch, setSystemBranch] = useState(ClientDataBranch.Unpublished);
  // Keeping these actually synced right while the page loads
  useEffect(() => {
    if (clientDataBranch) {
      if (clientDataType === ClientDataType.Vendor) {
        setClientBranch(clientDataBranch);
      } else if (clientDataType === ClientDataType.Supplier) {
        setStructureBranch(clientDataBranch);
      } else if (clientDataType === ClientDataType.Reference) {
        setSystemBranch(clientDataBranch);
      }
    }
  }, [clientDataType, clientDataBranch]);

  const actionCloseDialog = () => dispatch(closeDialog());
  const actionCancel = () => {
    setTimeout(() => setRunState(INIT_SELECT_VENDOR), 100); // anti-flash measure
    setNumCompleted(0);
    setPriceDiffCount(0);
    setInvalidConfigurationCount(0);
    setNewQuoteCount(0);
    limit.clearQueue(); // dump requests that haven't started yet
  };

  // Reset based on open state and other critical data
  useEffect(() => {
    if (!open) {
      setRunState(INIT_SELECT_VENDOR);
    }
  }, [open, clientId, clientDataBranch, multipleVendors]);

  // Gather quotes.
  useEffect(() => {
    if (runState !== INIT_SELECT_VENDOR) return;
    if (!clientIdToVerify) return;

    // Load up the manager link (temp until SV manager built) then load quote hashes
    const fetchData = async () => {
      const linkRes = await dispatch(portalApi.endpoints.getVerifiedQuotesMgmtLink.initiate()).unwrap();
      if (!('link' in linkRes)) {
        throw new Error(linkRes.message);
      }

      // Embed clientId as params, enable auto-search of quote data for current client
      const updatedUrl = new URL(linkRes.link);
      updatedUrl.search = `cid=${clientIdToVerify}`;
      setQuoteMgrLink(updatedUrl.toString());

      const res = await dispatch(portalApi.endpoints.getVerifiedQuotes.initiate(clientIdToVerify)).unwrap();
      if ('error' in res) {
        throw new Error(res.error);
      }

      if ('quoteList' in res && res.clientId === clientIdToVerify && res.quoteList.length > 0) {
        console.log(`Quotes updated, count=${res.quoteList.length}`);
        setQuotesToVerify(res.quoteList);
      } else {
        setRunState(NOQUOTES);
      }
    };

    fetchData().catch(() => {
      setRunState(ERROR);
    });
  }, [clientIdToVerify, runState]);

  // Core quote running logic
  const runQuotes = async () => {
    if (runState !== INIT_SELECT_VENDOR) return;
    setRunState(RUNNING);
    const postRunSingleQuote = async (hash: string) => {
      const response = await dispatch(
        portalApi.endpoints.runVerifiedQuote.initiate({
          clientId,
          hash,
          requestorEmail,
          clientBranch,
          structureBranch,
          systemBranch,
        }),
      ).unwrap();
      return response;
    };

    let abort = false; // Abort on error. Otherwise wasted time waiting for error dialog anyway.
    const quoteRequests = quotesToVerify.map(async (hash) =>
      limit(async () => {
        if (abort) {
          return;
        }
        await postRunSingleQuote(hash)
          .then((res) => {
            // TODO FIXME: fix return type to single error case
            if ('error' in res || 'message' in res) {
              console.error(res);
            } else if ('invalidConfigurationDetected' in res) {
              setInvalidConfigurationCount((count) => count + 1);
            } else if (res.priceDiffDetected) {
              setPriceDiffCount((count) => count + 1);
            } else if (res.newQuoteDetected) {
              setNewQuoteCount((count) => count + 1);
            }
          })
          .catch(() => {
            // no need to log - will show in console anyway
            abort = true;
          })
          .finally(() => setNumCompleted((prev) => prev + 1));
      }),
    );

    await Promise.allSettled(quoteRequests);

    if (abort) {
      setRunState(ERROR);
      return;
    }

    setRunState(COMPLETE);
  };

  if (!clientId) {
    return null;
  }

  // Important for handling clients with multiple vendors
  const clientIdToName = new Map<string, string>();
  configs.forEach((configurator) => {
    if (!configurator.clientId) {
      console.warn(`configurator.clientId undefined`);
      return;
    }
    if (!configurator.name) {
      console.warn(`configurator.name undefined`);
      return;
    }
    clientIdToName.set(configurator.clientId, configurator.name);
  });

  const clientName = clientIdToName.get(clientIdToVerify as string);

  const vendorsLabel = t(I18nKeys.VerifiedQuotesVendorSelectTitle);
  const uiVendorSelect = (
    <Box>
      <FormControl variant="filled" fullWidth>
        <InputLabel id={vendorsLabel}>{vendorsLabel}</InputLabel>
        <Select
          disabled={multipleVendors}
          labelId={vendorsLabel}
          value={clientIdToVerify}
          onChange={(event) => setClientIdToVerify(event.target.value)}
        >
          {vendorClientIds.map((value) => (
            <MenuItem key={value} value={value}>
              {clientIdToName.get(value) || value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );

  // This is the confluence wiki link for "Follow the instructions" when no
  //    quotes exist and this comment is for searchability
  const instructionsLink = t(I18nKeys.VerifiedQuotesDialogInternalHelpLink);
  const uiNoQuotes = (
    <Typography variant="subtitle1">
      <Trans
        // @ts-expect-error not ideal to use Trans component but only way we can do link.
        i18nKey={I18nKeys.VerifiedQuotesDialogNoQuotesBody}
        components={{
          anchor: <Link href={instructionsLink} target="_blank" />,
          br: <br />,
        }}
      />
    </Typography>
  );

  const uiRunning = (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex' }}>{iconRunning}</Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1" paddingBottom={1}>
          {t(I18nKeys.VerifiedQuotesDialogInProgressTitle, { clientName })}
        </Typography>
        <Typography variant="body2">
          {t(I18nKeys.VerifiedQuotesDialogInProgressBody, { complete: numCompleted, total: quotesToVerify.length })}
        </Typography>
      </Box>
    </Box>
  );

  const uiError = (
    <Box sx={{ display: 'flex' }}>
      {iconError}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1">{t(I18nKeys.VerifiedQuotesDialogErrorTitle)}</Typography>
        <Typography variant="body1">{t(I18nKeys.VerifiedQuotesDialogErrorBody)}</Typography>
      </Box>
    </Box>
  );

  const noMismatch = priceDiffCount === 0;
  const noInvalid = invalidConfigurationCount === 0;
  const allMatch = noInvalid && noMismatch;
  // invalidConfigurationCount
  const uiComplete = (
    <Box sx={{ display: 'flex' }}>
      {allMatch ? iconSuccess : iconError}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {allMatch && t(I18nKeys.VerifiedQuotesDialogCompleteBodyAllMatch, { clientName, completedCount: numCompleted })}
        {noInvalid
          ? t(I18nKeys.VerifiedQuotesDialogCompleteBodySomeMismatch, {
              clientName,
              differentCount: priceDiffCount,
              completedCount: numCompleted,
            })
          : t(I18nKeys.VerifiedQuotesDialogCompleteBodySomeInvalid, {
              clientName,
              invalidCount: invalidConfigurationCount,
              differentCount: priceDiffCount,
              completedCount: numCompleted,
            })}
      </Box>
    </Box>
  );
  const uiBranchSelect = (
    <>
      <Typography>Pick your data branches to verify with:</Typography>
      <BranchSelector branch={clientBranch} setBranch={setClientBranch} type={ClientDataType.Vendor} />
      <BranchSelector branch={structureBranch} setBranch={setStructureBranch} type={ClientDataType.Supplier} />
      <BranchSelector branch={systemBranch} setBranch={setSystemBranch} type={ClientDataType.Reference} />
    </>
  );

  const buttonCancel = <Button onClick={actionCloseDialog}>{t(I18nKeys.DialogCancelButton)}</Button>;
  const buttonVerify = (
    <Button disabled={runState === RUNNING} onClick={runQuotes}>
      {t(I18nKeys.VerifiedQuotesVerify)}
    </Button>
  );
  const buttonGotIt = <Button onClick={actionCloseDialog}>{t(I18nKeys.DialogGotItButton)}</Button>;

  const buttonClose = <Button onClick={actionCloseDialog}>Close</Button>;
  const buttonViewReport = (
    <Button target="_blank" href={quoteMgrLink}>
      {t(I18nKeys.VerifiedQuotesViewReport)}
    </Button>
  );

  return (
    <Dialog
      dialogKey={Dialogs.VerifiedQuotes}
      maxWidth="xs"
      fullWidth
      onClosed={actionCloseDialog}
      onCloseAnimationComplete={actionCancel}
      disableClose={runState === RUNNING}
    >
      <DialogTitle>{t(I18nKeys.VerifiedQuotesDialogTitle)}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        {runState === INIT_SELECT_VENDOR && uiVendorSelect}
        {runState === NOQUOTES && uiNoQuotes}
        {runState === RUNNING && uiRunning}
        {runState === ERROR && uiError}
        {runState === COMPLETE && uiComplete}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {runState === INIT_SELECT_VENDOR && (
          <>
            {buttonCancel}
            {buttonVerify}
          </>
        )}
        {runState === COMPLETE && buttonViewReport}
        {(runState === RUNNING || runState === COMPLETE) && buttonClose}
        {(runState === ERROR || runState === NOQUOTES) && buttonGotIt}
      </DialogActions>
    </Dialog>
  );
};
