import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface Props {
  readonly link: string;
  readonly className?: string;
  readonly icon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>> & {
    muiName: string;
  };
}

export const NewWindowLink: React.FC<Props> = ({ className, link, icon = OpenInNewIcon }: Props) => (
  <IconButton
    disabled={!link}
    className={className}
    onClick={(event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
      event.stopPropagation();
      window.open(link, '_blank', 'noopener noreferrer');
    }}
    size="large"
  >
    {React.createElement(icon)}
  </IconButton>
);
