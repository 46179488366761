import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';

export const s3Api = createApi({
  reducerPath: 's3Api',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/s3',
  }),
  endpoints: (builder) => ({
    /**
     * Gets a list of asset file urls from S3
     *
     * @param bucket
     * @param clientId
     * @return {Promise}
     */
    getAssetFiles: builder.query<{ files: string[] }, { bucket: string; groupId: string; clientId: string }>({
      query: ({ bucket, groupId, clientId }) => ({
        url: `/assets/${bucket}/${clientId}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId }),
        },
      }),
    }),

    /**
     * Gets a file url from S3
     *
     * @param bucket
     * @param path
     * @return {Promise}
     */
    getFileUrl: builder.query<string, { groupId: string; bucket: string; path: string; displayToastOnError?: boolean }>(
      {
        query: ({ groupId, bucket, path, displayToastOnError = true }) => ({
          url: `/fileUrl/${bucket}?path=${path}`,
          method: 'get',
          init: {
            headers: getRequestHeader({ groupId }),
          },
          displayToastOnError,
        }),
      },
    ),

    /**
     * Gets a file from S3
     *
     * @param groupId
     * @param bucket
     * @param path
     * @return {Promise}
     */
    getFile: builder.query<any, { groupId: string; bucket: string; path: string }>({
      query: ({ groupId, bucket, path }) => ({
        url: `/file/${bucket}?path=${path}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId }),
        },
      }),
    }),

    /**
     * Uploads a file to S3
     *
     * @param groupId
     * @param bucket
     * @param path
     * @param file
     */
    uploadFile: builder.mutation<
      { fileUrl: string },
      {
        groupId: string;
        bucket: string;
        path: string;
        file: { name: string; content: any; contentType: string };
        privateFile?: boolean;
      }
    >({
      query: ({ groupId, bucket, path, file, privateFile }) => ({
        url: `/upload/${bucket}`,
        method: 'post',
        init: {
          body: {
            groupId,
            path,
            file,
            privateFile,
          },
        },
      }),
    }),

    /**
     * Copy a file from one bucket to another in S3
     *
     * @param sourceBucket
     * @param sourceFilePath
     * @param destinationBucket
     * @param destinationFilePath
     * @returns {Promise<void>}
     */
    copyFile: builder.mutation<
      void,
      {
        groupId: string;
        sourceBucket: string;
        sourceFilePath: string;
        destinationBucket: string;
        destinationFilePath: string;
      }
    >({
      query: ({ groupId, sourceBucket, sourceFilePath, destinationBucket, destinationFilePath }) => ({
        url: `/copy`,
        method: 'post',
        init: {
          headers: getRequestHeader({ groupId }),
          body: {
            sourceBucket,
            sourceFilePath,
            destinationBucket,
            destinationFilePath,
          },
        },
      }),
    }),

    /**
     * Deletes a given file from S3
     * @param groupId
     * @param bucket
     * @param path
     * @returns {Promise}
     */
    deleteFile: builder.mutation<any, { groupId: string; bucket: string; path: string }>({
      query: ({ groupId, bucket, path }) => ({
        url: `/file/${bucket}`,
        method: 'del',
        init: {
          body: {
            groupId,
            path,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetAssetFilesQuery,
  useGetFileUrlQuery,
  useGetFileQuery,
  useUploadFileMutation,
  useCopyFileMutation,
  useDeleteFileMutation,
} = s3Api;
