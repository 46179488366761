import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useAppSelector } from '../hooks';
import { AppRoutes } from '../constants/AppRoutes';

const useStyles = makeStyles<
  Theme,
  {
    logoBarTextColor: string;
  }
>((theme: Theme) => ({
  secondaryActionIconButton: {
    color: ({ logoBarTextColor }) => logoBarTextColor,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  secondaryActionMoreIcon: {
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
      width: '48px',
      height: '48px',
    },
  },
}));

interface Props {
  readonly secondaryActions: JSX.Element[];
}

export const SecondaryActionsMenu: React.FC<Props> = ({ secondaryActions }: Props) => {
  const logoBarTextColor = useAppSelector((state) => state.viewer.theme?.logoBarTextColor || '#000');

  const classes = useStyles({ logoBarTextColor });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => setAnchorEl(event.currentTarget);
  const handleClose = (): void => setAnchorEl(null);

  // TODO remove this route validation after we refactor all old places
  const newRoutes = [AppRoutes.Payments, AppRoutes.Orders];
  if (secondaryActions.length === 1 && newRoutes.some((route) => window.location.pathname.startsWith(route))) {
    return secondaryActions[0];
  }

  return (
    <>
      <IconButton className={classes.secondaryActionIconButton} onClick={handleClick} size="large">
        <MoreVertIcon className={classes.secondaryActionMoreIcon} />
      </IconButton>
      <Menu
        id="secondary-actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {secondaryActions}
      </Menu>
    </>
  );
};
