import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Divider, Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FilterMenuItems } from '../constants/FilterMenuItems';
import { fetchOrders, setDateFilter, setDateRange } from '../ducks/orders';
import { DateRange } from '../types/DateRange';
import { DateRangeDialog } from './DateRangeDialog';
import { isCurrentUserManager, isCurrentUserAdmin } from '../utils/userUtils';
import { FilterSelectionDialog } from './FilterSelectionDialog';
import { CustomFilterType } from '../types/CustomFilter';
import { unknownGroup } from '../constants/Group';
import { unknownDealer } from '../types/Dealer';
import { unknownOwner } from '../types/OrderOwner';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { OrderStatusFilter } from '../types/OrderStatus';
import { defaultStatusFilters } from '../constants/OrderStatus';
import { FilterType, TableFilterType } from '../constants/Viewer';
import { getFilterMessage } from '../utils/viewerUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { getFilterDateRange, mapSubmitStatusToLabel } from '../utils/orderUtils';
import { clearFilters, fetchGroupFilters, setFilterDialog } from '../ducks/viewerSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { UserPreference } from '../constants/User';
import { saveUserPreferences } from '../ducks/currentUserSlice';

export const OrderFilterMenu: React.FC<{
  count: number;
}> = ({ count }: { count: number }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isAdmin = useAppSelector((state) => isCurrentUserAdmin(state.currentUser.user));
  const isManager = useAppSelector((state) => isCurrentUserManager(state.currentUser.user));
  const { date: { startDate = undefined, endDate = undefined, filter: dateFilter = FilterMenuItems.Days45 } = {} } =
    useAppSelector((state) => state.orders);
  const availableDealers = useAppSelector(({ currentUser: { availableDealers: dealers = [] } }) => [
    unknownDealer,
    ...dealers,
  ]);
  const availableOwners = useAppSelector(({ orders: { orderOwnerOptions } }) => [unknownOwner, ...orderOwnerOptions]);
  const groupFilters = useAppSelector((state) => state.viewer.groupFilters);
  const groupId = useAppSelector((state) => (state.currentUser.group || unknownGroup).groupId || undefined);
  const configurators = useAppSelector((state) => (state.currentUser.group || unknownGroup).configurators || []);
  const { orderStates, orderStatuses, orderSubmitStatuses } = useAppSelector(({ orders: { orders } }) => {
    const statuses = defaultStatusFilters;
    const statusLabels: string[] = [];
    const states: string[] = [];
    orders.forEach((order) => {
      if (
        (order.orderStatusId || order.orderStatusName) &&
        !statuses.some((status) => status.key === order.orderStatusId) &&
        !statuses.some((status) => status.name === order.orderStatusName)
      ) {
        statuses.push({
          key: order.orderStatusId,
          name: order.orderStatusName,
          color: order.orderStatusBackgroundColor,
        });
      }
      if (order.state && !states.includes(order.state)) {
        states.push(order.state);
      }
      statusLabels.push(mapSubmitStatusToLabel(order.submitStatus));
    });
    statuses.slice(0).sort((a: OrderStatusFilter, b: OrderStatusFilter) => (a.name > b.name ? 1 : -1));
    return {
      orderStates: states,
      orderStatuses: statuses,
      orderSubmitStatuses: [...new Set(statusLabels)],
    };
  });

  const { preferences: { [UserPreference.OrdersPreferences]: ordersProfilePreferences = {} } = {} } = useAppSelector(
    (state) => state.currentUser,
  );

  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const [filterMessage, setFilterMessage] = React.useState<string>(t(I18nKeys.OrderFilterLast45Days));
  const [customFilterMessage, setCustomFilterMessage] = React.useState('');
  const [dateRangeDialogOpen, setDateRangeDialogOpen] = React.useState(false);
  const [selectedDateFilterMenuItem, setSelectedDateFilterMenuItem] = React.useState(dateFilter);
  const [previousDateFilterMenuItem, setPreviousDateFilterMenuItem] = React.useState(dateFilter);
  const anchorElRef = React.createRef<HTMLDivElement>();

  // Get filters from localStorage if available
  // Set the custom filter message based on the filter type and number of filters
  // Sets the message in the format of 'for n filterType(s)'
  React.useEffect(() => {
    if (groupId && (!groupFilters || !groupFilters.find((groupFilter) => groupFilter.groupId === groupId))) {
      dispatch(fetchGroupFilters());
      dispatch(fetchOrders());
    }
    setCustomFilterMessage(getFilterMessage(groupId, groupFilters, TableFilterType.Order));
  }, [groupId, groupFilters, dispatch]);
  const handleFilterMenuClick = (): void => setFilterMenuAnchorEl(anchorElRef.current);
  const handleFilterMenuClose = (): void => setFilterMenuAnchorEl(null);

  const handleFilterChange = (filter: FilterMenuItems, dateRange?: DateRange): void => {
    let start = startDate;
    let end = endDate;
    if (dateRange) {
      const { startDate: newStartDate, endDate: newEndDate } = dateRange || {};
      [start, end] = [newStartDate, newEndDate].map((date) => date?.toISOString() || '');
    }
    setSelectedDateFilterMenuItem((previousFilter = FilterMenuItems.Days45) => {
      setPreviousDateFilterMenuItem(previousFilter);
      return filter || FilterMenuItems.Days45;
    });
    dispatch(setDateFilter(filter));
    dispatch(setDateRange({ startDate: start, endDate: end }));

    dispatch(
      saveUserPreferences({
        userPreference: UserPreference.OrdersPreferences,
        preferences: {
          ...ordersProfilePreferences,
          date: { ...(ordersProfilePreferences?.date || {}), startDate: start, endDate: end, filter },
        },
      }),
    );
    dispatch(fetchOrders());
  };

  useEffect(() => {
    switch (dateFilter) {
      case FilterMenuItems.Days15:
        setFilterMessage(t(I18nKeys.OrderFilterLast15Days));
        break;
      case FilterMenuItems.Days30:
        setFilterMessage(t(I18nKeys.OrderFilterLast30Days));
        break;
      case FilterMenuItems.Days45:
        setFilterMessage(t(I18nKeys.OrderFilterLast45Days));
        break;
      case FilterMenuItems.Days90:
        setFilterMessage(t(I18nKeys.OrderFilterLast90Days));
        break;
      case FilterMenuItems.MonthCurrent:
        setFilterMessage(t(I18nKeys.OrderFilterThisMonth));
        break;
      case FilterMenuItems.MonthLast:
        setFilterMessage(t(I18nKeys.OrderFilterLastMonth));
        break;
      case FilterMenuItems.AllTime:
        setFilterMessage(t(I18nKeys.OrderFilterAllTime));
        break;
      case FilterMenuItems.Custom:
        setFilterMessage(`${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`);
        break;
      default:
        setFilterMessage(t(I18nKeys.OrderFilterLast45Days));
    }
  }, [dateFilter, selectedDateFilterMenuItem, startDate, endDate, t]);

  const handleDateFilterMenuItemClick = (filter: FilterMenuItems): void => {
    if (filter === FilterMenuItems.Custom) {
      setDateRangeDialogOpen(true);
    }

    handleFilterChange(filter, getFilterDateRange(filter));
    handleFilterMenuClose();
  };

  const handleCustomFilterMenuItemClick = (filter: FilterType): void => {
    let filterType: FilterType | undefined;
    let availableValues: CustomFilterType = [];
    switch (filter) {
      case FilterType.Dealer:
        filterType = FilterType.Dealer;
        availableValues = availableDealers;
        break;
      case FilterType.Owner:
        filterType = FilterType.Owner;
        availableValues = availableOwners;
        break;
      case FilterType.Site:
        filterType = FilterType.Site;
        availableValues = configurators;
        break;
      case FilterType.State:
        filterType = FilterType.State;
        availableValues = orderStates;
        break;
      case FilterType.Status:
        filterType = FilterType.Status;
        availableValues = orderStatuses;
        break;
      case FilterType.SubmitStatus:
        filterType = FilterType.SubmitStatus;
        availableValues = orderSubmitStatuses;
        break;
      default:
    }
    if (filterType) {
      dispatch(
        setFilterDialog({
          groupId,
          tableFilterType: TableFilterType.Order,
          filterType,
          availableFilterValues: availableValues,
        }),
      );
      dispatch(openDialog({ dialog: Dialogs.FilterSelection }));
    }
    handleFilterMenuClose();
  };

  const handleClearFilterMenuItemClick = (): void => {
    dispatch(clearFilters(TableFilterType.Order));
    dispatch(fetchOrders());
    handleFilterMenuClose();
  };

  const transparentFilterIconColor = (filterMenuItem: FilterMenuItems | FilterType): string | undefined => {
    const filtersForGroup = groupFilters.find((groupFilter) => groupFilter.groupId === groupId);
    const filtersForTable = filtersForGroup
      ? filtersForGroup.tableFilters.find((tableFilter) => tableFilter.tableFilterType === TableFilterType.Order)
      : undefined;
    const filtersForMenuItem = filtersForTable
      ? filtersForTable.filters.find((filter) => filter.filterType === filterMenuItem)
      : undefined;
    return filterMenuItem !== selectedDateFilterMenuItem && !filtersForMenuItem ? 'transparent' : undefined;
  };

  const handleDateRangeDialogCancel = (): void => {
    handleFilterChange(previousDateFilterMenuItem, getFilterDateRange(previousDateFilterMenuItem));
    setSelectedDateFilterMenuItem(previousDateFilterMenuItem);
    setDateRangeDialogOpen(false);
  };

  const handleDateRangeDialogApply = (newDateRange: DateRange): void => {
    handleFilterChange(FilterMenuItems.Custom, newDateRange);
    if (newDateRange && newDateRange.startDate && newDateRange.endDate) {
      setFilterMessage(`${newDateRange.startDate.format('MM/DD/YYYY')} - ${newDateRange.endDate.format('MM/DD/YYYY')}`);
    }
    setDateRangeDialogOpen(false);
  };

  const filters = [
    { filter: FilterMenuItems.Days15, label: t(I18nKeys.FilterBy15Days) },
    { filter: FilterMenuItems.Days30, label: t(I18nKeys.FilterBy30Days) },
    { filter: FilterMenuItems.Days45, label: t(I18nKeys.FilterBy45Days) },
    { filter: FilterMenuItems.Days90, label: t(I18nKeys.FilterBy90Days) },
    { filter: FilterMenuItems.MonthCurrent, label: t(I18nKeys.FilterByThisMonth) },
    { filter: FilterMenuItems.MonthLast, label: t(I18nKeys.FilterByLastMonth) },
    // Disabling ALL_TIME for now because it always ends up eventually being a performance problem.
    // leaving the code in place though in case we re-consider.
    { filter: FilterMenuItems.AllTime, label: t(I18nKeys.FilterByAllTime), disabled: true },
    { filter: FilterMenuItems.Custom, label: t(I18nKeys.FilterByCustomDates) },
    { filter: FilterType.Dealer, label: t(I18nKeys.FilterByDealer), disabled: availableDealers.length <= 1 },
    {
      filter: FilterType.Owner,
      label: t(I18nKeys.FilterByOwner),
      disabled: availableOwners.length <= 1 || (!isAdmin && !isManager),
    },
    {
      filter: FilterType.Site,
      label: t(I18nKeys.FilterBySite),
      disabled: configurators.length <= 1,
    },
    { filter: FilterType.State, label: t(I18nKeys.FilterByState) },
    { filter: FilterType.Status, label: t(I18nKeys.FilterByStatus) },
    { filter: FilterType.SubmitStatus, label: t(I18nKeys.FilterBySubmitStatus) },
  ];

  return (
    <Grid container direction="column">
      <Grid container alignItems="center">
        <IconButton aria-controls="filter-menu" aria-haspopup="true" onClick={handleFilterMenuClick} size="large">
          <FilterListIcon />
        </IconButton>
        <Menu
          id="filter-menu"
          anchorEl={filterMenuAnchorEl}
          open={Boolean(filterMenuAnchorEl)}
          onClose={handleFilterMenuClose}
        >
          {filters.map(({ filter, label, disabled }, i) => {
            if (disabled) return null;
            return (
              <>
                <MenuItem
                  key={filter}
                  style={{ paddingRight: '40px' }}
                  onClick={(): void => {
                    if (Object.values(FilterMenuItems).includes(filter)) {
                      handleDateFilterMenuItemClick(filter as FilterMenuItems);
                    } else {
                      handleCustomFilterMenuItemClick(filter as FilterType);
                    }
                  }}
                >
                  <ListItemIcon style={{ color: transparentFilterIconColor(filter) }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <Typography>{label}</Typography>
                </MenuItem>
                {filter === FilterMenuItems.Custom && <Divider />}
              </>
            );
          })}
          <MenuItem style={{ paddingRight: '40px' }} onClick={(): void => handleClearFilterMenuItemClick()}>
            <ListItemIcon>
              <ClearIcon />
            </ListItemIcon>
            <Typography>{t(I18nKeys.ClearFiltersButton)}</Typography>
          </MenuItem>
        </Menu>
        <Typography color="textSecondary" variant="body2">
          {t(I18nKeys.FilterMessage, { count, customFilterMessage, filterMessage })}
        </Typography>
        <DateRangeDialog
          open={dateRangeDialogOpen}
          onApply={handleDateRangeDialogApply}
          onCancel={handleDateRangeDialogCancel}
          dateRange={
            startDate && endDate
              ? { startDate: moment(startDate), endDate: moment(endDate) }
              : {
                  startDate: moment().subtract(45, 'days'),
                  endDate: moment(),
                }
          }
        />
        <FilterSelectionDialog onApply={fetchOrders} />
      </Grid>
      <Grid>
        <div style={{ position: 'relative', bottom: '-20px' }} ref={anchorElRef} />
      </Grid>
    </Grid>
  );
};
