import { Permission } from '@idearoom/types';
import { TFunction } from 'react-i18next';
import { I18nKeys } from './I18nKeys';

export const getPermissionLabel = (permission: Permission, t: TFunction): string | undefined => {
  switch (permission) {
    case Permission.Admin: {
      return t(I18nKeys.PermissionAdmin);
    }
    case Permission.Manager: {
      return t(I18nKeys.PermissionManager);
    }
    case Permission.Sales: {
      return t(I18nKeys.PermissionSales);
    }
    case Permission.SuperUser: {
      return t(I18nKeys.PermissionSuperUser);
    }
    default: {
      return undefined;
    }
  }
};
