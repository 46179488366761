import { AxiosError } from 'axios';

type ErrorProps = {
  status: number | undefined;
  errorMessage: string | undefined;
};

type ErrorResponse =
  | AxiosError
  | {
      response?: any;
    };

/**
 * Attempt to extract information from known errors to avoid similar logic in "catch".
 */
export const extractErrorProps = (error: unknown): ErrorProps => {
  if (error && typeof error === 'object') {
    // @ts-expect-error will go away with TS upgrade
    if ('response' in error && error.response && typeof error.response === 'object') {
      // probably safe to assume error is AxiosError or Error
      const axiosError = error as ErrorResponse;
      const status = axiosError.response?.status;
      let responseBody = axiosError.response?.data || axiosError.response?.body;
      if (!responseBody) {
        return { status, errorMessage: 'No response body' };
      }
      if (typeof responseBody === 'string') {
        responseBody = JSON.parse(responseBody);
      }
      if ('message' in responseBody) {
        // Not connected to handleExpressServerErrors (portalService).
        return { status, errorMessage: responseBody.message };
      }
      if ('error' in responseBody) {
        // response sent by api-public via handleExpressServerErrors
        return { status, errorMessage: responseBody.error };
      }

      return { status, errorMessage: 'Unexpected AxiosError was thrown' };
    }

    // @ts-expect-error will go away with TS upgrade
    if ('message' in error && typeof error.message === 'string') {
      // @ts-expect-error will go away with TS upgrade
      return { status: undefined, errorMessage: error.message };
    }
  }

  return { status: undefined, errorMessage: 'Unexpected error was thrown' };
};
