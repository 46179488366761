import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { Configurator, ConfiguratorEnabledOnProps } from '../types/Configurator';
import { Group, GroupSettings } from '../types/Group';
import { BulkMemberResult, GroupMember, unknownGroupMember } from '../types/GroupMember';
import { GroupState } from '../types/GroupState';

// Action types

export enum GroupActionTypes {
  ADD_CONFIGURATOR_TO_GROUP_SUCCESS = 'ADD_CONFIGURATOR_TO_GROUP_SUCCESS',
  ADD_MEMBER = 'ADD_MEMBER',
  ADD_MEMBER_ERROR = 'ADD_MEMBER_ERROR',
  ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS',
  EDIT_MEMBER = 'EDIT_MEMBER',
  EDIT_MEMBER_ERROR = 'EDIT_MEMBER_ERROR',
  EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS',
  ADD_BULK_MEMBER = 'ADD_BULK_MEMBER',
  ADD_BULK_MEMBER_ERROR = 'ADD_BULK_MEMBER_ERROR',
  ADD_BULK_MEMBER_SUCCESS = 'ADD_BULK_MEMBER_SUCCESS',
  FETCH_GROUP = 'FETCH_GROUP',
  FETCH_GROUP_ERROR = 'FETCH_GROUP_ERROR',
  FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS',
  REMOVE_CONFIGURATOR_FROM_GROUP = 'REMOVE_CONFIGURATOR_FROM_GROUP',
  REMOVE_CONFIGURATOR_FROM_GROUP_ERROR = 'REMOVE_CONFIGURATOR_FROM_GROUP_ERROR',
  REMOVE_CONFIGURATOR_FROM_GROUP_SUCCESS = 'REMOVE_CONFIGURATOR_FROM_GROUP_SUCCESS',
  REMOVE_MEMBER = 'REMOVE_MEMBER',
  REMOVE_MEMBER_ERROR = 'REMOVE_MEMBER_ERROR',
  REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS',
  SEND_NEW_MEMBER_INVITATION = 'SEND_NEW_MEMBER_INVITATION',
  SEND_NEW_MEMBER_INVITATION_ERROR = 'SEND_NEW_MEMBER_INVITATION_ERROR',
  SEND_NEW_MEMBER_INVITATION_SUCCESS = 'SEND_NEW_MEMBER_INVITATION_SUCCESS',
  RESEND_MEMBER_INVITATION = 'RESEND_MEMBER_INVITATION',
  RESEND_MEMBER_INVITATION_ERROR = 'RESEND_MEMBER_INVITATION_ERROR',
  RESEND_MEMBER_INVITATION_SUCCESS = 'RESEND_MEMBER_INVITATION_SUCCESS',
  RESET_MEMBER_PASSWORD = 'RESET_MEMBER_PASSWORD',
  RESET_MEMBER_PASSWORD_ERROR = 'RESET_MEMBER_PASSWORD_ERROR',
  RESET_MEMBER_PASSWORD_SUCCESS = 'RESET_MEMBER_PASSWORD_SUCCESS',
  SET_MEMBER_DIALOG = 'SET_MEMBER_DIALOG',
  TOGGLE_ENABLED_ON_PROPERTY = 'TOGGLE_DEALER_NETWORK_ENABLED',
  TOGGLE_ENABLED_ON_PROPERTY_SUCCESS = 'TOGGLE_DEALER_NETWORK_ENABLED_SUCCESS',
  UPDATE_GROUP_SETTINGS = 'UPDATE_GROUP_SETTINGS',
  UPDATE_GROUP_SETTINGS_SUCCESS = 'UPDATE_GROUP_SETTINGS_SUCCESS',
  GET_FILTERED_MEMBERS = 'GET_FILTERED_MEMBERS',
  SET_FILTERED_MEMBERS = 'SET_FILTERED_MEMBERS',
  SET_GROUP_DIALOG = 'SET_GROUP_DIALOG',
}

// Reducer

export const INITIAL_GROUP_STATE: GroupState = {
  newMember: false,
  editingMember: false,
  loading: false,
  group: undefined,
  member: unknownGroupMember,
};

export const groupReducer: Reducer<GroupState> = (state = INITIAL_GROUP_STATE, groupAction) => {
  switch (groupAction.type) {
    case GroupActionTypes.ADD_CONFIGURATOR_TO_GROUP_SUCCESS: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { configurator },
        } = groupAction;

        const { configurators = [] } = group;
        return { ...state, group: { ...group, configurators: [...configurators, configurator] } };
      }

      return { ...state };
    }

    case GroupActionTypes.REMOVE_CONFIGURATOR_FROM_GROUP: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { configurator },
        } = groupAction;

        let { configurators = [] } = group;

        const configuratorIndex = configurators.findIndex(
          (groupConfigurator) =>
            groupConfigurator.key === configurator.key && groupConfigurator.vendor === configurator.vendor,
        );

        configurators = [...configurators];
        configurators[configuratorIndex] = { ...configurator, updating: true };

        return { ...state, group: { ...group, configurators } };
      }

      return { ...state };
    }

    case GroupActionTypes.REMOVE_CONFIGURATOR_FROM_GROUP_ERROR: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { configurator },
        } = groupAction;

        let { configurators = [] } = group;

        const configuratorIndex = configurators.findIndex(
          (groupConfigurator) =>
            groupConfigurator.key === configurator.key && groupConfigurator.vendor === configurator.vendor,
        );

        configurators = [...configurators];
        configurators[configuratorIndex] = { ...configurator, updating: false };

        return { ...state, group: { ...group, configurators } };
      }

      return { ...state };
    }

    case GroupActionTypes.REMOVE_CONFIGURATOR_FROM_GROUP_SUCCESS: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { configurator },
        } = groupAction;

        let { configurators = [] } = group;
        configurators = configurators.filter(
          (groupConfigurator) =>
            groupConfigurator.key !== configurator.key || groupConfigurator.vendor !== configurator.vendor,
        );

        return {
          ...state,
          group: {
            ...group,
            configurators,
          },
        };
      }

      return { ...state };
    }

    case GroupActionTypes.TOGGLE_ENABLED_ON_PROPERTY_SUCCESS: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { configurator, enabledOnProperty },
        } = groupAction;

        let { configurators } = group;
        configurators = (configurators || []).map((c) => {
          const vendorInfo = c.vendorData && c.vendorData.vendor ? c.vendorData.vendor : {};
          return c.key === configurator.key && c.vendor === configurator.vendor
            ? {
                ...c,
                vendorData: {
                  ...c.vendorData,
                  vendor: {
                    ...vendorInfo,
                    salesToolsEnabled: vendorInfo.salesToolsEnabled || true,
                    dealerNetworkEnabled: vendorInfo.dealerNetworkEnabled || false,
                    pricingEnabled: vendorInfo.pricingEnabled || false,
                    [enabledOnProperty]: !(vendorInfo[enabledOnProperty as ConfiguratorEnabledOnProps] || false),
                  },
                },
              }
            : c;
        });

        return {
          ...state,
          group: {
            ...group,
            configurators,
          },
        };
      }

      return { ...state };
    }

    case GroupActionTypes.SET_MEMBER_DIALOG: {
      const {
        payload: { member, newMember },
      } = groupAction;
      return { ...state, member, newMember, editingMember: false };
    }

    case GroupActionTypes.ADD_MEMBER: {
      return { ...state, editingMember: true };
    }

    case GroupActionTypes.ADD_MEMBER_ERROR: {
      return { ...state, editingMember: false };
    }

    case GroupActionTypes.ADD_MEMBER_SUCCESS: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { member },
        } = groupAction;

        const { members = [] } = group;
        return {
          ...state,
          editingMember: false,
          group: { ...group, members: [...members, member] },
          member: unknownGroupMember,
        };
      }

      return { ...state };
    }

    case GroupActionTypes.EDIT_MEMBER: {
      const {
        payload: { member },
      } = groupAction;
      return { ...state, editingMember: true, member };
    }

    case GroupActionTypes.EDIT_MEMBER_ERROR: {
      return { ...state, editingMember: false };
    }

    case GroupActionTypes.EDIT_MEMBER_SUCCESS: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { member },
        } = groupAction;

        const { members = [] } = group;
        const memberIndex = members.findIndex((mem) => mem.email === member.email);
        if (memberIndex !== -1) {
          return {
            ...state,
            editingMember: false,
            group: {
              ...group,
              members: [
                ...members.slice(0, memberIndex),
                { ...members[memberIndex], ...member },
                ...members.slice(memberIndex + 1, members.length),
              ],
            },
            member: unknownGroupMember,
          };
        }

        return {
          ...state,
          editingMember: false,
          group: { ...group, members },
          member: unknownGroupMember,
        };
      }

      return { ...state };
    }

    case GroupActionTypes.ADD_BULK_MEMBER: {
      return { ...state, editingMember: true };
    }

    case GroupActionTypes.ADD_BULK_MEMBER_ERROR: {
      return { ...state, editingMember: false };
    }

    case GroupActionTypes.ADD_BULK_MEMBER_SUCCESS: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { bulkMemberResult },
        } = groupAction;

        const { members = [] } = group;
        return {
          ...state,
          editingMember: false,
          group: { ...group, members: [...members, ...bulkMemberResult.successfulUsers] },
        };
      }

      return { ...state };
    }

    case GroupActionTypes.REMOVE_MEMBER:
    case GroupActionTypes.SEND_NEW_MEMBER_INVITATION:
    case GroupActionTypes.RESEND_MEMBER_INVITATION:
    case GroupActionTypes.RESET_MEMBER_PASSWORD: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { member },
        } = groupAction;

        let { members = [] } = group;

        const memberIndex = members.findIndex((groupMember) => groupMember.username === member.username);

        members = [...members];
        members[memberIndex] = { ...member, updating: true };

        return { ...state, group: { ...group, members } };
      }

      return { ...state };
    }

    case GroupActionTypes.REMOVE_MEMBER_ERROR:
    case GroupActionTypes.SEND_NEW_MEMBER_INVITATION_ERROR:
    case GroupActionTypes.SEND_NEW_MEMBER_INVITATION_SUCCESS:
    case GroupActionTypes.RESEND_MEMBER_INVITATION_ERROR:
    case GroupActionTypes.RESEND_MEMBER_INVITATION_SUCCESS:
    case GroupActionTypes.RESET_MEMBER_PASSWORD_ERROR:
    case GroupActionTypes.RESET_MEMBER_PASSWORD_SUCCESS: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { member },
        } = groupAction;

        let { members = [] } = group;

        const memberIndex = members.findIndex((groupMember) => groupMember.username === member.username);

        members = [...members];
        members[memberIndex] = { ...member, updating: false };

        return { ...state, group: { ...group, members } };
      }

      return { ...state };
    }

    case GroupActionTypes.UPDATE_GROUP_SETTINGS_SUCCESS: {
      const {
        payload: { settings },
      } = groupAction;

      const { group } = state;

      if (group !== undefined) {
        return {
          ...state,
          group: {
            ...group,
            settings,
          },
        };
      }

      return { ...state };
    }

    case GroupActionTypes.REMOVE_MEMBER_SUCCESS: {
      const { group } = state;

      if (group !== undefined) {
        const {
          payload: { member },
        } = groupAction;

        const { members = [] } = group;
        return {
          ...state,
          group: {
            ...group,
            members: members.filter((groupMember) => groupMember.username !== member.username),
          },
        };
      }

      return { ...state };
    }

    case GroupActionTypes.FETCH_GROUP: {
      return { ...state, group: undefined, loading: true };
    }

    case GroupActionTypes.FETCH_GROUP_ERROR: {
      return { ...state, group: undefined, loading: false };
    }

    case GroupActionTypes.FETCH_GROUP_SUCCESS: {
      const {
        payload: { group },
      } = groupAction;

      return { ...state, group, loading: false };
    }

    default: {
      return state;
    }
  }
};

// Action creators

export const addConfiguratorToGroupSuccess = (configurator: Configurator): AnyAction =>
  action(GroupActionTypes.ADD_CONFIGURATOR_TO_GROUP_SUCCESS, { configurator });

export const setMemberDialog = (member?: GroupMember): AnyAction =>
  action(GroupActionTypes.SET_MEMBER_DIALOG, { member, newMember: !member });

export const addMember = (): AnyAction => action(GroupActionTypes.ADD_MEMBER);

export const addMemberSuccess = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.ADD_MEMBER_SUCCESS, { member });

export const addMemberError = (): AnyAction => action(GroupActionTypes.ADD_MEMBER_ERROR);

export const editMember = (member: GroupMember): AnyAction => action(GroupActionTypes.EDIT_MEMBER, { member });

export const editMemberSuccess = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.EDIT_MEMBER_SUCCESS, { member });

export const editMemberError = (): AnyAction => action(GroupActionTypes.EDIT_MEMBER_ERROR);

export const addBulkMember = (): AnyAction => action(GroupActionTypes.ADD_BULK_MEMBER);

export const addBulkMemberSuccess = (bulkMemberResult: BulkMemberResult): AnyAction =>
  action(GroupActionTypes.ADD_BULK_MEMBER_SUCCESS, { bulkMemberResult });

export const addBulkMemberError = (): AnyAction => action(GroupActionTypes.ADD_BULK_MEMBER_ERROR);

export const fetchGroup = (groupId: string): AnyAction => action(GroupActionTypes.FETCH_GROUP, { groupId });

export const fetchGroupError = (): AnyAction => action(GroupActionTypes.FETCH_GROUP_ERROR);

export const fetchGroupSuccess = (group: Group): AnyAction => action(GroupActionTypes.FETCH_GROUP_SUCCESS, { group });

export const removeConfiguratorFromGroup = (configurator: Configurator): AnyAction =>
  action(GroupActionTypes.REMOVE_CONFIGURATOR_FROM_GROUP, { configurator });

export const removeConfiguratorFromGroupError = (configurator: Configurator): AnyAction =>
  action(GroupActionTypes.REMOVE_CONFIGURATOR_FROM_GROUP_ERROR, { configurator });

export const removeConfiguratorFromGroupSuccess = (configurator: Configurator): AnyAction =>
  action(GroupActionTypes.REMOVE_CONFIGURATOR_FROM_GROUP_SUCCESS, { configurator });

export const removeMember = (member: GroupMember): AnyAction => action(GroupActionTypes.REMOVE_MEMBER, { member });

export const removeMemberError = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.REMOVE_MEMBER_ERROR, { member });

export const removeMemberSuccess = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.REMOVE_MEMBER_SUCCESS, { member });

export const sendNewMemberInvitation = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.SEND_NEW_MEMBER_INVITATION, { member });

export const sendNewMemberInvitationError = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.SEND_NEW_MEMBER_INVITATION_ERROR, { member });

export const sendNewMemberInvitationSuccess = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.SEND_NEW_MEMBER_INVITATION_SUCCESS, { member });

export const resendMemberInvitation = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.RESEND_MEMBER_INVITATION, { member });

export const resendMemberInvitationError = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.RESEND_MEMBER_INVITATION_ERROR, { member });

export const resendMemberInvitationSuccess = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.RESEND_MEMBER_INVITATION_SUCCESS, { member });

export const resetMemberPassword = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.RESET_MEMBER_PASSWORD, { member });

export const resetMemberPasswordError = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.RESET_MEMBER_PASSWORD_ERROR, { member });

export const resetMemberPasswordSuccess = (member: GroupMember): AnyAction =>
  action(GroupActionTypes.RESET_MEMBER_PASSWORD_SUCCESS, { member });

export const toggleEnabledOnProperty = (
  configurator: Configurator,
  enabledOnProperty: ConfiguratorEnabledOnProps,
): AnyAction => action(GroupActionTypes.TOGGLE_ENABLED_ON_PROPERTY, { configurator, enabledOnProperty });

export const toggleEnabledOnPropertySuccess = (
  configurator: Configurator,
  enabledOnProperty: ConfiguratorEnabledOnProps,
): AnyAction => action(GroupActionTypes.TOGGLE_ENABLED_ON_PROPERTY_SUCCESS, { configurator, enabledOnProperty });

export const updateGroupSettings = (settings: GroupSettings): AnyAction =>
  action(GroupActionTypes.UPDATE_GROUP_SETTINGS, { settings });

export const updateGroupSettingsSuccess = (settings: GroupSettings): AnyAction =>
  action(GroupActionTypes.UPDATE_GROUP_SETTINGS_SUCCESS, { settings });
