import { Industry } from '@idearoom/types';
import { Supplier } from './Supplier';
import { VendorData } from './VendorData';
import { ConfiguratorFormFields as ConfiguratorFormFieldNames } from '../constants/FormFields';
import { I18nKeys } from '../constants/I18nKeys';
import { ConfiguratorFormFieldType, ConfiguratorFormType } from '../constants/Configurator';

export enum ConfiguratorEnabledOnProps {
  DealerNetworkEnabled = 'dealerNetworkEnabled',
  SalesToolsEnabled = 'salesToolsEnabled',
  PricingEnabled = 'pricingEnabled',
  ProServicesBranchEnabled = 'proServicesBranchEnabled',
  TemplateEnabled = 'templateEnabled',
}

export interface Configurator {
  readonly updating: boolean;
  readonly key: string;
  readonly vendor: string;
  readonly clientId?: string;
  readonly name?: string;
  readonly url?: string;
  readonly vendorData?: VendorData;
  readonly vendorDataPublishEnabled?: boolean;
  readonly suppliers?: Supplier[];
  readonly supplierKey?: string;
}

export interface ConfiguratorFormField {
  fieldName: ConfiguratorFormFieldNames;
  required?: boolean;
  getHelperLabel?: (form: ConfiguratorFormType) => I18nKeys | undefined;
  getXS?: (form: ConfiguratorFormType) => number;
  disabled?: (form: ConfiguratorFormType) => boolean;
}

export interface ConfiguratorFormTextField extends ConfiguratorFormField {
  type: ConfiguratorFormFieldType.Text;
  label: I18nKeys;
}

export interface ConfiguratorFormSelectField extends ConfiguratorFormField {
  type: ConfiguratorFormFieldType.Select;
  selectOptions: { value: string; label: I18nKeys | string; displayKey?: boolean }[];
  selectLabel: I18nKeys;
  loading?: boolean;
}

export interface ConfiguratorFormRadioSelectField extends ConfiguratorFormField {
  type: ConfiguratorFormFieldType.RadioSelect;
  radioGroup: ConfiguratorFormFieldNames;
  radioOption: string;
  radioLabel: I18nKeys;
  selectOptions: { value: string; label: I18nKeys | string; displayKey?: boolean }[];
  selectLabel: I18nKeys;
  loading?: boolean;
}

export interface ConfiguratorFormHelperText {
  getHelperLabel: (form: ConfiguratorFormType) => I18nKeys | undefined;
}

export interface ConfiguratorFormText {
  label: I18nKeys;
}

export type ConfiguratorFormFields =
  | ConfiguratorFormTextField
  | ConfiguratorFormSelectField
  | ConfiguratorFormRadioSelectField
  | ConfiguratorFormHelperText
  | ConfiguratorFormText;

export type ConfiguratorFormProps = {
  title: I18nKeys;
  submitButtonLabel: I18nKeys;
  fields: ConfiguratorFormFields[];
};

export type ConfiguratorFormValues = {
  [ConfiguratorFormFieldNames.Name]?: string;
  [ConfiguratorFormFieldNames.GroupId]?: string;
  [ConfiguratorFormFieldNames.Key]?: string;
  [ConfiguratorFormFieldNames.Subdomain]?: string;
  [ConfiguratorFormFieldNames.Domain]?: string;
  [ConfiguratorFormFieldNames.Industry]?: Industry;
  [ConfiguratorFormFieldNames.ExistingKey]?: string;
  [ConfiguratorFormFieldNames.Supplier]?: string;
  [ConfiguratorFormFieldNames.SupplierDataTemplate]?: string;
  [ConfiguratorFormFieldNames.VendorDataTemplate]?: string;
  [ConfiguratorFormFieldNames.Type]?: string;
  [ConfiguratorFormFieldNames.GenerateKey]?: boolean;
};
