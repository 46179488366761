import { Button, Dialog, DialogActions, DialogContent, Divider, Grid2 as Grid, Link, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { CloudDownload } from '@mui/icons-material';
import { Configurator } from '../types/Configurator';
import { Order } from '../types/Order';
import { getSiteFromClientId } from '../utils/clientIdUtils';
import { formatPhoneNumber } from '../utils/phoneNumberUtils';
import { hyphenateString } from '../utils/stringUtils';
import { formatPrice } from '../utils/pricingUtils';
import { NewWindowLink } from './NewWindowLink';
import { I18nKeys } from '../constants/I18nKeys';
import { AppState } from '../types/AppState';
import { useAppSelector } from '../hooks';
import { getOrderOwnerFromOrder } from '../utils/orderUtils';
import { unknownGroup } from '../constants/Group';
import { anyHasSmartBuildIntegrationEnabled } from '../utils/vendorDataUtils';

const useStyles = makeStyles((theme: Theme) => ({
  imageContainer: {
    position: 'relative',
    background: theme.palette.grey[200],
  },
  image: {
    width: '100%',
    minHeight: '370px',
  },
  link: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  floorplanContainer: {
    position: 'relative',
    background: theme.palette.grey[200],
    marginTop: theme.spacing(1),
  },
  floorplanImage: {
    width: '100%',
    minHeight: '370px',
    padding: `${theme.spacing(1)} ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
  },
  gridRow: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wrapItem: {
    overflowWrap: 'break-word',
  },
  actions: {
    justifyContent: 'space-between',
  },
}));

interface Props {
  readonly open: boolean;
  readonly order?: Order;
  readonly configurators?: Configurator[];
  readonly admin?: boolean;
  handleClose(): void;
  handleDelete?(): void;
}

export const OrderDetailsDialog: React.FC<Props> = ({
  order,
  open,
  configurators,
  admin,
  handleClose,
  handleDelete,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currency } = useAppSelector((state: AppState) => state?.viewer);
  const { members } = useAppSelector((state: AppState) => state?.group.group || unknownGroup);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!order) return <></>;

  const {
    hash,
    customerName,
    customerEmail,
    customerPhone,
    date,
    clientId,
    dealerEmail: initialDealerEmail,
    dealerName: initialDealerName,
    orderDealerKey,
    orderDealerEmail,
    orderDealerName,
    orderDealerLink,
    orderStatusName,
    orderStatusI18nKey,
    ipAddress,
    link: initialLink,
    buildingImage,
    floorplanImage,
    totalPrice,
    buildingStyle,
    versionedEmailId,
    smartbuildJobId,
  } = order;

  const { name: ownerName, email: ownerEmail } = getOrderOwnerFromOrder(order, members);

  let link = initialLink;
  let dealerEmail = initialDealerEmail;
  let dealerName = initialDealerName;
  if (orderDealerKey) {
    link = orderDealerLink;
    dealerEmail = orderDealerEmail;
    dealerName = orderDealerName;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" scroll="body" fullWidth>
      <DialogContent>
        <Grid container direction="column">
          {customerName && (
            <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.gridRow}>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.OrderDetailsDialogName)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Typography className={classes.wrapItem} variant="body2">
                  {customerName}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.gridRow}>
            <Grid size={{ xs: 4 }}>
              <Typography color="textSecondary" variant="body2">
                {t(I18nKeys.FieldEmail)}
              </Typography>
            </Grid>
            <Grid size="grow">
              <Link className={classes.wrapItem} href={`mailto:${customerEmail}`} variant="body2">
                {customerEmail}
              </Link>
            </Grid>
          </Grid>
          {customerPhone && (
            <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.gridRow}>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.FieldPhone)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Link className={classes.wrapItem} href={`tel:${formatPhoneNumber(customerPhone)}`} variant="body2">
                  {formatPhoneNumber(customerPhone)}
                </Link>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.gridRow}>
            <Grid size={{ xs: 4 }}>
              <Typography color="textSecondary" variant="body2">
                {t(I18nKeys.OrderDetailsDialogDate)}
              </Typography>
            </Grid>
            <Grid size="grow">
              <Typography className={classes.wrapItem} variant="body2">
                {`${moment(date).format('MMM DD, h:mm A')} from ${ipAddress}`}
              </Typography>
            </Grid>
          </Grid>
          {clientId && (
            <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.gridRow}>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.OrderDetailsDialogSite)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Typography className={classes.wrapItem} variant="body2">
                  {getSiteFromClientId(clientId, configurators)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      {(dealerName || dealerEmail) && (
        <>
          <DialogContent>
            <Grid container direction="column">
              <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.gridRow}>
                <Grid size={{ xs: 4 }}>
                  <Typography color="textSecondary" variant="body2">
                    {t(I18nKeys.OrderDetailsDialogDealer)}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 8 }}>
                  {dealerName && (
                    <Typography className={classes.wrapItem} variant="body2">
                      {dealerName}
                    </Typography>
                  )}
                  {dealerEmail && (
                    <Link className={classes.wrapItem} href={`mailto:${dealerEmail}`} variant="body2">
                      {dealerEmail}
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
        </>
      )}
      <DialogContent>
        <Grid container direction="column">
          <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.gridRow}>
            <Grid size={{ xs: 4 }}>
              <Typography color="textSecondary" variant="body2">
                {t(I18nKeys.OrderDetailsDialogOwner)}
              </Typography>
            </Grid>
            <Grid size={{ xs: 8 }}>
              <Typography className={classes.wrapItem} variant="body2">
                {ownerName || 'Not Assigned'}
              </Typography>
              {ownerEmail && (
                <Grid size="grow">
                  <Link className={classes.wrapItem} href={`mailto:${ownerEmail}`} variant="body2">
                    {ownerEmail}
                  </Link>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.gridRow}>
            <Grid size={{ xs: 4 }}>
              <Typography color="textSecondary" variant="body2">
                {t(I18nKeys.OrderDetailsDialogStatus)}
              </Typography>
            </Grid>
            <Grid size="grow">
              <Typography className={classes.wrapItem} variant="body2">
                {(orderStatusI18nKey && t(orderStatusI18nKey)) || orderStatusName || 'New Lead'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogContent>
        <Grid container direction="column">
          {hash && (
            <Grid container justifyContent="flex-start" alignItems="baseline" spacing={1} className={classes.gridRow}>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.OrderDetailsDialogHash)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Typography className={classes.wrapItem} variant="body2">
                  {hash}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="flex-start" alignItems="baseline" spacing={1} className={classes.gridRow}>
            <Grid size={{ xs: 4 }}>
              <Typography color="textSecondary" variant="body2">
                {t(I18nKeys.OrderDetailsDialogReference)}
              </Typography>
            </Grid>
            <Grid size="grow">
              <Typography className={classes.wrapItem} variant="body2">
                {hyphenateString(versionedEmailId)}
              </Typography>
            </Grid>
          </Grid>
          {smartbuildJobId && anyHasSmartBuildIntegrationEnabled(configurators) && (
            <Grid container justifyContent="flex-start" alignItems="baseline" spacing={1} className={classes.gridRow}>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.OrderDetailsDialogSmartBuild)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Link
                  href={`https://postframesolver.azurewebsites.net/Framer/Edit/${smartbuildJobId}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Typography className={classes.wrapItem} variant="body2">
                    {smartbuildJobId}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          )}
          {totalPrice && (
            <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.gridRow}>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.OrderDetailsDialogAmount)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Typography className={classes.wrapItem} variant="body2">
                  {formatPrice(totalPrice, currency)}
                </Typography>
              </Grid>
            </Grid>
          )}
          {buildingStyle && (
            <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.gridRow}>
              <Grid size={{ xs: 4 }}>
                <Typography color="textSecondary" variant="body2">
                  {t(I18nKeys.OrderDetailsDialogStyle)}
                </Typography>
              </Grid>
              <Grid size="grow">
                <Typography className={classes.wrapItem} variant="body2">
                  {buildingStyle}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <div className={classes.imageContainer}>
        <img src={buildingImage} alt="building" className={classes.image} />
        <NewWindowLink link={link} className={classes.link} />
      </div>
      <div className={(classes.imageContainer, classes.floorplanContainer)}>
        <img src={floorplanImage} alt="floorplan" className={(classes.image, classes.floorplanImage)} />
        <NewWindowLink link={floorplanImage} className={classes.link} icon={CloudDownload} />
      </div>
      <DialogActions className={handleDelete && admin ? classes.actions : undefined}>
        {handleDelete && admin && (
          <Button onClick={handleDelete} color="primary">
            {t(I18nKeys.DialogDeleteButton)}
          </Button>
        )}
        <Button onClick={handleClose} color="primary">
          {t(I18nKeys.DialogCloseButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
