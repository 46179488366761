/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { navigate } from 'hookrouter';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { signOut } from '@aws-amplify/auth';
import IdeaRoomLogo from '../images/idearoom.svg';
import { useGetSubscriptionDataByIdQuery, useSharedAddClientMutation } from '../services/clientApi';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientSignUpFormFields, SignInFormFields } from '../constants/FormFields';
import { AppRoutes } from '../constants/AppRoutes';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { LocalStorage } from '../constants/LocalStorage';
import { ClientSignUpForm, FormData } from './ClientSignUpForm';

const CenteredPaper = styled(Paper)(() => ({
  position: 'absolute',
  top: '50px',
  width: '70%',
  maxWidth: '680px',
  height: 'calc(100vh - 50px)',
}));

export const ClientSignUp: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const subscriptionId = useAppSelector((state) => state.signUp.subscriptionId || '');

  const [loading, setLoading] = React.useState<boolean>(true);
  const [step, setStep] = React.useState<number>(0);

  const {
    data: {
      customer: {
        firstName = '',
        lastName = '',
        address = '',
        city = '',
        state = '',
        zip = '',
        phone = '',
        organization: company = '',
        email = '',
      } = {},
      offer: { industry = '', type = '' } = {},
      authenticated,
    } = {},
    isUninitialized: isUninitializedSubscriptionData,
    isLoading: isLoadingSubscriptionData,
  } = useGetSubscriptionDataByIdQuery(subscriptionId, {
    skip: !subscriptionId,
  });

  const [addClient] = useSharedAddClientMutation();

  useEffect(() => {
    if (!isUninitializedSubscriptionData && !isLoadingSubscriptionData) {
      if (authenticated) {
        setLoading(false);
        return;
      }
      // User's subscription is not authenticated, redirect to sign in
      toast.error(t(I18nKeys.ClientSignUpSubscriptionInvalid));
      navigate(AppRoutes.SignIn);
    }
  }, [isUninitializedSubscriptionData, isLoadingSubscriptionData, authenticated, t]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading && <CircularProgress style={{ alignSelf: 'center' }} color="primary" />}
      {!loading && (
        <CenteredPaper elevation={0}>
          <Box sx={{ textAlign: 'center' }}>
            <img alt="IdeaRoom Logo" src={IdeaRoomLogo} />
          </Box>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: '#323B4B' }} gutterBottom>
            {step === 0 ? `Let's create` : `Creating`} your 3D configurator site...
          </Typography>
          <ClientSignUpForm
            step={step}
            setStep={setStep}
            onSubmit={async ({
              [ClientSignUpFormFields.Industry]: userIndustry,
              [ClientSignUpFormFields.SupplierOrTemplate]: userSupplierOrTemplate,
              [ClientSignUpFormFields.Email]: userEmail,
              [ClientSignUpFormFields.Password]: userPassword,
            }: FormData) => {
              const {
                client: { groupId },
              } = await addClient({
                firstName,
                lastName,
                company: company || '',
                email: userEmail,
                password: userPassword,
                address,
                city,
                state,
                zip,
                phone,
                subscriptionId,
                supplierOrTemplate: userSupplierOrTemplate?.id,
                industry: userIndustry,
                type,
              }).unwrap();
              localStorage.setItem(LocalStorage.SelectedGroupId, groupId);

              if (userPassword) {
                await signOut();
                await new Promise((resolve, reject): void => {
                  dispatch({
                    type: `${Forms.SignIn}_SUBMIT`,
                    values: {
                      [SignInFormFields.Email]: userEmail,
                      [SignInFormFields.Password]: userPassword,
                    },
                    resolve,
                    reject,
                  });
                });
              }
            }}
            initialValues={{
              [ClientSignUpFormFields.Industry]: industry,
              [ClientSignUpFormFields.Type]: type,
              [ClientSignUpFormFields.Email]: email,
            }}
          />
        </CenteredPaper>
      )}
    </Box>
  );
};
