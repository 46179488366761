import { Dispatch } from 'react';
import { TFunction } from 'react-i18next';
import {
  PricingSurcharge,
  PricingSurchargeCalculation,
  PricingSurchargeCalculationType,
  PricingSurchargeCategory,
  PricingSurchargeCondition,
  PricingSurchargeConditionType,
  PricingSurchargeRule,
  PricingSurchargeVaryCondition,
  PricingSurchargeVaryConditionOption,
} from '@idearoom/types';
import {
  PricingSurchargeConditions,
  noPricingSurcharge,
  defaultPricingSurcharge,
  maxRoundTo,
  newSurchargeRule,
  defaultSurchargeCalculation,
  PRICING_SURCHARGE_COLUMN,
} from '../constants/PricingSurcharge';
import { i18n } from '../i18n';
import { getVendorFromClientId } from './clientIdUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { Commit } from '../types/Commit';
import { LocalStorage } from '../constants/LocalStorage';
import { openNotificationDialog } from '../ducks/notificationDialog';
import { TableData } from '../types/DataGrid';
import { ComponentCategoryItemWithConditions } from '../types/PricingClientUpdate';
import {
  getComponentCategoryItemColumnValue,
  getComponentCategoryItems,
  groupComponentCategoryItems,
} from './pricingClientUpdateUtils';
import { DisplayColumns, HelperColumns } from '../constants/PricingClientUpdate';
import { ComponentCategoryKey } from '../constants/ClientUpdateCategoryKey';
import { currencySymbols } from '../constants/Pricing';

export const parseClientSurcharges = (tableData: TableData[]): PricingSurcharge[] => {
  const [{ [PRICING_SURCHARGE_COLUMN]: surcharges = [] } = {}] = tableData || [];
  if (typeof surcharges === 'string') return JSON.parse(surcharges);

  return Array.isArray(surcharges) ? (surcharges as PricingSurcharge[]) : [surcharges as any as PricingSurcharge];
};

export const removeCurrencySymbolsCommasAndSpaces = (value: string | undefined): string => {
  if (!value) return '';
  const regex = new RegExp(`[${currencySymbols.join('')},\\s]`, 'g');
  const replaced = value.replace(regex, '').trim();
  return replaced;
};

export const getSurcharge = (
  surcharges: PricingSurcharge[],
  clientId: string,
  supplierKey: string | undefined,
): PricingSurcharge => {
  if (supplierKey) {
    return (
      surcharges.find(
        (surcharge) => surcharge.supplierKey === supplierKey && supplierKey !== getVendorFromClientId(clientId),
      ) || defaultPricingSurcharge
    );
  }
  return surcharges.find((surcharge) => surcharge.supplierKey === undefined) || defaultPricingSurcharge;
};

/**
 * Attempts to find a surcharge in a list of surcharges based on a supplierKey and pricing selection
 * If the pricingSelection param is NONE, return a noPricingSurcharge object
 * If there is a surcharge found, return it filling in any missing props with defaultPricingSurcharge values
 *
 * @param clientId clientId to use as a fallback
 * @param pricingSurcharges list of PricingSurcharge to search
 * @param supplierKey supplierKey to find
 * @param pricingSelection pricingSelection type to find
 * @returns PricingSurcharge with the given supplierKey and pricingSelection otherwise returns a default surcharge
 */
export const findMatchingSurchargeCalculation = (
  clientId: string,
  pricingSurcharges: PricingSurcharge[],
  supplierKey: string | undefined,
  pricingSelection: PricingSurchargeCalculationType,
): PricingSurcharge => {
  const vendor = getVendorFromClientId(clientId) || undefined;
  const matchingCalculation = pricingSurcharges.find(
    (surcharge) =>
      (surcharge.supplierKey || vendor) === (supplierKey || vendor) &&
      (!pricingSelection || surcharge.key === pricingSelection),
  );
  return {
    ...(pricingSelection === PricingSurchargeCalculationType.None ? noPricingSurcharge : defaultPricingSurcharge),
    ...matchingCalculation,
    supplierKey,
    key: pricingSelection,
  };
};

export const findSelectedSurcharge = (tableData: TableData[], clientId: string, supplierKey: string) => {
  const surcharges = parseClientSurcharges(tableData);

  if (!clientId) {
    return defaultPricingSurcharge;
  }

  const vendor = getVendorFromClientId(clientId);
  const selectedSurcharge = surcharges.find(
    (surcharge) => (surcharge.supplierKey || vendor) === (supplierKey || vendor),
  );

  return selectedSurcharge || defaultPricingSurcharge;
};

/**
 * Maps a list of PricingSurchargeVaryConditionOption to a list of PricingSurchargeVaryConditionOption
 *
 * @param conditionOptions list of PricingSurchargeVaryConditionOption
 * @returns list of PricingSurchargeVaryConditions
 */
export const mapPricingSurchargeConditionOptions = (
  conditionOptions: PricingSurchargeVaryConditionOption[] = [],
): PricingSurchargeVaryCondition[] =>
  PricingSurchargeConditions.map((condition) => ({
    ...condition,
    options: conditionOptions.filter((option) => option.optionType === condition.value),
  }));

/**
 * Validate if price string contains decimal values
 *
 * @param priceAsString price value
 * @returns if price contains decimal values
 */
export const isDecimalPrice = (priceAsString: string | undefined) => {
  const price = parseFloat(removeCurrencySymbolsCommasAndSpaces(priceAsString || ''));

  if (Number.isNaN(price)) return false;
  return price % 1 !== 0;
};

/**
 * Returns the currency symbol for the current vendor locale
 *
 * @param currency vendor currency format
 * @returns currency symbol
 */
export const getCurrencySymbol = (currency = 'USD'): string =>
  new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(0)
    .replace('0', '')
    .trim();

/**
 * Takes a string or number and converts it to a number with a given number of decimal places
 *
 * @param value string or number to convert into a number
 * @param decimalPlaces number of decimal places to round to
 * @returns formatted number as a string
 */
export const formatNumber = (value: string | number | null, decimalPlaces = 2): string => {
  const valueAsNumber = typeof value === 'number' ? value : parseFloat(value || '0');
  return valueAsNumber.toFixed(decimalPlaces);
};

/**
 * Takes a string or number and converts it to a price
 *
 * @param price string or number to convert into a price
 * @returns formatted price as a string
 */
export const formatPrice = (
  price: string | number | null,
  currency = 'USD',
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
): string => {
  const formattedPrice = typeof price === 'number' ? price.toString() : price || '0';
  const priceWithoutSymbolOrCommas = [getCurrencySymbol(currency), '$', ','].reduce(
    (acc, symbol) => acc.replace(symbol, '').replace(',', ''),
    formattedPrice,
  );

  const formattedPriceAsNumber = Number(priceWithoutSymbolOrCommas);

  if (Number.isNaN(formattedPriceAsNumber)) {
    return formattedPrice;
  }

  const hasDecimalPoint = formattedPriceAsNumber % 1 !== 0;

  return new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: hasDecimalPoint ? 2 : minimumFractionDigits,
    maximumFractionDigits: Math.max(hasDecimalPoint ? 2 : 0, maximumFractionDigits),
  }).format(formattedPriceAsNumber);
};

/**
 * Count of the unique labels within a list of PricingSurchargeVaryConditionOptions
 *
 * @param pricingSurchargeVaryConditionOption list of PricingSurchargeVaryConditionOption to count labels in
 * @returns count of unique labels in PricingSurchargeVaryConditionOptions
 */
export const countUniqueLabels = (
  pricingSurchargeVaryConditionOption: PricingSurchargeVaryConditionOption[] = [],
): number => {
  const uniqueLabels: string[] = [];
  pricingSurchargeVaryConditionOption.forEach(
    (option) => option.label && !uniqueLabels.includes(option.label) && uniqueLabels.push(option.label),
  );
  return uniqueLabels.length;
};

/**
 * Checks to see if the surcharge has a valid surcharge calculation
 *
 * @param calculation surcharge to validate the calculation on
 * @returns boolean value based on the validity of the surcharge calculation
 */
export const hasSurchargeCalculation = (calculation: PricingSurcharge | undefined): boolean => {
  const hasCalc = (calc: PricingSurchargeCalculation): boolean =>
    (!!calc.amountChange && calc.amountChange !== 0) || (!!calc.percentChange && calc.percentChange !== 0);

  if (!calculation || !calculation.calculation) return false;
  // A surcharge is valid if it has a default amount/percentage or at least one rule has an amount/percentage
  return (
    hasCalc(calculation.calculation) ||
    (calculation.rules && !!calculation.rules.find((rule) => hasCalc(rule.calculation)))
  );
};

/**
 * Takes a PricingSurcharge and returns whether it affects the base prices
 *
 * @param calculation PricingSurcharge to check categories of
 * @returns boolean whether or not the calculation affects to base prices
 */
export const affectsBasePrice = (calculation: PricingSurcharge | undefined): boolean =>
  !!calculation && calculation.categories && calculation.categories.includes(PricingSurchargeCategory.BasePrice);

/**
 * Takes a PricingSurcharge and returns whether it is a line item surcharge
 *
 * @param calculation PricingSurcharge to check categories of
 * @returns boolean whether or not the calculation is a line item surcharge
 */
export const isLineItemSurcharge = (calculation: PricingSurcharge | undefined): boolean =>
  !!calculation && calculation.categories && calculation.key === PricingSurchargeCalculationType.LineItem;

/**
 * Takes a surcharge rule and condition type. Checks if all the keys in the condition are checked.
 *
 * @param rule PricingSurchargeRule to check the keys of
 * @param type condition type of the rule
 * @param optionKeys option keys to check against the selected keys of the rule
 * @returns whether or not all of the option keys in the rule are checked
 */
export const allKeysAreSelected = (
  rule: PricingSurchargeRule,
  type: PricingSurchargeConditionType,
  optionKeys: string[],
): boolean => {
  const surchargeCondition = rule.conditions.find((condition) => condition.type === type) as PricingSurchargeCondition;
  if (surchargeCondition && surchargeCondition.keys && optionKeys.length > 0) {
    const checkedKeys = [...surchargeCondition.keys];
    return optionKeys.every((key) => checkedKeys.includes(key));
  }
  return false;
};

/**
 * Updates a list of PricingSurcharges with a given PricingSurcharge.
 * If the PricingSurcharge exists in the surcharge for the supplierKey, replace it
 * Otherwise add it as a new PricingSurcharge
 * If PricingSurcharge is undefined, don't add it
 *
 * @param clientId clientId to use as a fallback
 * @param calculations list of PricingSurcharge
 * @param surcharge PricingSurcharge
 * @param deleteSurchargeCalculation If set, deletes the surcharge from the list, defaults to false
 * @param pricingSelection defaults to false
 * @returns list of PricingSurcharge with the given PricingSurcharge updated in the list
 */
export const reconcileSurchargeCalculations = (
  clientId: string,
  pricingSurcharges: PricingSurcharge[] = [],
  surcharge: PricingSurcharge,
  deleteSurcharge = false,
  pricingSelection = false,
): PricingSurcharge[] => {
  const vendor = getVendorFromClientId(clientId) || undefined;
  return [
    ...pricingSurcharges.filter(
      (pricingSurcharge) =>
        (pricingSurcharge.supplierKey || vendor) !== (surcharge.supplierKey || vendor) ||
        (pricingSelection && (pricingSurcharge.key || undefined) !== (surcharge.key || undefined)),
    ),
    ...(deleteSurcharge ? [] : [surcharge]),
  ];
};

/**
 * Adds a new PricingSurchargeCategory to a PricingSurcharge
 *
 * @param surcharge PricingSurcharge to add the category to
 * @param category PricingSurchargeCategory to add
 * @returns PricingSurcharge with the category added
 */
export const addPricingCategory = (surcharge: PricingSurcharge, pricingCategory: PricingSurchargeCategory) => ({
  ...surcharge,
  categories: [...surcharge.categories, pricingCategory],
});

/**
 * Removes a PricingSurchargeCategory from a PricingSurcharge
 *
 * @param surcharge PricingSurcharge to remove the category from
 * @param category PricingSurchargeCategory to remove
 * @returns PricingSurcharge with the category removed
 */
export const removePricingCategory = (surcharge: PricingSurcharge, pricingCategory: PricingSurchargeCategory) => ({
  ...surcharge,
  categories: surcharge.categories.filter((category) => category !== pricingCategory),
});

/**
 * Add surcharge pricing rule to a surcharge
 *
 * @param surcharge PricingSurcharge to add the rule to
 * @param pricingSurchargeRule PricingSurchargeRule to add
 * @returns PricingSurcharge with the rule added
 */
export const addPricingRule = (surcharge: PricingSurcharge) => {
  const newRuleId = Math.max(0, ...surcharge.rules.map((rule) => rule.id)) + 1;
  // strip out any key values but keep the condition types
  const newConditions: PricingSurchargeCondition[] = surcharge.rules[0].conditions.map(
    ({ type }) => ({ type } as PricingSurchargeCondition),
  );

  return {
    ...surcharge,
    rules: [...surcharge.rules, newSurchargeRule(newRuleId, newConditions)],
  };
};

/**
 * Update a surcharge pricing rule
 *
 * @param surcharge PricingSurcharge to update the rule on
 * @param pricingSurchargeRule PricingSurchargeRule to update
 * @returns PricingSurcharge with the rule updated
 */
export const updatePricingRule = (surcharge: PricingSurcharge, pricingSurchargeRule: PricingSurchargeRule) => {
  const ruleExists = surcharge.rules.find((rule) => rule.id === pricingSurchargeRule.id);
  if (ruleExists) {
    const ruleIndex = surcharge.rules.findIndex((rule) => rule.id === pricingSurchargeRule.id);
    return {
      ...surcharge,
      rules: [
        ...surcharge.rules.slice(0, ruleIndex),
        pricingSurchargeRule,
        ...surcharge.rules.slice(ruleIndex + 1, surcharge.rules.length),
      ],
    };
  }
  return surcharge;
};

/**
 * Remove a surcharge pricing rule
 *
 * @param surcharge PricingSurcharge to remove the rule from
 * @param pricingSurchargeRuleId PricingSurchargeRule to remove
 * @returns PricingSurcharge with the rule removed
 */
export const removePricingRule = (surcharge: PricingSurcharge, pricingSurchargeRuleId: number) => ({
  ...surcharge,
  rules: surcharge.rules.filter((rule) => rule.id !== pricingSurchargeRuleId),
});

/**
 * Add pricing condition to all pricing rules
 *
 * @param surcharge PricingSurcharge to add the condition to
 * @param condition PricingSurchargeCondition to add
 * @returns PricingSurcharge with the condition added to all rules
 */
export const addPricingConditionToAllRules = (surcharge: PricingSurcharge, condition: string): PricingSurcharge => {
  const newRules = [];
  newRules.push(...surcharge.rules);

  // If no rules exist create one
  if (newRules.length === 0) {
    newRules.push({ id: 0, conditions: [], calculation: defaultSurchargeCalculation });
  }

  return {
    ...surcharge,
    rules: newRules.map((rule) => ({
      ...rule,
      conditions: [
        ...rule.conditions,
        { type: condition as PricingSurchargeConditionType } as PricingSurchargeCondition,
      ],
    })),
  };
};

/**
 * Remove pricing condition from all pricing rules
 *
 * @param surcharge PricingSurcharge to remove the condition from
 * @param condition PricingSurchargeCondition to remove
 * @returns PricingSurcharge with the condition removed from all rules
 */
export const removePricingConditionFromAllRules = (
  surcharge: PricingSurcharge,
  condition: string,
): PricingSurcharge => {
  const { rules } = surcharge;

  const rulesWithPricingSurchargeVaryConditionRemoved = rules.map((rule) => ({
    ...rule,
    conditions: [...rule.conditions.filter((ruleCondition) => ruleCondition.type !== condition)],
  }));

  const newRules = rulesWithPricingSurchargeVaryConditionRemoved.every((rule) => rule.conditions.length === 0)
    ? []
    : rulesWithPricingSurchargeVaryConditionRemoved;

  return {
    ...surcharge,
    rules: newRules,
  };
};

/**
 * Update surcharge rule condition
 *
 * @param surcharge PricingSurcharge to update the condition on
 * @param ruleId id of the rule to update
 * @param conditionType PricingSurchargeConditionType to update
 * @param condition PricingSurchargeCondition to update
 * @returns PricingSurcharge with the condition updated
 */
export const updatePricingRuleCondition = (
  surcharge: PricingSurcharge,
  ruleId: number,
  surchargeConditionType: PricingSurchargeConditionType,
  surchargeCondition: { minimum: number; maximum: number } | string[],
): PricingSurcharge => {
  const newRules: PricingSurchargeRule[] = surcharge.rules.map((rule) => {
    if (rule.id !== ruleId) {
      return rule;
    }
    return {
      ...rule,
      conditions: rule.conditions.map((condition) => {
        if (condition.type !== surchargeConditionType) {
          return condition;
        }
        if (Array.isArray(surchargeCondition)) {
          return {
            type: surchargeConditionType,
            keys: surchargeCondition,
          } as PricingSurchargeCondition;
        }
        return {
          type: surchargeConditionType,
          minimum: surchargeCondition.minimum,
          maximum: surchargeCondition.maximum,
        } as PricingSurchargeCondition;
      }),
    };
  });

  return {
    ...surcharge,
    rules: newRules,
  };
};

/**
 * Gets the pricing book options from a list of pricing conditions
 *
 * @param {PricingSurchargeVaryCondition[]} conditionOptions list of pricing conditions
 * @returns {PricingSurchargeVaryConditionOption[]} list of price book pricing condition options
 */
export const getPriceBooks = (
  conditionOptions: PricingSurchargeVaryCondition[],
): PricingSurchargeVaryConditionOption[] => {
  const priceBookOptions = conditionOptions.find((option) => option.value === PricingSurchargeConditionType.Region);
  return priceBookOptions ? priceBookOptions.options : [];
};

/**
 * Parsing the surcharge roundTo value. Values are restricted to whole numbers between 0 and 100 (inclusive).
 *
 * @param {string | undefined} input Round to value to be parsed
 * @returns {number} Parsed value
 */
export const parseRoundTo = (input: string | undefined): number => {
  let inputAsInt = parseInt(input || '', 10);
  // Do not allow values over maximum round to value or below 0
  if (!!inputAsInt && inputAsInt > maxRoundTo) inputAsInt = maxRoundTo;
  if (!!inputAsInt && inputAsInt < 0) inputAsInt = 0;
  // Can return a value of 0, but this is interpreted as $0.01 when applied in estimate
  return !!inputAsInt || inputAsInt === 0 ? inputAsInt : 0;
};

export const getValidatedNewValue = (newValue: string | number, oldValue: string): string => {
  const unformattedNewValue =
    typeof newValue === 'string' ? removeCurrencySymbolsCommasAndSpaces(newValue) : newValue.toString();
  let valueAsNumber = Number(unformattedNewValue);
  if (Number.isNaN(valueAsNumber)) {
    return oldValue;
  }
  // Only allow newValue to have 2 decimal places
  valueAsNumber = Math.round(valueAsNumber * 100) / 100;
  // price column is a string so return as a string type
  return valueAsNumber.toString();
};

export const arePriceValuesDifferent = (oldValue: any, newValue: any): boolean => {
  // need to check the value as a float so that 1.00 is the same as 1
  const a =
    newValue === null || newValue === undefined ? '' : parseFloat(removeCurrencySymbolsCommasAndSpaces(newValue));
  const b =
    oldValue === null || oldValue === undefined ? '' : parseFloat(removeCurrencySymbolsCommasAndSpaces(oldValue));
  return a !== b;
};

export const notifyOfDataChange = ({
  groupId,
  clientId,
  latestCommit,
  key,
  dispatch,
  t,
}: {
  groupId: string;
  clientId: string;
  latestCommit: Commit | undefined;
  key: LocalStorage;
  dispatch: Dispatch<any>;
  t: TFunction;
}) => {
  if (latestCommit) {
    const lastKnownCommit = JSON.parse(localStorage.getItem(key) || '{}');
    const lastKnownCommitHash = lastKnownCommit?.[groupId]?.[clientId];

    if (lastKnownCommitHash !== latestCommit.hash) {
      lastKnownCommit[groupId] = {
        ...(lastKnownCommit[groupId] || {}),
        [clientId]: latestCommit.hash,
      };

      localStorage.setItem(key, JSON.stringify(lastKnownCommit));
    }

    if (lastKnownCommitHash && lastKnownCommitHash !== latestCommit.hash) {
      dispatch(
        openNotificationDialog(
          '',
          t(I18nKeys.PricingBaseDataChangeDialogMessage, {
            author: latestCommit.committer,
          }),
        ),
      );
      dispatch(openDialog({ dialog: Dialogs.Notification }));
    }
  }
};

/**
 * Checks if the items have duplicate labels
 *
 * @param items Items with label and key properties
 * @returns boolean
 */
export const hasDuplicateLabels = (
  rowData: Record<string, string | number | null | undefined>,
  category: ComponentCategoryKey | undefined,
  items: ComponentCategoryItemWithConditions[],
): boolean =>
  getComponentCategoryItems(groupComponentCategoryItems(category, items)).some(
    (i) =>
      getComponentCategoryItemColumnValue(DisplayColumns.Label, i) === rowData[DisplayColumns.Label] &&
      getComponentCategoryItemColumnValue(HelperColumns.Key, i) !== rowData[HelperColumns.Key],
  );

/**
 *  Checks to see if we should show an item's key
 *
 * @param rowData The raw row data
 * @param category The component category
 * @param items Items with label and key properties
 *
 * @returns boolean True if we should show the item's key, false otherwise
 */
export const shouldShowKey = (
  rowData: Record<string, string | number | null | undefined>,
  category: ComponentCategoryKey | undefined,
  items: ComponentCategoryItemWithConditions[],
): boolean =>
  category !== ComponentCategoryKey.Flooring &&
  category !== ComponentCategoryKey.NestingBox &&
  hasDuplicateLabels(rowData, category, items);

export const formatSurchargeCalculation = (
  surchargeCalculation: PricingSurcharge,
  supplierKey: string | undefined,
): PricingSurcharge => {
  let formattedSurchargeCalculation: PricingSurcharge = {
    ...surchargeCalculation,
    label: surchargeCalculation.label || '',
    isSubtotal: surchargeCalculation.categories.includes(PricingSurchargeCategory.Subtotal),
    supplierKey,
  };

  // If the surcharge order calculation is line item
  // Remove isTaxable and affectsDeposit as they are inherited by the line items
  // Set amountChange in calculation to 0 as this cannot be applied to line items
  if (surchargeCalculation.key === PricingSurchargeCalculationType.LineItem) {
    formattedSurchargeCalculation = {
      ...formattedSurchargeCalculation,
      label: '',
      isTaxable: false,
      affectsDeposit: false,
      calculation: { ...formattedSurchargeCalculation.calculation, amountChange: 0 },
      rules: [
        ...formattedSurchargeCalculation.rules.map((rule) => ({
          ...rule,
          calculation: { ...rule.calculation, amountChange: 0 },
        })),
      ],
    };
  }

  return formattedSurchargeCalculation;
};
