/* eslint-disable react/jsx-props-no-spreading */
import { Card, CardContent, Typography, Button, Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';

const useStyles = makeStyles(() => ({
  tooltipCardContainer: {
    maxWidth: '380px',
    borderRadius: '8px',
    background: 'var(--White---BG, #FFF)',
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.08), 0px 24px 38px 3px rgba(0, 0, 0, 0.06), 0px 9px 46px 8px rgba(0, 0, 0, 0.04)',
  },
  tooltipCardContent: {
    padding: '16px 16px 16px 24px !important;',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  tooltipButton: {
    lineHeight: '16px',
    textTransform: 'uppercase',
    height: '36px',
    display: 'flex',
    padding: '0px',
    justifyContent: 'center',
    fontSize: '14px',
    letterSpacing: '.75px',
    fontWeight: 500,
    textAlign: 'center',
    borderRadius: '4px',
    border: '1px solid var(--Primary, #21215A)',
    color: 'var(--Primary, #21215A)',
  },
  tooltipButtonContainer: {
    alignItems: 'flex-end',
    justifyContent: 'right',
  },
}));

type TooltipProps = TooltipRenderProps;

export const JoyrideTooltip: React.FC<TooltipProps> = ({ step, tooltipProps, primaryProps }: TooltipProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card className={classes.tooltipCardContainer} {...tooltipProps}>
      <CardContent className={classes.tooltipCardContent}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 9 }}>
            <Typography variant="body2">{step.content}</Typography>
          </Grid>
          <Grid container className={classes.tooltipButtonContainer} size={{ xs: 3 }}>
            <Button fullWidth className={classes.tooltipButton} variant="outlined" color="primary" {...primaryProps}>
              {primaryProps.title}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
