import { GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-premium';

export enum MUIDataGridColumn {
  Actions = 'actions',
  City = 'city',
  Dealers = 'dealers',
  Site = 'site',
  Email = 'email',
  Name = 'name',
  Permissions = 'permissions',
  Phone = 'phone',
  State = 'state',
  Status = 'status',
  Configurators = 'configurators',
}

export enum MUIDataGrid {
  AllUsers = 'allUsers',
  Dealers = 'dealers',
  Users = 'users',
}

export const muiDataGridColumnWidths: {
  [column: string]: { minWidth?: number; width?: number; maxWidth?: number };
} = {
  [MUIDataGridColumn.Actions]: { width: 50, minWidth: 50, maxWidth: 50 },
  [MUIDataGridColumn.City]: { width: 200 },
  [MUIDataGridColumn.Dealers]: { width: 300 },
  [MUIDataGridColumn.Site]: { width: 78 },
  [MUIDataGridColumn.Email]: { width: 300 },
  [MUIDataGridColumn.Name]: { width: 250 },
  [MUIDataGridColumn.Permissions]: { width: 200 },
  [MUIDataGridColumn.Phone]: { width: 200 },
  [MUIDataGridColumn.State]: { width: 250 },
  [MUIDataGridColumn.Status]: { width: 150 },
  [GRID_TREE_DATA_GROUPING_FIELD]: { width: 50, minWidth: 50, maxWidth: 50 },
};

export const dealersColumns = [
  MUIDataGridColumn.Name,
  MUIDataGridColumn.City,
  MUIDataGridColumn.State,
  MUIDataGridColumn.Phone,
  MUIDataGridColumn.Email,
  MUIDataGridColumn.Site,
  MUIDataGridColumn.Actions,
];

export const usersColumns = [
  MUIDataGridColumn.Name,
  MUIDataGridColumn.Email,
  MUIDataGridColumn.Phone,
  MUIDataGridColumn.Configurators,
  MUIDataGridColumn.Dealers,
  MUIDataGridColumn.Permissions,
  MUIDataGridColumn.Status,
  MUIDataGridColumn.Actions,
];
