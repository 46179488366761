/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stepper, Grid2 as Grid, FormControl, Box, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import { Industry } from '@idearoom/types';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { reduxForm, Form, InjectedFormProps, formValueSelector, Field, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { navigate } from 'hookrouter';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientSignUpFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import {
  useGetDealerSuppliersQuery,
  useSharedAddClientMutation,
  useGetTemplateVendorsQuery,
  useSharedClientUserValidationMutation,
} from '../services/clientApi';
import { ClientSignUpStep } from './ClientSignUpStep';
import { ClientSignUpAutocompleteField } from './redux-form/ClientSignUpAutocompleteField';
import { I18nKeys } from '../constants/I18nKeys';
import { InputField } from './redux-form/InputField';
import { passwordValidation, required } from '../utils/reduxFormUtils';
import { LoadingButton } from './LoadingButton';
import { ClientSupplierPreview } from './ClientSupplierPreview';
import { ClientType } from '../constants/Client';
import { AppRoutes } from '../constants/AppRoutes';
import { ClientSupplierLogoBanner } from './ClientSupplierLogoBanner';
import shedCreationLottie from '../images/shedCreationAnimation.lottie';
import carportCreationLottie from '../images/carportCreationAnimation.lottie';

export interface FormData {
  [ClientSignUpFormFields.SupplierOrTemplate]: { id: string; label: string };
  [ClientSignUpFormFields.Industry]: string;
  [ClientSignUpFormFields.Type]: ClientType;
  [ClientSignUpFormFields.Email]: string;
  [ClientSignUpFormFields.Password]: string;
  [ClientSignUpFormFields.ConfirmPassword]: string;
}

const getAnimationByIndustry = (industry: string) => {
  switch (industry) {
    case Industry.Carports:
      return carportCreationLottie;
    case Industry.Sheds:
      return shedCreationLottie;
    default:
      return shedCreationLottie;
  }
};

interface CustomProps {
  step: number;
  setStep: (step: number) => void;
  onSubmit: { (values: FormData): void };
}

type FormProps = CustomProps & InjectedFormProps<FormData, CustomProps>;

const ClientSignUpFormComponent: React.FC<FormProps> = ({ step, setStep, handleSubmit, onSubmit }: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const industry = useAppSelector((state) =>
    formValueSelector(Forms.ClientSignUp)(state, ClientSignUpFormFields.Industry),
  );
  const industryLabel = industry?.replace('-', ' ');
  const type = useAppSelector((state) => formValueSelector(Forms.ClientSignUp)(state, ClientSignUpFormFields.Type));
  const supplier = useAppSelector((state) =>
    formValueSelector(Forms.ClientSignUp)(state, ClientSignUpFormFields.SupplierOrTemplate),
  );
  const email = useAppSelector((state) => formValueSelector(Forms.ClientSignUp)(state, ClientSignUpFormFields.Email));

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [existingUser, setExistingUser] = React.useState(false);

  const { data: templateVendors, isLoading: isLoadingTemplateVendors } = useGetTemplateVendorsQuery(industry, {
    skip: !industry || type === ClientType.Dealer,
  });
  const { data: suppliers, isLoading: isLoadingSuppliers } = useGetDealerSuppliersQuery(industry, {
    skip: !industry || type === ClientType.Supplier,
  });

  const [validateUser, { isLoading: isValidatingUser }] = useSharedClientUserValidationMutation();
  const [_, { isLoading: isAddingClient }] = useSharedAddClientMutation();

  const singleSupplierIndustry = suppliers?.length === 1;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stepper activeStep={step} orientation="vertical" connector={null}>
        <ClientSignUpStep
          index={0}
          header={t(
            // eslint-disable-next-line no-nested-ternary
            type === ClientType.Supplier
              ? I18nKeys.ClientSignUpStep1HeaderSupplier
              : singleSupplierIndustry
              ? I18nKeys.ClientSignUpStep1HeaderDealerSingleSupplier
              : I18nKeys.ClientSignUpStep1HeaderDealer,
            {
              industry: industryLabel,
            },
          )}
          headerPreview={
            !singleSupplierIndustry &&
            type === ClientType.Dealer && <ClientSupplierLogoBanner supplier={supplier} height="80px" />
          }
          message={
            step === 0
              ? t(
                  // eslint-disable-next-line no-nested-ternary
                  type === ClientType.Supplier
                    ? I18nKeys.ClientSignUpStep1MessageSupplier
                    : singleSupplierIndustry
                    ? I18nKeys.ClientSignUpStep1MessageDealerSingleSupplier
                    : I18nKeys.ClientSignUpStep1MessageDealer,
                  {
                    industry: industryLabel,
                  },
                )
              : supplier?.label
          }
          step={step}
          setStep={setStep}
          singleSupplierIndustry={singleSupplierIndustry}
        >
          <Grid container spacing="16px">
            <Grid
              key={ClientSignUpFormFields.SupplierOrTemplate}
              size={6}
              sx={{ display: `${singleSupplierIndustry ? 'none' : 'block'}` }}
            >
              <FormControl fullWidth>
                <Field
                  name={ClientSignUpFormFields.SupplierOrTemplate}
                  label="Search"
                  variant="outlined"
                  component={ClientSignUpAutocompleteField}
                  options={type === ClientType.Supplier ? templateVendors : suppliers}
                  disabled={isLoadingSuppliers || isLoadingTemplateVendors}
                />
              </FormControl>
            </Grid>
            <ClientSupplierPreview supplier={supplier} step={step} hideLogo={singleSupplierIndustry} />
          </Grid>
          <Box
            // Add style text padding when it isn't being shown to avoid button moving on supplier selection
            mt={
              (type === ClientType.Supplier && !supplier && '20px') || (type === ClientType.Supplier && '0px') || '16px'
            }
          >
            <LoadingButton
              loading={isValidatingUser}
              variant="contained"
              sx={{ backgroundColor: '#3787FF', color: 'white' }}
              disabled={!supplier}
              onClick={async () =>
                validateUser(email)
                  .unwrap()
                  .catch(() => ({ status: undefined }))
                  .then(({ status }) => {
                    setExistingUser(!!status);
                    setStep(1);
                  })
              }
            >
              {t(I18nKeys.ContinueButton)}
            </LoadingButton>
          </Box>
        </ClientSignUpStep>
        {step === 1 && (
          <ClientSignUpStep
            index={1}
            header={t(
              existingUser ? I18nKeys.ClientSignUpStep2HeaderExistingUser : I18nKeys.ClientSignUpStep2HeaderNewUser,
              {
                industry: industryLabel,
              },
            )}
            message={t(
              existingUser ? I18nKeys.ClientSignUpStep2MessageExistingUser : I18nKeys.ClientSignUpStep2MessageNewUser,
              { industry: industryLabel },
            )}
            step={step}
            setStep={setStep}
          >
            <Grid container spacing="16px">
              <Grid container size={12}>
                <Grid key={ClientSignUpFormFields.Email} size={6}>
                  <FormControl fullWidth>
                    <Field
                      name={ClientSignUpFormFields.Email}
                      label="Email"
                      variant="filled"
                      component={InputField}
                      disabled
                      validate={required}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container size={12}>
                <Grid key={ClientSignUpFormFields.Password} size={6}>
                  <FormControl fullWidth>
                    <Field
                      autoComplete="new-password"
                      type={showNewPassword ? 'text' : 'password'}
                      name={ClientSignUpFormFields.Password}
                      label="Password"
                      variant="filled"
                      component={InputField}
                      validate={passwordValidation}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowNewPassword((show) => !show)}
                                onMouseDown={(event) => event.preventDefault()}
                                edge="end"
                                tabIndex={-1}
                              >
                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                    {existingUser && (
                      <FormHelperText
                        style={{ margin: '15px 0px 0px', alignSelf: 'end', cursor: 'pointer', fontSize: '14px' }}
                        onClick={() => navigate(AppRoutes.ForgotPassword)}
                      >
                        {t(I18nKeys.ForgotPasswordLink, 'Forgot your password?')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {!existingUser && (
                  <Grid key={ClientSignUpFormFields.ConfirmPassword} size={6}>
                    <FormControl fullWidth>
                      <Field
                        autoComplete="new-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        name={ClientSignUpFormFields.ConfirmPassword}
                        label="Confirm Password"
                        variant="filled"
                        component={InputField}
                        validate={required}
                        slotProps={{
                          input: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowConfirmPassword((show) => !show)}
                                  onMouseDown={(event) => event.preventDefault()}
                                  edge="end"
                                  tabIndex={-1}
                                >
                                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Box>
                <LoadingButton
                  loading={isAddingClient}
                  variant="contained"
                  sx={{ backgroundColor: '#3787FF', color: 'white' }}
                  onClick={() => {
                    dispatch(submit(Forms.ClientSignUp));
                  }}
                >
                  {t(I18nKeys.CreateSiteButton)}
                </LoadingButton>
              </Box>
            </Grid>
          </ClientSignUpStep>
        )}
        {step === 2 && (
          <ClientSignUpStep
            index={2}
            header={t(
              existingUser ? I18nKeys.ClientSignUpStep2HeaderExistingUser : I18nKeys.ClientSignUpStep2HeaderNewUser,
              {
                industry: industryLabel,
              },
            )}
            step={step}
            setStep={setStep}
            finalStep
          >
            <Box
              sx={{
                minHeight: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                aspectRatio: '1.5 / 1',
                margin: '0 auto',
              }}
            >
              <DotLottieReact src={getAnimationByIndustry(industry)} loop autoplay />
            </Box>
          </ClientSignUpStep>
        )}
      </Stepper>
    </Form>
  );
};

const validate = ({
  [ClientSignUpFormFields.Password]: password,
  [ClientSignUpFormFields.ConfirmPassword]: confirmPassword,
}: FormData): any => {
  if (password && confirmPassword && password !== confirmPassword) {
    return { [ClientSignUpFormFields.ConfirmPassword]: `Password confirmation doesn't match new password` };
  }

  return {};
};

export const ClientSignUpForm = reduxForm<FormData, CustomProps>({
  form: Forms.ClientSignUp,
  validate,
  onSubmitSuccess: (_result, _dispatch, { setStep }) => {
    setStep(2);
  },
})(ClientSignUpFormComponent);
