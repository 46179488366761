import React from 'react';
import { motion } from 'framer-motion';
import { Card, Skeleton } from '@mui/material';

type Props = React.PropsWithChildren<{
  delay: number;
  className?: string;
  onAnimationComplete?: () => void;
  loading: boolean;
}>;

export const FlipCard: React.FC<Props> = ({ delay, children, className, onAnimationComplete, loading }: Props) => (
  <motion.div
    initial={{ rotateY: 180 }}
    animate={{ rotateY: 0 }}
    transition={{ duration: 1, delay }}
    onAnimationComplete={() => onAnimationComplete?.()}
    style={{
      height: '100%',
      width: '100%',
      position: 'relative',
      transformStyle: 'preserve-3d',
    }}
  >
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backfaceVisibility: 'hidden',
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        className={className}
        style={{ borderRadius: '8px', display: loading ? 'block' : 'none' }}
      />
      <Card
        elevation={0}
        className={className}
        sx={{
          height: '100%',
          alignContent: 'center',
          justifyItems: 'center',
          display: loading ? 'none' : 'block',
        }}
      >
        {children}
      </Card>
    </div>
  </motion.div>
);
