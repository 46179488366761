import { Button, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from './Dialog';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { I18nKeys } from '../constants/I18nKeys';
import { Dialogs } from '../constants/Dialogs';
import { useAppDispatch } from '../hooks';
import { sendOtp, verifyOtp } from '../ducks/currentUserSlice';
import { isValidEmail } from '../utils/emailUtils';

export const OneTimePasswordDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [email, setEmail] = React.useState<string>('');
  const [emailError, setEmailError] = React.useState<string>('');
  const [otpSent, setOtpSent] = React.useState<boolean>(false);
  const [otp, setOtp] = React.useState<string>('');

  const handleSendOtp = (): void => {
    setEmailError('');
    if (!otpSent) {
      if (isValidEmail(email)) {
        dispatch(sendOtp(email));
        setOtpSent(true);
      } else {
        setEmailError('Invalid email');
      }
    } else if (otp) {
      dispatch(verifyOtp(otp));
    } else {
      setEmailError('Invalid one time password');
    }
  };

  const closeDialog = (): void => {
    setOtpSent(false);
    setEmail('');
    setEmailError('');
    setOtp('');
    dispatch(closeDialogFunc());
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSendOtp();
    }
  };

  return (
    <Dialog dialogKey={Dialogs.SendOtp} maxWidth="xs" fullWidth>
      <DialogTitle>{t(I18nKeys.SendOtpDialogTitle, 'Send One Time Password')}</DialogTitle>
      <DialogContent>
        {!otpSent && (
          <TextField
            label={t(I18nKeys.FieldEmail, 'Email')}
            autoFocus
            autoComplete="email"
            type="email"
            variant="filled"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
            onKeyDown={handleKeyDown}
          />
        )}
        {otpSent && (
          <>
            <Typography sx={{ marginBottom: 2 }}>
              {t(I18nKeys.SendOtpDialogMessage, 'An email has been sent with a one time password, enter it below')}
            </Typography>
            <TextField
              label={t(I18nKeys.SendOtpDialogOtp, 'One Time Password')}
              autoFocus
              autoComplete="one-time-password"
              type="text"
              variant="filled"
              fullWidth
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {t(I18nKeys.DialogCancelButton, 'Cancel')}
        </Button>
        <Button onClick={handleSendOtp} color="primary">
          {otpSent ? t(I18nKeys.SendOtpDialogSubmitButton, 'Submit') : t(I18nKeys.SendOtpDialogSendButton, 'Send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
