import React, { useLayoutEffect, useState } from 'react';
import JoyRide, { ACTIONS, CallBackProps, ORIGIN, STATUS, Step } from 'react-joyride';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Button, Card, CircularProgress, Grid2 as Grid, LinearProgress, Stack, Typography } from '@mui/material';
import dashedCircle from '../images/dashedCircle.svg';
import progressComplete from '../images/progressComplete.svg';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setTourIsActive } from '../ducks/gettingStartedSlice';
import { JoyrideTooltip } from './JoyrideTooltip';
import { unknownGroup } from '../constants/Group';
import { useCompleteSetupStepMutation, useGetGroupSetupStepsQuery } from '../services/groupApi';
import { getGettingStartedSteps } from '../constants/gettingStartedConfig';
import { setSearchHidden } from '../ducks/search';

const ACCENT_COLOR = '#3787FF';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#F5F5F5',
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  },
  card: {
    marginTop: '0px !important',
    marginBottom: '12px !important',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    padding: '12px 16px',
    background: 'rgba(255, 255, 255, 1)',
    cursor: 'pointer',
  },
  nextActiveCard: {
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.08), 0px 24px 38px 3px rgba(0, 0, 0, 0.06), 0px 9px 46px 8px rgba(0, 0, 0, 0.04)',
  },
  completedCard: {
    opacity: '0.6',
    cursor: 'default',
  },
  cardTitle: {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0px',
  },
  cardDescription: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    marginTop: '4px !important',
  },
  progress: {
    marginTop: theme.spacing(2),
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#E0E0E0',
    '& .MuiLinearProgress-bar': {
      backgroundColor: ACCENT_COLOR,
      borderRadius: '5px',
    },
  },
  stepNotCompletedButton: {
    width: '100%',
    backgroundColor: ACCENT_COLOR,
    color: 'white',
    '&:hover': {
      backgroundColor: ACCENT_COLOR,
    },
  },
  stepCompletedButton: {
    width: '100%',
    border: '1px solid var(--Primary, #21215A)',
    backgroundColor: 'var(--White---BG, #FFF) !important',
    color: 'var(--Primary, #21215A) !important',
  },
}));

export const GettingStarted: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { selectedClientId = '' } = useAppSelector((state) => state?.viewer);

  const { groupId } = useAppSelector((state) => state?.currentUser.group || unknownGroup) || {};
  const { configurators: groupConfigurators } =
    useAppSelector((state) => state.currentUser.group || unknownGroup) || {};
  const isDayOneClient = groupConfigurators?.[0]?.vendorData?.vendor?.maxioSubscriptionId;

  const { data: { steps: gettingStartedSteps = [] } = {}, isLoading: isLoadingSteps } = useGetGroupSetupStepsQuery(
    {
      clientId: selectedClientId,
      groupId,
    },
    { refetchOnMountOrArgChange: true, skip: !selectedClientId || !groupId || !isDayOneClient },
  );

  const { configurators } = useAppSelector((state) => state.currentUser.group || unknownGroup);
  const supplierName = configurators?.[0]?.suppliers?.[0]?.supplierName || '';

  const [completeSetupStep] = useCompleteSetupStepMutation();

  const runTour = useAppSelector((state) => state.gettingStarted.tourIsActive);

  const completedSteps = gettingStartedSteps.filter((step) => step.completed).length;

  const [tourSteps, setTourSteps] = useState<Step[]>([]);

  const getProgressPercentage = (stepsComplete: number, stepCount: number): number => {
    if (!stepsComplete || !stepCount) return 2;
    return (stepsComplete / stepCount) * 100;
  };

  const totalSteps = gettingStartedSteps.length;

  const firstIncompleteStep = gettingStartedSteps.find((step) => step.completed === false);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, step, origin } = data;

    if (action === ACTIONS.CLOSE || status === STATUS.SKIPPED || status === STATUS.FINISHED) {
      // Need to set our running state to false, so we can restart if we click start again.
      dispatch(setTourIsActive(false));
      if (origin === ORIGIN.BUTTON_PRIMARY) {
        if (step.data?.onClick) {
          step.data.onClick();
        }
      }
    }
  };

  const cardProgressComponent = (stepCompleted: boolean, stepIndex: number) => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {stepCompleted ? (
        <img
          alt="progressComplete"
          src={progressComplete}
          style={{ position: 'relative', zIndex: 1, width: '40px', height: '40px', margin: 'auto' }}
        />
      ) : (
        <>
          <img
            alt="dashedCircle"
            src={dashedCircle}
            style={{ position: 'relative', zIndex: 1, width: '40px', height: '40px', margin: 'auto' }}
          />
          <Typography
            variant="body1"
            sx={{
              color: ACCENT_COLOR,
              fontWeight: 'bold',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
            }}
          >
            {stepIndex + 1}
          </Typography>
        </>
      )}
    </>
  );

  useLayoutEffect(() => {
    dispatch(setSearchHidden(true));
  }, [dispatch]);

  return (
    <div id="get-started-root" className={classes.root}>
      {isLoadingSteps && (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <CircularProgress style={{ alignSelf: 'center' }} color="primary" />
        </div>
      )}
      {!isLoadingSteps && (
        <>
          <JoyRide
            hideBackButton
            hideCloseButton
            run={runTour}
            steps={tourSteps}
            tooltipComponent={JoyrideTooltip}
            callback={handleJoyrideCallback}
            locale={{
              close: 'Got it',
            }}
            styles={{
              options: {
                zIndex: 1300,
              },
            }}
          />
          <Stack direction="row" alignItems="flex-start" spacing={6} sx={{ width: '100%' }}>
            <Stack direction="column" spacing={2} sx={{ width: '500px' }}>
              <Stack direction="column" spacing={1}>
                <Typography variant="h6">Let’s get your site up and running.</Typography>
                <LinearProgress
                  className={classes.progress}
                  variant="determinate"
                  value={getProgressPercentage(completedSteps, totalSteps)}
                />
                <Typography variant="subtitle2" sx={{ mt: 2, color: 'text.secondary' }}>
                  {completedSteps} of {totalSteps} steps completed
                </Typography>
              </Stack>
              <Stack direction="column" spacing={2}>
                {gettingStartedSteps.map((step, index) => {
                  const stepConfig = getGettingStartedSteps({
                    groupId,
                    supplierName,
                    dispatch,
                    completeSetupStep,
                  }).find((s) => s.id === step.key);
                  const stepCompleted = gettingStartedSteps.find((s) => s.id === step.id)?.completed || false;
                  return (
                    <Card
                      className={`${classes.card} ${
                        step.id === firstIncompleteStep?.id ? classes.nextActiveCard : ''
                      } ${stepCompleted ? classes.completedCard : ''}`}
                      key={step.id}
                      elevation={4}
                      sx={{ marginTop: '12px', padding: 2 }}
                      onClick={(): void => {
                        if (!stepCompleted) {
                          dispatch(setTourIsActive(true));
                          const tourStepsForButton =
                            getGettingStartedSteps({ groupId, supplierName, dispatch, completeSetupStep }).find(
                              (s) => s.id === step.key,
                            )?.tourSteps || [];
                          setTourSteps(tourStepsForButton);
                        }
                      }}
                    >
                      <Stack direction="row" alignItems="center" spacing={3}>
                        <Grid container spacing={2} sx={{ width: '100%' }}>
                          <Grid size="auto">
                            <Stack direction="row" alignItems="center" sx={{ position: 'relative', height: '100%' }}>
                              <Stack sx={{ position: 'relative' }} alignContent="center" alignItems="center">
                                {cardProgressComponent(stepCompleted, index)}
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid size="grow">
                            <Stack direction="column" alignItems="left" spacing={1}>
                              <Typography variant="body1" sx={{ fontWeight: 'bold' }} className={classes.cardTitle}>
                                {stepConfig?.title}
                              </Typography>
                              <Typography variant="body2" className={classes.cardDescription}>
                                {stepConfig?.description}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid size={3}>
                            <Stack direction="row" alignItems="center" alignContent="center" sx={{ height: '100%' }}>
                              <Button
                                disabled={!!stepCompleted}
                                variant="contained"
                                className={`${
                                  stepCompleted ? classes.stepCompletedButton : classes.stepNotCompletedButton
                                }`}
                              >
                                {index === 0 ? 'View' : 'Go'}
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Card>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </div>
  );
};
