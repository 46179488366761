import { Fab, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect } from 'react';
import { Dialogs } from '../constants/Dialogs';
import { DockedDrawerWidth } from '../constants/DockedDrawerWidth';
import { openDialog } from '../ducks/dialogSlice';
import { fetchGroup, setMemberDialog } from '../ducks/group';
import { GroupMember } from '../types/GroupMember';
import {
  getFilteredMembers,
  getUserRows,
  isIdeaRoomGroup as isIdeaRoomGroupFunc,
  isIdeaRoomUser,
} from '../utils/userUtils';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { GroupMembersTable } from './GroupMembersTable';
import { useAppSelector, useAppDispatch } from '../hooks';
import { unknownGroup } from '../constants/Group';
import { useUserRepo } from '../hooks/useUserRepo';
import { unknownUser } from '../types/User';
import { MUIDataGrid } from '../constants/MUIDataGrid';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    [theme.breakpoints.down('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2) },
    [theme.breakpoints.up('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2 + DockedDrawerWidth / 8) },
  },
  fabText: {
    padding: theme.spacing(1),
  },
}));

export const Users: React.FC<{ groupId?: string }> = ({ groupId }: { groupId?: string }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [filteredGroupMembers, setFilteredGroupMembers] = React.useState<GroupMember[]>([]);

  const { loading, group: { members = [], configurators = [] } = unknownGroup } = useAppSelector(
    (state) => state.group,
  );
  const { user = unknownUser, availableDealers = [] } = useAppSelector((state) => state.currentUser);
  const { searchTerm } = useAppSelector((state) => state.search);
  const { groupFilters = [] } = useAppSelector((state) => state.viewer);

  const { allUsers, isLoadingAllUsers } = useUserRepo({ useAllUsers: true });

  const isIdeaRoomGroup = useAppSelector((state) =>
    isIdeaRoomGroupFunc((state.currentUser.group || unknownGroup).groupId),
  );

  // Effect that filters members by searchTerm anytime either members or searchTerm change
  useEffect(() => {
    const users = getUserRows(
      getFilteredMembers(
        groupId,
        isIdeaRoomGroup
          ? allUsers
          : // Filter out users from the users list to avoid them editing and breaking their permissions (except idearoom users)
            members.filter((member: GroupMember) => isIdeaRoomUser(user) || member.email !== user.email),
        groupFilters,
      ),
    );
    if (searchTerm.length === 0) {
      setFilteredGroupMembers(users);
      return;
    }
    const tests = [
      ({ email }: GroupMember): boolean => stringToLowerIncludes(email, searchTerm),
      ({ name }: GroupMember): boolean => stringToLowerIncludes(name, searchTerm),
      ({ dealers = [] }: GroupMember): boolean =>
        stringToLowerIncludes(
          availableDealers
            .filter((dealer) => dealers.includes(dealer.key))
            .map((dealer) => dealer.name)
            .join(', '),
          searchTerm,
        ),
      ({ permissions = [] }: GroupMember): boolean => stringToLowerIncludes(permissions.join(', '), searchTerm),
    ];

    setFilteredGroupMembers(users.filter((groupMember) => tests.some((test) => test(groupMember))));
  }, [isIdeaRoomGroup, members, groupId, isLoadingAllUsers, user, availableDealers, searchTerm, groupFilters]);

  useEffect(() => {
    if (groupId) {
      dispatch(fetchGroup(groupId));
    }
  }, [groupId]);

  return (
    <>
      <GroupMembersTable
        loading={loading || isLoadingAllUsers}
        members={filteredGroupMembers}
        table={isIdeaRoomGroup ? MUIDataGrid.AllUsers : MUIDataGrid.Users}
        groupConfigurators={configurators}
      />
      {!isIdeaRoomGroup && (
        <Fab
          color="primary"
          className={classes.fab}
          variant="extended"
          onClick={(): void => {
            dispatch(setMemberDialog());
            dispatch(openDialog({ dialog: Dialogs.User }));
          }}
          aria-label="add member"
        >
          <AddIcon />
          <Typography className={classes.fabText}>Add</Typography>
        </Fab>
      )}
    </>
  );
};
