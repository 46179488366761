export enum FilterMenuItems {
  Days45,
  Days90,
  MonthCurrent,
  MonthLast,
  AllTime,
  Custom,
  Days15, // These have to be down here to prevent preferences from being incorrectly set
  Days30,
}
