export enum ToastMessageType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export enum TableFilterType {
  Order = 'order',
  User = 'user',
}

export enum FilterType {
  Dealer = 'Dealer',
  Owner = 'Owner',
  Site = 'Site',
  State = 'State',
  Status = 'Status',
  SubmitStatus = 'SubmitStatus',
  Permission = 'Permission',
}

export const defaultTableFilters = [
  {
    tableFilterType: TableFilterType.Order,
    filters: [],
  },
  {
    tableFilterType: TableFilterType.User,
    filters: [],
  },
];

// Can't use computed values here, use I18nKey values
export enum Columns {
  City = 'table-header-city',
  Created = 'table-header-created',
  Name = 'table-header-name',
  Email = 'table-header-email',
  Phone = 'table-header-phone',
  Reference = 'table-header-reference-number',
  Estimate = 'table-header-estimate',
  Style = 'table-header-building-style',
  Size = 'table-header-building-size',
  SubmitStatus = 'table-header-submit-status',
  Zip = 'table-header-delivery-zip',
  Site = 'table-header-site',
  State = 'table-header-state',
  Dealer = 'table-header-dealer',
  Owner = 'table-header-owner',
  Status = 'table-header-status',
  Design = 'table-header-design',
  SmartBuild = 'table-header-smartbuild',
}

const basicColumns = [Columns.Created, Columns.Name, Columns.Email, Columns.Phone];

export const unknownUserDefaultColumns = [...basicColumns, Columns.Status, Columns.Design];

export const defaultAvailableColumns = [...basicColumns, Columns.Site, Columns.Dealer, Columns.Status, Columns.Design];

export const allAvailableColumns = [
  ...basicColumns,
  Columns.City,
  Columns.State,
  Columns.Zip,
  Columns.Style,
  Columns.Size,
  Columns.Estimate,
  Columns.Reference,
  Columns.Site,
  Columns.Owner,
  Columns.Dealer,
  Columns.Status,
  Columns.SubmitStatus,
  Columns.Design,
];

export const defaultVisibleColumns = allAvailableColumns;

export const enum MenuStatus {
  Collapsed = 'collapsed',
  Expanded = 'expanded',
  Closed = 'closed',
}

export const MenuWidthMap = {
  [MenuStatus.Collapsed]: 52,
  [MenuStatus.Expanded]: 260,
  [MenuStatus.Closed]: 0,
};

export const logoBarHeight = 86;
export const mobileLogoBarHeight = 48;
export const clientDataTopBarHeight = 64;
export const breadcrumbsHeight = 42;
export const publishBarHeight = 64;
