import { Box, Card, CardContent, CardHeader, Fab, Hidden, Typography, MenuItem } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import AutoSizer, { Size } from 'react-virtualized/dist/commonjs/AutoSizer';
import { GridColDef, GridEventListener, GridRowParams, useGridApiRef } from '@mui/x-data-grid-premium';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { DockedDrawerWidth } from '../constants/DockedDrawerWidth';
import { openDialog } from '../ducks/dialogSlice';
import { Dealer } from '../types/Dealer';
import { SortableProperty } from '../types/SortableProperty';
import { SortProperty } from '../types/SortProperty';
import { formatPhoneNumber } from '../utils/phoneNumberUtils';
import { sortRows } from '../utils/sortUtils';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { DealerDialog } from './DealerDialog';
import { NewWindowLink } from './NewWindowLink';
import { TableControls } from './TableControls';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ConfiguratorAppBar } from './ConfiguratorAppBar';
import { unknownGroup } from '../constants/Group';
import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { MuiDataGrid } from './MUIDataGrid';
import { dealersColumns, MUIDataGrid, MUIDataGridColumn } from '../constants/MUIDataGrid';
import { getActionsMenuProps, getDefaultColumnDefinition } from '../utils/MUIDataGridUtils';
import { ActionsMenu } from './ActionsMenu';
import { setSecondaryActions } from '../ducks/secondaryActions';
import { fetchDealers, setDealerDialog } from '../ducks/dealersSlice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#F5F5F5',
    height: '100%',
    width: '100%',
  },
  backdrop: {
    zIndex: 1300, // 1300 is the zIndex of a modal in Material UI
  },
  fab: {
    position: 'fixed',
    [theme.breakpoints.down('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2) },
    [theme.breakpoints.up('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2 + DockedDrawerWidth / 8) },
  },
  fabText: {
    padding: theme.spacing(1),
  },
  dealerCards: {
    height: '100%',
  },
  dealerCard: {
    height: 138,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dealerCardHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: '0',
  },
  dealerCardHeaderContent: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  dealerCardHeaderTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dealerCardContent: {
    paddingTop: '0',
  },
  dealerCardContentLine: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export const Dealers: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();

  const { configurators } = useAppSelector((state) => state.currentUser.group || unknownGroup);
  const { dealers, loading, logoUrl } = useAppSelector((state) => state.dealers);
  const searchTerm = useAppSelector((state) => state.search.searchTerm);
  const selectedClientId = useAppSelector((state) => state.viewer.selectedClientId || '');

  const [filteredDealers, setFilteredDealers] = React.useState<Dealer[]>(dealers);
  const [sortState, setSortState] = React.useState<SortProperty[]>([]);

  useEffect(() => {
    // don't bother searching until there's at least 2 characters
    if (searchTerm.length < 2) {
      setFilteredDealers(dealers);
      return;
    }
    const tests = [
      (dealer: Dealer): boolean => stringToLowerIncludes(dealer.name, searchTerm),
      (dealer: Dealer): boolean => stringToLowerIncludes(dealer.key, searchTerm),
      (dealer: Dealer): boolean => stringToLowerIncludes(dealer.city, searchTerm),
      (dealer: Dealer): boolean => stringToLowerIncludes(dealer.state, searchTerm),
      (dealer: Dealer): boolean => stringToLowerIncludes(formatPhoneNumber(dealer.phoneNumber), searchTerm),
      (dealer: Dealer): boolean => stringToLowerIncludes(dealer.phoneNumber, searchTerm),
      (dealer: Dealer): boolean => stringToLowerIncludes(dealer.emailAddress, searchTerm),
    ];

    setFilteredDealers(dealers.filter((dealer: Dealer) => tests.some((test) => test(dealer))));
  }, [dealers, filteredDealers.length, selectedClientId, searchTerm]);

  const handleExport = useCallback(() => {
    apiRef?.current?.exportDataAsCsv({ fileName: t(I18nKeys.DealerExportCSVTitle) });
  }, [apiRef]);

  useLayoutEffect(() => {
    dispatch(
      setSecondaryActions([
        <MenuItem key="export" onClick={handleExport}>
          {t(I18nKeys.ExportButton)}
        </MenuItem>,
      ]),
    );
  }, [dispatch]);

  const handleSortChanged = (sortProperties: SortProperty[]): void => {
    setSortState(sortProperties);
    sortRows(filteredDealers, sortProperties);
  };

  useEffect(() => {
    if (selectedClientId) {
      dispatch(fetchDealers(selectedClientId));
    }
  }, [selectedClientId]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const DealerCard = ({ index, style }: ListChildComponentProps): JSX.Element => {
    const dealer = filteredDealers[index];
    return (
      <div style={style}>
        <Card
          className={classes.dealerCard}
          key={dealer.key}
          onClick={(): void => {
            dispatch(setDealerDialog({ dialogDealer: dealer, dialogClientId: selectedClientId }));
            dispatch(openDialog({ dialog: Dialogs.Dealer }));
          }}
        >
          <CardHeader
            classes={{
              root: classes.dealerCardHeader,
              content: classes.dealerCardHeaderContent,
              title: classes.dealerCardHeaderTitle,
            }}
            className={classes.dealerCardHeader}
            action={<NewWindowLink link={dealer.dealerURL} />}
            title={dealer.name}
          />
          <CardContent className={classes.dealerCardContent} style={{ paddingBottom: '8px' }}>
            <Box className={classes.dealerCardContentLine}>
              <Box>{`${dealer.city}, ${dealer.state}`}</Box>
            </Box>
            <Box className={classes.dealerCardContentLine}>{dealer.emailAddress}</Box>
            <Box className={classes.dealerCardContentLine}>
              <Box>{formatPhoneNumber(dealer.phoneNumber)}</Box>
              <Box>
                <ActionsMenu
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getActionsMenuProps(
                    { table: MUIDataGrid.Dealers, clientId: selectedClientId, editable: false },
                    dealer,
                    dispatch,
                    t,
                  )}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </div>
    );
  };

  const sortableProperties: SortableProperty[] = [
    { i18nKey: I18nKeys.TableHeaderName, property: 'name' },
    { i18nKey: I18nKeys.TableHeaderCity, property: 'city' },
    { i18nKey: I18nKeys.TableHeaderState, property: 'state' },
    { i18nKey: I18nKeys.TableHeaderPhone, property: 'phoneNumber' },
    { i18nKey: I18nKeys.TableHeaderContactEmail, property: 'emailAddress' },
  ];

  const columns: GridColDef[] = useMemo(
    () =>
      dealersColumns.map((column: MUIDataGridColumn) =>
        getDefaultColumnDefinition(
          { table: MUIDataGrid.Dealers, clientId: selectedClientId, editable: false },
          column,
          classes,
          dispatch,
          t,
        ),
      ),
    [t, dispatch, selectedClientId],
  );

  const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
    (params: GridRowParams<any>) => {
      const { row: dealer } = params;
      dispatch(setDealerDialog({ dialogDealer: dealer, dialogClientId: selectedClientId }));
      dispatch(openDialog({ dialog: Dialogs.Dealer }));
    },
    [selectedClientId, dispatch],
  );

  return (
    <div className={classes.root}>
      <DealerDialog />
      <ConfiguratorAppBar
        enabledOnProperty={ConfiguratorEnabledOnProps.DealerNetworkEnabled}
        isVendorProperty
        configurators={configurators}
      />
      <Hidden smDown>
        <MuiDataGrid
          loading={loading}
          rows={filteredDealers}
          columns={columns}
          apiRef={apiRef}
          getRowId={({ id }) => id}
          autoHeight
          onRowClick={onRowClick}
        />
      </Hidden>
      <Hidden smUp>
        <Box style={{ flexDirection: 'column', height: '100%' }}>
          <div>
            <TableControls
              sortProperties={sortState}
              sortableProperties={sortableProperties}
              onSort={handleSortChanged}
            />
          </div>
          <div className={classes.dealerCards} style={{ flex: '1 1 auto' }}>
            <AutoSizer>
              {({ height, width }: Size): JSX.Element => (
                <FixedSizeList
                  style={{ paddingBottom: '70px' }}
                  height={height}
                  width={width}
                  itemCount={filteredDealers.length}
                  itemSize={154}
                >
                  {DealerCard}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </Box>
      </Hidden>
      {!loading && (
        <Fab
          color="primary"
          className={classes.fab}
          variant="extended"
          onClick={(): void => {
            dispatch(setDealerDialog({ dialogDealer: undefined, dialogClientId: selectedClientId, logoUrl }));
            dispatch(openDialog({ dialog: Dialogs.Dealer }));
          }}
          aria-label="add dealer"
        >
          <AddIcon />
          <Typography className={classes.fabText}>{t(I18nKeys.AddButton)}</Typography>
        </Fab>
      )}
    </div>
  );
};
