/* eslint-disable no-param-reassign */
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { CurrentUserState } from '../types/CurrentUserState';
import { AuthStatus } from '../constants/AuthStatus';
import { Dealer } from '../types/Dealer';
import { Group, GroupData } from '../types/Group';
import { User } from '../types/User';
import { defaultGroupSettings } from '../constants/Group';
import { Configurator } from '../types/Configurator';
import { GroupMember } from '../types/GroupMember';
import { UserPreference } from '../constants/User';

export const initialState: CurrentUserState = {
  authStatus: AuthStatus.SignedOut,
  group: undefined,
  loading: true,
  user: undefined,
  impersonation: {},
  errors: {},
  signingIn: false,
};

export const currentUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUserData(
      state,
      action: PayloadAction<{ user: User; group: Group; groups: GroupData[]; availableDealers: Dealer[] }>,
    ) {
      const { user, groups, group, availableDealers } = action.payload;
      state.authStatus = AuthStatus.SignedIn;
      state.loading = false;
      state.user = user;
      state.groups = groups;
      state.group = {
        ...group,
        settings: {
          ...defaultGroupSettings,
          ...group.settings,
        },
      };
      state.availableDealers = availableDealers;
    },
    setSigningIn(state, action: PayloadAction<boolean>) {
      state.signingIn = action.payload;
    },
    setCurrentUserAuthStatus(state, action: PayloadAction<AuthStatus>) {
      state.authStatus = action.payload;
    },
    signOutAction(state) {
      state.authStatus = initialState.authStatus;
      state.group = initialState.group;
      state.loading = initialState.loading;
      state.user = initialState.user;
      state.impersonation = initialState.impersonation;
      state.errors = initialState.errors;
      state.signingIn = initialState.signingIn;
    },
    updateCurrentUser(state, action: PayloadAction<User | undefined>) {
      state.user = action.payload;
    },
    changeGroup(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    setAvailableDealers(state, action: PayloadAction<Dealer[] | undefined>) {
      state.availableDealers = action.payload;
    },
    setErrors(state, action: PayloadAction<any>) {
      state.errors = action.payload;
    },
    clearErrors(state) {
      state.errors = {};
    },
    setGroupConfig(state, action: PayloadAction<Configurator>) {
      const config = action.payload;
      const { group = {} } = state;
      const { configurators = [] } = group as Group;
      const index = configurators.findIndex((c) => c.clientId === config.clientId);
      const updatedConfigs = [...configurators];
      if (index !== -1) {
        updatedConfigs[index] = config;
      }
      state.group = {
        ...(state.group as Group),
        configurators: updatedConfigs,
      };
    },
    initiateImpersonation(state, action: PayloadAction<GroupMember>) {
      state.impersonation = {
        ...state.impersonation,
        user: action.payload,
        loading: false,
      };
    },
    validateImpersonation(state) {
      state.impersonation = {
        ...state.impersonation,
        loading: true,
      };
    },
    impersonationSuccess(state) {
      state.impersonation = initialState.impersonation;
    },
    impersonationFailed(state) {
      state.impersonation = initialState.impersonation;
    },
    setUserPreferences(state, action: PayloadAction<any>) {
      state.preferences = action.payload;
    },
    signedIn(state) {
      state.loading = true;
    },
  },
});

export const {
  setCurrentUserData,
  setSigningIn,
  setCurrentUserAuthStatus,
  signOutAction,
  updateCurrentUser,
  changeGroup,
  setAvailableDealers,
  setErrors,
  clearErrors,
  setGroupConfig,
  initiateImpersonation,
  validateImpersonation,
  impersonationSuccess,
  impersonationFailed,
  setUserPreferences,
  signedIn,
} = currentUserSlice.actions;

export const sendForgotPasswordCode = createAction('user/sendForgotPasswordCode');

export const signedInSuccess = createAction<{ username: string; groupId: string }>('user/signedInSuccess');

export const signedInFailed = createAction('user/signedInFailed');

export const sendOtp = createAction<string>('user/sendOtp');

export const verifyOtp = createAction<string>('user/verifyOtp');

export const fetchCurrentUserData = createAction('user/fetchCurrentUserData');

export const fetchUserPreferences = createAction('user/fetchUserPreferences');

export const fetchUserPreferencesComplete = createAction('user/fetchUserPreferencesComplete');

export const saveUserPreferences = createAction<{ userPreference: UserPreference; preferences: any }>(
  'user/saveUserPreferences',
);

export const saveUserPreferencesComplete = createAction('user/saveUserPreferencesComplete');

export const recordUserEvent = createAction<{
  groupId: string;
  clientId: string | undefined;
  eventCategory: string | undefined;
  eventType: string;
}>('user/recordUserEvent');
