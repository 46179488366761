import { PricingSheetDimensions } from '../types/Pricing';
import { User } from '../types/User';
import { isCarportView } from '../utils/clientIdUtils';
import { isIdeaRoomUser } from '../utils/userUtils';
import { I18nKeys } from './I18nKeys';

export enum PricingTab {
  Base = 'base',
  Component = 'component',
  SizeBased = 'size-based',
  Surcharge = 'surcharge',
}

export enum PricingSheetDimension {
  Width = 'width',
  Length = 'length',
  Height = 'height',
  Region = 'region',
  PanelWidth = 'panelWidth',
  None = 'none',
}

export const currencySymbols = ['$', '€', '£', '¥', '₣', 'CA'];

export enum DefaultPricingColumnFieldNames {
  RowSpanLabel = 'rowSpanLabel',
}

export const pricingBaseGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Width,
  y: PricingSheetDimension.Length,
};

export const pricingBaseListView: PricingSheetDimensions = {
  x: PricingSheetDimension.Region,
  y: PricingSheetDimension.Length,
};

export const pricingSizeBasedLengthGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Length,
  y: PricingSheetDimension.Height,
};

export const pricingSizeBasedWidthGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Width,
  y: PricingSheetDimension.Height,
};

export const pricingSizeBasedPanelWidthGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Length,
  y: PricingSheetDimension.PanelWidth,
};

export const pricingSizeBasedWidthOnlyGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Width,
  y: PricingSheetDimension.None,
};

export const pricingTabs: {
  i18nKey: I18nKeys;
  value: PricingTab;
  route: PricingTab;
  id: string;
  enabled: ({
    user,
    clientId,
    pricingEnableClientManaged,
    isDealer,
  }: {
    user: User | undefined;
    clientId: string;
    pricingEnableClientManaged: boolean;
    isDealer: boolean;
  }) => boolean;
}[] = [
  {
    i18nKey: I18nKeys.Base,
    value: PricingTab.Base,
    route: PricingTab.Base,
    id: 'base-tab',
    enabled: ({ user, pricingEnableClientManaged, isDealer }) =>
      (isIdeaRoomUser(user) || !!pricingEnableClientManaged) && !isDealer,
  },
  {
    i18nKey: I18nKeys.SizeBasedTab,
    value: PricingTab.SizeBased,
    route: PricingTab.SizeBased,
    id: 'size-based-tab',
    enabled: ({ clientId, isDealer }) => isCarportView(clientId) && !isDealer,
  },
  {
    i18nKey: I18nKeys.ComponentTab,
    value: PricingTab.Component,
    route: PricingTab.Component,
    id: 'component-tab',
    enabled: ({ isDealer }) => !isDealer,
  },
  {
    i18nKey: I18nKeys.Adjustments,
    value: PricingTab.Surcharge,
    route: PricingTab.Surcharge,
    id: 'surcharge-tab',
    enabled: () => true,
  },
];
