/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GettingStartedState } from '../types/GettingStartedState';
import { GettingStartedSteps } from '../constants/gettingStartedConfig';

export const initialState: GettingStartedState = {
  tourIsActive: false,
  runSitesTour: false,
  sitesTourKey: GettingStartedSteps.SET_SITE_LOGO,
  runLeadsTour: false,
};

export const gettingStartedSlice = createSlice({
  name: 'gettingStarted',
  initialState,
  reducers: {
    setTourIsActive(state, action: PayloadAction<boolean>) {
      state.tourIsActive = action.payload;
    },
    setRunSitesTour(state, action: PayloadAction<boolean>) {
      state.runSitesTour = action.payload;
    },
    setSitesTourKey(state, action: PayloadAction<GettingStartedSteps>) {
      state.sitesTourKey = action.payload;
    },
    setRunLeadsTour(state, action: PayloadAction<boolean>) {
      state.runLeadsTour = action.payload;
    },
  },
});

export const { setTourIsActive, setRunSitesTour, setSitesTourKey, setRunLeadsTour } = gettingStartedSlice.actions;
