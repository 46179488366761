export const defaultAllowedHTMLTags = [
  '#comment',
  'a',
  'abbr',
  'acronym',
  'b',
  'blockquote',
  'br',
  'code',
  'div',
  'em',
  'i',
  'img',
  'li',
  'ol',
  'p',
  'span',
  'strong',
  'table',
  'td',
  'tr',
  'ul',
];

export const defaultAllowedAttributes = [
  'href',
  'src',
  'title',
  'alt',
  'class',
  'style',
  'colspan',
  'rowspan',
  'border',
  'cellpadding',
  'cellspacing',
  'align',
  'valign',
  'width',
  'height',
  'bgcolor',
  'background',
  'bordercolor',
  'bordercolorlight',
  'bordercolordark',
  'summary',
  'target',
];
