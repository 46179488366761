/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SignUpState } from '../types/SignUpState';

export const initialState: SignUpState = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  loading: false,
  subscriptionId: undefined,
};

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setSignUpState(state, action: PayloadAction<SignUpState>) {
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    setSubscriptionId(state, action: PayloadAction<string>) {
      state.subscriptionId = action.payload;
    },
  },
});

export const { setSignUpState, setSubscriptionId } = signUpSlice.actions;
