import { GridSize, SortDirection } from '@mui/material';
import { ColumnState, ICellEditor } from 'ag-grid-community';
import { UILayoutVersion } from '@idearoom/types';
import {
  ClientDataEditor,
  ClientDataUserPreferencesProps,
  ColumnDataType,
  ConflictSolution,
  DoltDiffType,
  MergeStatus,
} from '../constants/ClientData';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { TableData } from './DataGrid';
import {
  FloorplanComponentStyle,
  FloorplanGridStyle,
  FloorplanLabelingMethod,
  FloorplanMeasurementArrowheadStyle,
  PricingVisibility,
  LeadsPopupDelay,
  FloorplanFrontDirection,
  SitesMessageType,
} from '../constants/VendorData';
import { I18nKeys } from '../constants/I18nKeys';
import { getCellMetadataFormulasDiffMap } from '../utils/clientDataUtils';
import { ClientDataType } from '../constants/ClientDataType';

export interface ClientDataUserPreferences {
  [ClientDataUserPreferencesProps.UserTableMetadata]: TableMetadata;
}

export interface ClientTable {
  tableName: string;
  formattedTableName: string;
  label: string;
}

export type ColumnPinned = 'left' | 'right' | boolean | null | undefined;

export type ColumnSort = SortDirection | undefined;

export interface ColumnMetadata {
  label?: string;
  hide?: boolean;
  editable?: boolean;
  sheds?: boolean;
  carports?: boolean;
  order?: number;
  searchable?: boolean;
  sort?: ColumnSort;
  sortIndex?: number | undefined;
  pinned?: ColumnPinned;
  width?: number;
  required?: boolean;
  defaultValue?: string;
  note?: string;
  dataType?: ColumnDataType;
  searchDefault?: boolean;
  lookupTables?: { table: string; datasetType: ClientDataType; column?: string }[];
  enumType?: string;
  multiple?: boolean;
  lenient?: boolean;
}

export interface TableColumnMetadata {
  [column: string]: ColumnMetadata;
}

export interface TableMetadata {
  tableName: string;
  formattedTableName: string;
  sheds?: boolean;
  carports?: boolean;
  metadata: TableColumnMetadata;
}

export interface Metadata {
  note?: string;
  formula?: string;
}

export interface CellMetadataMetadata {
  [key: string]: Metadata;
}

export interface CellMetadata {
  clientId: string;
  table: string;
  rowId: string;
  metadata: CellMetadataMetadata;
}

export interface ClientDataUpdate {
  [tableName: string]: TableData[];
}

export interface ClientDataBranchMetadata {
  name: string;
  head: string;
  branchType: ClientDataBranch;
  authors?: string[];
  changedTables?: string[];
  description?: string;
}
export interface ClientDataTableRowDiff {
  [ClientDataFixedColumns.RowId]: string;
  diffType: DoltDiffType;
  [fromToColumns: string]: any;
}

export interface ClientDataTablesDataQueryParam {
  dataType: ClientDataType;
  branch: ClientDataBranch;
  groupId: string;
  clientId: string;
  tableNames: string[];
  columns: string[];
}

export interface ClientDataTableChanges {
  table: string;
  changes: {
    [ClientDataFixedColumns.RowId]: string;
    diffType: DoltDiffType;
    order: number;
    authors: string[];
    date: string;
    key?: string | null;
    column?: string;
    columnId?: string;
    from?: string;
    to?: string;
  }[];
}

export interface ClientDataBranchLogCommit {
  commit_hash: string;
  committer: string;
  date: string;
  email: string;
  message: string;
}

export type KeyValue = {
  key: string;
  value: string;
  keyboardShortcut?: string;
};

export interface ConstraintViolation {
  violationId: number;
  violatedUniqueKey: string;
  rows: any[];
}

export interface TableConstraintViolation {
  table: string;
  violations: ConstraintViolation[];
}

export interface MergeConflict {
  table: string;
  conflicts: {
    [ClientDataFixedColumns.RowId]: string;
    conflictId: string;
    ourDiffType: DoltDiffType;
    theirDiffType: DoltDiffType;
    [columnName: string]: any;
  }[];
}

export interface MergeResult {
  branch: ClientDataBranch;
  status: MergeStatus;
  conflicts?: MergeConflict[];
  constraintViolations?: TableConstraintViolation[];
  error?: string;
}

export interface PublishMergeResult {
  data: PublishingResult | undefined;
  error: any | undefined;
  isSuccess: boolean;
}

export interface PublishingResult {
  mainMerge: MergeResult;
  additionalBranchMerges?: MergeResult[];
}

interface ConflictResolutionItem {
  conflictId: string;
  rowSolution?: ConflictSolution;
  [columnName: string]: ConflictSolution | string | undefined;
}

export interface ConflictResolution {
  table: string;
  solution: ConflictResolutionItem[];
}

interface ConstraintResolutionItem {
  violationId: number;
  rowId: string;
}

export interface ConstraintResolution {
  table: string;
  solution: ConstraintResolutionItem[];
}

export interface SearchResultPart {
  text: string;
  highlight: boolean;
}

export interface ClipboardData {
  value: string;
  formula?: string;
}

export interface SiteDetail {
  name?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phoneNumber?: string;
  companyFax?: string;
  homeLinkUrl?: string;
  emailAddress?: string;
  quoteEmailFromAddress?: string;
  quoteEmailFromAddressSame?: boolean;
  quoteEmailCopyAddress?: string;
  quoteEmailCopyAddressSame?: boolean;
  checkoutEmailFormat?: string;
  quoteEmailFormat?: string;
  saveEmailFormat?: string;
  printEmailFormat?: string;
  sendEmailsTo?: string;
  logoUrl?: string;
  showLogoOnMobile?: boolean;
  emailLogoUrl?: string;
  watermark?: string;
  watermarkText?: string;
  watermarkPosition?: string;
  showLogoWatermarkUnderEstimate?: boolean;
  contactBarColor?: string;
  contactBarTextColor?: string;
  selectedTextColor?: string;
  logoBarColor?: string;
  logoBarTextColor?: string;
  yourEstimateTabColor?: string;
  yourEstimateTextColor?: string;
  supplierKey?: string;
  showInformationalPanel?: boolean;
  informationPanelTitle?: string;
  informationPanelSubtitle?: string;
  informationPanelMessage?: string;
  promoBannerType?: SitesMessageType;
  desktopPromo1?: string;
  desktopPromo2?: string;
  mobilePromo1?: string;
  mobilePromo2?: string;
  deliveryMessageType?: SitesMessageType;
  deliveryMessage?: string;
  installationMessageType?: SitesMessageType;
  installationMessage?: string;
  enableClientManagedLeadsForm?: boolean;
  leadsEnableEmailPopup?: boolean;
  leadsRequireForDesign?: boolean;
  leadsPopupDelay?: LeadsPopupDelay;
  leadsPopupTitle?: string;
  leadsPopupMessage?: string;
  leadsPopupCallToActionText?: string;
  leadsPopupCollectEmail?: boolean;
  leadsPopupCollectPhone?: boolean;
  leadsPopupCollectName?: boolean;
  leadsPopupCollectZipCode?: boolean;
  pricingVisibility?: PricingVisibility;
  pricingVisibilityStaging?: PricingVisibility;
  priceRangePercentage?: number;
  pricingEnableClientManaged?: boolean;
  pricingEnableClientUpdates?: boolean;
  disableSharing?: boolean;
  disableAR?: boolean;
  enableDesignAutoSave?: boolean;
  hideCenterComponentsButton?: boolean;
  layout?: UILayoutVersion;
  captureInfoWithCapSave?: boolean;
  capSaveRequirePhone?: boolean;
  defaultSceneEnvironment?: string;
  disableSceneEnvironmentChange?: boolean;
  emailFloorPlan?: boolean;
  emailFloorplanImageScale?: number;
  useFloorPlanGridDimensions?: boolean;
  useFloorPlanGridDimensionsLegend?: boolean;
  useFloorplanFullPage?: boolean;
  showBetaButtonWhen?: string;
  floorplanLabelingMethod?: FloorplanLabelingMethod;
  floorplanGridStyle?: FloorplanGridStyle;
  floorplanMeasurementArrowheadStyle?: FloorplanMeasurementArrowheadStyle;
  floorplanWallThickness?: number;
  floorplanTextSize?: number;
  floorplanHeight?: number;
  floorplanWidth?: number;
  floorplanShowSideTexts?: boolean;
  floorplanShowFlowerbox?: boolean;
  floorplanShowPorchBoardDirection?: boolean;
  floorplanComponentStyle?: FloorplanComponentStyle;
  floorplanFrontDirection?: FloorplanFrontDirection;
  authenticationProvider?: string;
  salesViewGroupId?: string;
  supplierDatasetOptOut?: boolean;
}

export interface SiteDetailField {
  i18nKey: I18nKeys;
  disabled?: (data: SiteDetail, isIdeaRoomUser: boolean, selectedGroupId: string) => boolean;
  hidden?: (data: SiteDetail, isIdeaRoomUser: boolean, selectedGroupId: string) => boolean;
  ideaRoomOnly?: (data: SiteDetail, isIdeaRoomUser: boolean, selectedGroupId: string) => boolean;
  props?: {
    defaultValue?: any;
    sm?: GridSize;
    xs?: GridSize;
    options?: any[];
  };
  checkbox?: boolean;
  radio?: boolean;
  switch?: boolean;
}

export interface ColumnChangedParams {
  tablePreferences: {
    [column: string]: ColumnState & { order: number };
  };
}

export type CellMetadataFormulasDiffMap = ReturnType<typeof getCellMetadataFormulasDiffMap>;

export type CustomCellEditorInstance = ICellEditor<any> & {
  getEditorType?: () => ClientDataEditor;
  getValue?: () => string;
  insertValue?: (val: string) => void;
  getSelection?: () => { start: number; end: number };
  setSelection?: ({ start, end }: { start: number; end: number }) => void;
};

export enum AsyncTaskStatus {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
}

export type AsyncTask = {
  asyncTaskStatus: AsyncTaskStatus;
};

export type AsyncTaskFailure = {
  asyncTaskStatus: AsyncTaskStatus.Failed;
  message: string;
};
