import {
  Grid2 as Grid,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UILayoutVersion } from '@idearoom/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SiteDetailField, SiteDetail } from '../types/ClientData';
import { ColorOption } from './ColorOption';
import { colorsFieldsMap, sitesFieldsMap, SiteDetailDataFields } from '../constants/VendorData';
import { SceneEnvironmentSelector } from './SceneEnvironmentSelector';
import { ideaRoomOnlySiteDetailField, hideSiteDetailField, disabledSiteDetailField } from '../utils/vendorDataUtils';
import { SiteInputLabel } from './SiteInputLabel';
import { useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { SiteDetailChange } from '../constants/ClientData';

const useStyles = makeStyles(() => ({
  inputLabel: {
    marginTop: '16px',
  },
  filledSelect: {
    fontSize: 16,
    height: '55px',
  },
}));

interface Props {
  data: SiteDetail;
  clientId: string;
  onChange: (changes: SiteDetailChange[]) => void;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

export const SitesColorsAndScene: React.FC<Props> = ({
  data = {},
  clientId,
  onChange,
  isIdeaRoomUser = false,
  selectedGroupId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { errors = [] } = useAppSelector((state: AppState) => state?.vendorData);

  const hideLayoutControl = hideSiteDetailField(SiteDetailDataFields.Layout, data, isIdeaRoomUser, selectedGroupId);
  const hideInfoCapture = hideSiteDetailField(
    SiteDetailDataFields.CaptureInfoWithCapSave,
    data,
    isIdeaRoomUser,
    selectedGroupId,
  );
  const hideCapSaveRequirePhone = hideSiteDetailField(
    SiteDetailDataFields.CapSaveRequirePhone,
    data,
    isIdeaRoomUser,
    selectedGroupId,
  );
  return (
    <Grid container id="sites-colors-and-scene-container">
      <Grid size={{ xs: 12, md: 6 }}>
        <Grid size={{ xs: 12, md: 12 }} style={!hideLayoutControl || !hideInfoCapture ? { padding: '8px' } : {}}>
          {!hideLayoutControl && (
            <FormControl fullWidth>
              <SiteInputLabel
                shrink
                className={classes.inputLabel}
                htmlFor="production-layout-select"
                i18nKey={sitesFieldsMap[SiteDetailDataFields.Layout]?.i18nKey}
                ideaRoomOnly={ideaRoomOnlySiteDetailField(
                  SiteDetailDataFields.Layout,
                  data,
                  isIdeaRoomUser,
                  selectedGroupId,
                )}
              />
              <Select
                value={data[SiteDetailDataFields.Layout] || UILayoutVersion.Modern}
                onChange={(e: SelectChangeEvent<string>): void =>
                  onChange([{ column: e.target.name, value: e.target.value }])
                }
                inputProps={{
                  displayEmpty: false,
                  name: SiteDetailDataFields.Layout,
                  id: 'production-layout-select',
                }}
                className={classes.filledSelect}
                variant="filled"
                disabled={disabledSiteDetailField(SiteDetailDataFields.Layout, data, isIdeaRoomUser, selectedGroupId)}
              >
                {Object.values(UILayoutVersion).map((layout) => (
                  <MenuItem key={layout} value={layout}>
                    {t(`sites-layout-${layout}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {!hideInfoCapture && (
            <FormControl fullWidth style={{ marginTop: '16px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={SiteDetailDataFields.CaptureInfoWithCapSave}
                    checked={data.captureInfoWithCapSave === undefined ? true : !!data.captureInfoWithCapSave}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      onChange([{ column: e.target.name, value: e.target.checked }])
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'capture info on save' }}
                    disabled={disabledSiteDetailField(
                      SiteDetailDataFields.CaptureInfoWithCapSave,
                      data,
                      isIdeaRoomUser,
                      selectedGroupId,
                    )}
                  />
                }
                label={t(sitesFieldsMap[SiteDetailDataFields.CaptureInfoWithCapSave]?.i18nKey || '')}
              />
            </FormControl>
          )}
          {!hideInfoCapture && !hideCapSaveRequirePhone && (
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    name={SiteDetailDataFields.CapSaveRequirePhone}
                    checked={!!data.capSaveRequirePhone || false}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      onChange([{ column: e.target.name, value: e.target.checked }])
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'require phone when capturing info' }}
                    disabled={disabledSiteDetailField(
                      SiteDetailDataFields.CapSaveRequirePhone,
                      data,
                      isIdeaRoomUser,
                      selectedGroupId,
                    )}
                  />
                }
                label={t(sitesFieldsMap[SiteDetailDataFields.CapSaveRequirePhone]?.i18nKey || '')}
              />
            </FormControl>
          )}
        </Grid>
        {Object.entries(colorsFieldsMap)
          .filter(([key]) => !hideSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId))
          .map(([key, field]) => {
            const { i18nKey } = field as SiteDetailField;
            return (
              <ColorOption
                disabled={disabledSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId)}
                key={key}
                name={key}
                i18nKey={i18nKey}
                onChange={onChange}
                data={data}
                errors={errors}
              />
            );
          })}
      </Grid>
      <SceneEnvironmentSelector
        onChange={onChange}
        data={data}
        clientId={clientId}
        isIdeaRoomUser={isIdeaRoomUser}
        selectedGroupId={selectedGroupId}
      />
    </Grid>
  );
};
